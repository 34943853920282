import React, { useState, useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import AuthenticatedResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// icons 

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Stack from '@mui/material/Stack';
import RoomIcon from '@mui/icons-material/Room';

// Image Lightbox
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import Options from './Options';
import {  Typography } from '@mui/material';
// Routes
import routes from "routes";
// Services 
import EventBus from "common/EventBus";
import MediaUploadService from "services/media_upload.service";
import MKButton from "components/MKButton";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 1000,
      height: 1000,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundcolor: "red"
      }
    },
    open_image: {
      ouline: "none"
    }
  }));
  const pattern = [
    {
      rows: 2,
      cols: 2,
    },
    {
      rows: 1,
      cols: 1,
    },
    {
      rows: 1,
      cols: 1,
    },
    {
      rows: 1,
      cols: 2,
    },
    {
      rows: 1,
      cols: 2,
    },
    {
      rows: 2,
      cols: 2,
    },
    {
      rows: 1,
      cols: 1,
    },
    {
      rows: 1,
      cols: 1,
    },
  ];
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function StandardImageList() {
    const navigate = useNavigate();
    const location = useLocation();
    const surf_session = location.state?.data; // here the surf session uuid received
    const classes = useStyles();
    const [no_files_yet, showNoFilesYet] = useState(false);
    const [session_files, setSessionFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("false");
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleImage = (value) => {
      setImage(value);
      setOpen(true);
      console.log(image);
    };


    const options = {
      settings: {
        overlayColor: "rgb(25, 136, 124)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
      },
      buttons: {
        backgroundColor: "#1b5245",
        iconColor: "rgba(126, 172, 139, 0.8)",
      },
      caption: {
        captionColor: "#a6cfa5",
        captionFontFamily: "Raleway, sans-serif",
        captionFontWeight: "300",
        captionTextTransform: "uppercase",
      }
    };
    useEffect(() => {
      console.log(" DATA RECEIVED:", surf_session );
      MediaUploadService.get_surf_session_files(surf_session.uuid).then(
          (response) => {
              console.log("SESSION FILES:",response);
              if (response?.length > 0) {
                  setSessionFiles(response);
                  showNoFilesYet(false);

              } 
              else {
                  console.log("NO FILES YET");
                  showNoFilesYet(true);
              }

          },
          (error) => {
              const _content_error =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              console.log(error.response, _content_error);
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                navigate("/pages/authentication/sign-in");
              }
            }
      )
    
     

  }, []);
  
  return (
    
    <><AuthenticatedResponsiveAppBar routes={routes} transparent light /><div className={classes.root}>
          {no_files_yet ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="info" onClose={handleClose}>
                            It looks you havent' upload any files for this session! You can upload  your files <a href="">HERE </a>
                      </Alert>
                      ) : (<></>)}
          <Grid container alignItems="center" marginTop={5} marginBottom={5} >
                    <Grid item md={6}>
                        <Typography variant="h3" color="text.secondary" align="center" >
                                Your Session Pics
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}  container justifyContent="flex-end">
                        <Stack direction="row" spacing={2} alignContent="right" alignItems="right">
                            <Item>
                                <MKButton size="small" color="white" component={Link} to="/page/surf-session-view" state={{ data: surf_session}} startIcon={<RoomIcon></RoomIcon>}>
                                 {surf_session?.spot_name}
                               </MKButton>
                            
                             </Item>
                             <Item>
                                <MKTypography variant="body" fontSize="small"> 
                                <EventAvailableIcon></EventAvailableIcon> {surf_session?.start_session_datetime}
                                </MKTypography>
                            
                             </Item>
                             <Item>
                                <MKTypography variant="body" fontSize="small"> 
                                <EventBusyIcon></EventBusyIcon> {surf_session?.end_session_datetime}
                                </MKTypography>
                            
                             </Item>         
                        </Stack>
                   </Grid>
          </Grid>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <ImageList variant="quilted" cols={4} rowHeight={200}>
                {session_files.map((item, index) => (
                  <ImageListItem
                    key={item.img}
                    cols={
                      pattern[
                        index - Math.floor(index / pattern.length) * pattern.length
                      ].cols
                    }
                    rows={
                      pattern[
                        index - Math.floor(index / pattern.length) * pattern.length
                      ].rows
                    }
                    sx={{
                      opacity: '.7',
                      transition: 'opacity .3s linear',
                      cursor: 'pointer',
                      '&:hover': { opacity: 1 },
                    }}
                  >
                    <Options />
                    <img
                      {...srcset(
                        item.image_url,
                        200,
                        pattern[
                          index - Math.floor(index / pattern.length) * pattern.length
                        ].rows,
                        pattern[
                          index - Math.floor(index / pattern.length) * pattern.length
                        ].cols
                      )}
                      alt={surf_session?.spot_name}
                      loading="lazy"
                    />
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        color: 'white',
                        background: 'rgba(0,0,0, .3)',
                        p: '5px',
                        borderTopRightRadius: 8,
                      }}
                    >
                      {/* {moment(new Date() - 500 * 60 * 60).fromNow()} */}
                    </Typography>
                    {/* <Tooltip
                      title="User Name"
                      sx={{
                        position: 'absolute',
                        bottom: '3px',
                        right: '3px',
                      }}
                    >
                      <Avatar>CIAO</Avatar>
                    </Tooltip> */}
                  </ImageListItem>
                ))}
              </ImageList>
            </SRLWrapper>
          </SimpleReactLightbox>
      </div></>
  );
}
