
import AuthenticatedResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import Grid from "@mui/material/Grid";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
import MKButton from "components/MKButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base";
import { styled } from "@mui/system";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

import StarIcon from '@mui/icons-material/Star';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
// Routes
import routes from "routes";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Loader from "common/Loader";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SurfSessionService from "services/surf_session.service";
import UserService from "services/user.service";
import EventBus from "common/EventBus";
import FormHelperText from '@mui/material/FormHelperText';
// import KeepWorkingOnSelect from "pages/PrivatePages/SurfSessions/surf-session/sections/working_on"
// import TrainingProgresses from "pages/PrivatePages/SurfSessions/surf-session/sections/training_progresses"
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
import SelectedSpotForecast from "pages/PrivatePages/SurfSessions/surf-session/sections/spot-forecast"
import UserSurfboardService from "services/surfboard.service";



const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));



const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};


const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#f0f2f5"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const SurfSession = () => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    console.log("SET CLOSE");
    setMessage();
    setOpen(false);
  };
  const isLoading = false;
  const [formData, setFormData] = useState({
    spot_name: "",
    wave_rating: "",
    wave_period: 0,
    wave_height_avg: 0,
    wave_classification: "",
    temperature: "",
    weather_condition: "",
    mood_tracker: "",
    wind_speed: 0,
    wind_direction: "",
    water_temperature: 0,
    wetsuit: "",
    board_length: "",
    board_volume: "",
    board_brand: "",
    session_rating: "",
    annotations: "",
    session_description: "",
    start_session_datetime: "",
    end_session_datetime: "",
  });
  const navigate = useNavigate();

  const location = useLocation();
  const selected_spot = location.state?.data;
  const [items, setItems] = useState({
    session_rating: [],
    waves_rating: [],
    waves_classification: [],
    wetsuit: [],
    mood_tracker: [],
    weather_condition: [],
    wind_direction: [],
  });
  const [content, setContent] = useState("");
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [my_surfboards, setMySurfBoards] = useState();
  // session rating
  const [hover, setHover] = React.useState(-1);
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  

  useEffect(() => {
    console.log("-----DATA RECEIVED----:", { selected_spot });

    UserService.getUserBoard().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
          navigate("/pages/authentication/sign-in");
        }
      }
    );
    UserSurfboardService.getSurfboards().then(
      (response) => {
        const data = response;
        console.log("SURFBOARDS:", data);
        if (data.length === 0 ) {
          navigate("/pages/select-spot");

        }
        
        setMySurfBoards(data);
      },
      (error) => {
        setSuccessful(false);
        setOpen(true);
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
          console.log("ERROR:",_content)

      }
    );

    SurfSessionService.get_session_classification_values().then(
      (response) => {
        const data = response.data;
        console.log("CLASSIFICATION RESPONSE :", data);
        setItems(data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("ERROR GETTING VALUES:",_content);
      }
    );
  }, [navigate, selected_spot]);
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("NAME:", name);
    console.log("SET:", (name, value));
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var payload = {
      spot_name: selected_spot?.spot_name,
      spot_id: selected_spot?.spot_id,
      wave_classification: formData.wave_classification,
      wetsuit: formData.wetsuit,
      surfboard_uuid: formData.surfboard.uuid,
      my_mood: formData.mood_tracker || "3",

      wave_rating: selected_spot?.wave_rating,
      wave_height_avg: selected_spot?.wave_avg_size,
      wave_period: selected_spot?.wave_period,
      wind_speed: selected_spot?.wind_speed,
      wind_direction: selected_spot?.wind_direction,
      temperature: selected_spot?.weather_temp,
      weather_condition: selected_spot?.weather_condition,
      water_temperature: selected_spot?.water_temp,

      session_rating: formData.session_rating,
      positive_annotations: formData.positive_annotations,
      negative_annotations: formData.negative_annotations,
      session_description: formData.session_description,
      start_session_datetime: selected_spot.start_session_datetime,
      end_session_datetime: selected_spot.stop_session_datetime,
    };

    console.log("PAYLOAD:",payload);
   

    Object.keys(payload).forEach(function(key, index) {
      if (payload[key] === undefined) {
          console.log("TO BE REMOVED:", key);
          delete payload[key]

      }
      
      
    });
    console.log("NEW PAYLOAD:",payload);

    SurfSessionService.storeSurfSession(payload).then(
      (response) => {
        const data = response.data;
        console.log("STORE SESSION RESPONSE :", data);
        setSuccessful(true);
        setMessage("Great! Let's add more information to your session");
        setOpen(true);
        navigate("/pages/surf-session-route", {state: {data: data}});


      },
      (error) => {
        let status = error.request.status
        let message = JSON.parse(error.request.response);
        console.log("STATUS AND MESSAGE", status, message);
        setSuccessful(false);
        setMessage("Something went wrong!");
        setOpen(true);

        console.log("OPEN:",open)
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
          console.log("ERROR:",_content);
      }
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <AuthenticatedResponsiveAppBar routes={routes} transparent light />
      
        <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -40,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid section xs={12} lg={12}  textAlign="center" marginTop={-8}>
              <MKTypography variant="h4">Log your Session</MKTypography>
            </Grid>
           
          <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
          
          <MKBox width="100%" component="form" method="post" autocomplete="off" >
            {!successful && (
              <MKBox p={3}>
                <Grid container spacing={10} alignItems="center" alignContent="center" marginBottom={5}>
                  <Grid item xs={6} md={2} marginLeft={10}>
                     <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="wetsuit-label">SurfBoard</InputLabel>
                      <Select
                        name="surfboard"
                        id="surfboard"
                        value={formData.surfboard || ''}
                        onChange={handleChange}
                        label="surfboard"
                      >
                        <MenuItem value={formData.surfboard}>
                          <em></em>
                        </MenuItem>
                        {my_surfboards?.map((surfboard) => (
                          <MenuItem key={surfboard.key} value={surfboard}>
                            {surfboard.value}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>What board did you use?</FormHelperText>

                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={2}>
                 
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="wetsuit-label">Wetsuit</InputLabel>
                      <Select
                        name="wetsuit"
                        id="wetsuit"
                        value={formData.wetsuit}
                        onChange={handleChange}
                        label="Did you wear a wetsuit?"
                      >
                        <MenuItem value={formData.wetsuit}>
                          <em>{formData.wetsuit}</em>
                        </MenuItem>
                        {items["wetsuit"].map((item) => (
                          <MenuItem key={item.key} value={item.value}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Did you wear a wetsuit?</FormHelperText>

                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={2} marginTop={2}>
                  <MKTypography variant="body2" fontSize="small"> Rate Your Session</MKTypography>
                  <Stack spacing={1}>
                      <Rating
                          name="session_rating"
                          value={formData.session_rating || ""}
                          precision={0.5}
                          size="medium"
                          onChange={(event, newValue) => {
                            handleChange(event, newValue);
                          }}
                          onChangeActive={(event, newHover) => {
                            setHover(newHover);
                          }}
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                      
                  </Stack>
                    
                  </Grid>
                  <Grid item xs={6} md={2} marginTop={2}>
                  <MKTypography variant="body2" fontSize="small"> How were the waves?</MKTypography>

                  <Stack spacing={1}> 
                      <Rating
                          name="wave_classification"
                          value={formData.wave_classification || ""}
                          precision={0.5}
                          size="medium"

                          onChange={(event, newValue) => {
                            handleChange(event, newValue);
                          }}
                          onChangeActive={(event, newHover) => {
                            setHover(newHover);
                          }}
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        
                  </Stack>
                  </Grid>
                  <Grid item xs={6} md={2} marginTop={2}>
                  <MKTypography variant="body2" fontSize="small"> Your Mood after the session</MKTypography>

                  <StyledRating
                        name="mood_tracker"
                        size="large"
                        IconContainerComponent={IconContainer}
                        onChange={handleChange}
                        getLabelText={(value) => customIcons[value].label}
                        highlightSelectedOnly
                    />
                  </Grid>
                </Grid>
              
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12} md={6}>
                    <Textarea
                      variant="standard"
                      label="Your good notes"
                      minRows={5}
                      fullWidth                    
                      name="positive_annotations"
                      value={formData.positive_annotations}
                      onChange={handleChange}
                      placeholder="Write here what you think you did good"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Textarea
                      variant="standard"
                      label="Your bad notes"
                      fullWidth
                      minRows={5}
                      id="negative_annotations"
                      name="negative_annotations"
                      value={formData.negative_annotations}
                      onChange={handleChange}
                      placeholder="Write here what you think went wrong"
                    />
                  </Grid>
                </Grid> 
                <Grid container  sx={{ mx: "auto" }} textAlign="center"> 
                  <Grid item xs={12} md={6}>
                    <Textarea
                      variant="standard"
                      label="Description of your session"
                      fullWidth
                      minRows={5}
                      id="session_description"
                      name="session_description"
                      value={formData.session_description}
                      onChange={handleChange}
                      placeholder="Overall, let's describe your session"
                      required
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                  <MKTypography variant="body2">I am still need to work on </MKTypography>
                    <KeepWorkingOnSelect />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    {/* <TrainingProgresses /> */}
                    
                  </Grid>
                </Grid>

                <Grid container spacing={2} marginTop={2} borderTop={1} borderColor="lightgrey">
                  <Grid item xs={12} md={6}>
                      <SelectedSpotForecast forecastData={selected_spot}/>
                 </Grid>
                  
                </Grid>
                <Grid container spacing={3} marginTop={5}>
                  <Grid item xs={12} md={12} align="right">
                    <MKButton variant="gradient" color="info" onClick={handleSubmit}>
                      Save
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            )}
            {message && (
              <><Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                  >
                      <Box sx={style}>
                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        </Typography>
                        <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                          {message}
                        </Typography>
                      </Box>
                    </Modal></>
              
            )}

          </MKBox>
          </Grid>
      </Card>
      <Footer />
              </MKBox>
    </>
  );
};

export default SurfSession;
