import { RotatingLines } from "react-loader-spinner";
import Grid from "@mui/material/Grid";

function Loader() {
    return (
      <Grid style={{display: 'flex', justifyContent: 'center'}}margin="20%">
       <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
  
  /> 
      </Grid>
    )
  }

  const PageLoader = {
    Loader,
    
  };
  
  export default PageLoader;
  