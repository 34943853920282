/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect} from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MKTypography from "components/MKTypography";
// Images
import profilePicture from "assets/images/antonio.jpg";

function Profile({data}) {
  const [current_user, setCurrentUser] = useState();
  
  useEffect(() => {
  setCurrentUser(data?.current_user.current_user);
  }, [current_user, data.current_user.current_user]) // dependencies here ensure that data is ready before rendering the page

  return (
    <MKBox component="section" py={{ xs: 6, sm: 2 }} >
      <Container >
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -20, md: -15 }} textAlign="center">
            <MKAvatar
              src={profilePicture}
              alt={current_user?.first_name + " " + current_user?.last_name}
              size="xxl"
              shadow="xl"
              

            />
          </MKBox>
          <Grid container justifyContent="center" py={5}>
            <Grid item xs={12} md={12} mx={{ xs: "auto", sm: 6, md: 1 }}>

              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1} mt={-5} textAlign="center" >
                <Grid item  xs={12} md={12} >
                  <MKTypography variant="h4" textAlign="center" >
                    {current_user?.first_name + " " + current_user?.last_name}
                  </MKTypography>
                    <LocationOnIcon />
                    <MKTypography component="list" variant="body2" color="text" textAlign="center">
                      Catania
                    </MKTypography>
                </Grid>
                
              </MKBox>
                <Grid container xs={12} md={12} mt={4}>
                <Grid  item xs={4} md={4}  >
                  <MKTypography component="section" variant="h4" fontWeight="bold" textAlign="center">
                    2&nbsp;
                  </MKTypography>
                  <MKTypography component="section" variant="body2" color="text" textAlign="center">
                    Boards
                  </MKTypography>
                </Grid>
                <Grid item xs={4} md={4} >
                  <MKTypography component="section" variant="h" fontWeight="bold" textAlign="center">
                    4&nbsp;
                  </MKTypography>
                  <MKTypography component="section" variant="body2" color="text" textAlign="center"> 
                    Favorite Spots
                  </MKTypography>
                </Grid>
                <Grid item xs={4} md={4} >
                  <MKTypography component="section" variant="h4" fontWeight="bold" textAlign="center">
                    14&nbsp;
                  </MKTypography>
                  <MKTypography component="section" variant="body2" color="text" textAlign="center">
                     Surf Sessions
                  </MKTypography>
                </Grid>
                
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
