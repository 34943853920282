/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EventBus from "common/EventBus";

const { REACT_APP_BACKEND_URL } = process.env;
const API_URL = REACT_APP_BACKEND_URL;


console.log("-------- SERVICE URL -------",API_URL);

const register = (username, email, password) => {
  return axios.post(API_URL + "/users", {
    username,
    email,
    password,
  });
};

const login = async (username, password) => {
  console.log("-----HERE LOGIN-----", username, password, API_URL);
  let bodyFormData = new FormData();
  bodyFormData.append("username", username);
  bodyFormData.append("password", password);
  const response = await axios({
    method: "post",
    url: API_URL + "login/access_token",
    data: bodyFormData,
    headers: {
      "Content-Type": `multipart/form-data; boundary=${bodyFormData._boundary}`,
    },
  });

  console.log("RESPONSE:", response.data);
  if (response.data.access_token) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const logout = async () => {
  const navigate = useNavigate();

  localStorage.removeItem("user");
  EventBus.dispatch("logout");
  // navigate("/");
  // const response = await axios.post(API_URL + "signout");
  // return response.data;
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};
export default AuthService;
