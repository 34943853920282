import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { TakeoutDining } from '@mui/icons-material';
import Grid from "@mui/material/Grid";
const uData = [4000, 3000, 2000, 2780, 1890];
const pData = [4, 4, 5, 5, 4];

const xLabels = [
  'Sat 08',
  'Sun 09',
  'Mon 10',
  'Wed 12',
  'Wed 19'
  
];


const yLabels = [
    '4 Star',
    '4 Star',
    '5 Star',
    '4 Star',
    '3 Star',
    
  ];
  

const tasks = [
     {
    "label":"Take-off",
    "data" : [4,5,3,4,4]
    },
    {
        "label":"Paddling",
        "data" : [5,5,3,5,5]
        },
        {
            "label":"Bottom-turn",
            "data" : [2,3,2,1,4]
            },


]

export default function SimpleLineChart() {
  return (

    tasks.map((task) => {
         
    return(
        <Grid item xs={12} md={12}  p={10}>
             <LineChart
                height={300}
                series={[
                { data: task.data, label: task.label , 
                    showMark: ({ index }) => index % 2 === 0,
                    
                },
                
                
                ]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
            /> 
        </Grid>
       
        
        )      
      })
    
    
  );
}