import React, { useState, useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthenticatedResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import Typography from '@mui/material/Typography';
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base";
import { styled } from "@mui/system";
import MKButton from "components/MKButton";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
// Icons
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Stack from '@mui/material/Stack';
import RoomIcon from '@mui/icons-material/Room';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';

// Routes
import routes from "routes";
// Services 
import EventBus from "common/EventBus";
import UserSurfboardService from "services/surfboard.service";
import SurfSessionService from "services/surf_session.service";


const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };
  
  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#f0f2f5"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  let wind_directions = [
    { label: "Cross-shore", value: "Cross-shore" },
    { label: "Cross-onshore", value: "Cross-onshore" },
    { label: "Cross-offshore", value: "Cross-offshore" },
    { label: "Onshore", value: "Onshore" },
    { label: "Offshore", value: "Offshore" },
  ];

  let weather_conditions = [
    { label: "No Data", value: "No Data" },
    { label: "LIGHT RAIN", value: "LIGHT_RAIN" },
    { label: "LIGHT SHOWERS", value: "LIGHT_SHOWERS" },
    { label: "MODERATE RAIN", value: "MODERATE_RAIN" },
    { label: "HEAVY RAIN", value: "HEAVY_RAIN" },
    { label: "CLEAR", value: "CLEAR" },
    { label: "CLOUD", value: "CLOUD" },
  ];

  let wave_ratings = [
    { label: "No Data", value: "No Data" },
    { label: "FLAT", value: "flat" },
    { label: "POOR", value: "poor" },
    { label: "POOR TO FAIR", value: "poor_to_fair" },
    { label: "FAIR", value: "fair" },
    { label: "FAIR TO GOOD", value: "fair_to_good" },
    { label: "GOOD", value: "good" },

  ];


//   created_at:"",
//   end_session_datetime:"",
//   my_mood:"" ,
//   negative_annotations: "",
//   positive_annotations: "",
//   session_day:"",
//   session_description: "",
//   session_month:"",
//   session_rating:"",
//   session_year:"", 
//   spot_id:"", 
//   spot_name:"", 
//   start_session_datetime: "",
//   surfboard_uuid:"", 
//   swell_size:"", 
//   temperature:"",
//   updated_at:"",
//   user_uuid:"", 
//   uuid:"",
//   water_temperature: "",
//   wave_classification: "",
//   wave_height_avg:"",
//   wave_period: "",
//   wave_rating:"",
//   waves_surfed:"", 
//   weather_condition:"" ,
//   wetsuit:"" ,
//   wind_direction:"",
//   wind_speed:""

export default function SurfSessionEdit() {
    const navigate = useNavigate();
    const location = useLocation();
    const surf_session = location.state?.data; // here the surf session uuid received
    const [surfboard, setSurfboard] = useState({});
    const [show_no_surfboard, showAlertNoSurfboard] = useState(false);
    const [show_update_success, showUpdateSuccess] = useState(false);
    const [show_update_error, showUpdateError] = useState(false);
    const [formData, setFormData] = useState({});
    const [wave_avg_size, setWaveAvgSize] = useState("");
    const [wave_period, setWavePeriod] = useState("");
    const [wind_direction, setWindDirection] = useState("");
    const [wind_speed, setWindSpeed] = useState("");
    const [weather_temp, setWeatherTemp] = useState("");
    const [weather_condition, setWeatherCondition] = useState("");
    const [water_temp, setWaterTemp] = useState("");
    const [wave_rating, setWaveRating] = useState();
    const [my_surfboards, setMySurfBoards] = useState();
    const [data_to_pass, setDataToPass] = useState();

  

    let handleWaveRatingChange = (e) => {
        setWaveRating(e.target.value);
    };

    let handleWindDirectionChange = (e) => {
        setWindDirection(e.target.value);
    };

    let handleWeatherConditiononChange = (e) => {
        setWeatherCondition(e.target.value);
    };
    
    const handleChange = (event) => {
        event.preventDefault();
        console.log("EVENT:",event)
        const { name, value } = event.target;
        console.log("NAME:", name);
        console.log("SET:", (name, value));
      
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        
      
      };
    const handleClose = () => {
        showUpdateSuccess(false);
        navigate("/page/surf-session-view", {state: { data: data_to_pass}});
      };
    const handleSubmit = (event) => {
        event.preventDefault();
        var payload = {
            spot_name: surf_session.spot_name,
            spot_id: surf_session.spot_id,
            wave_classification: surf_session.wave_classification,
            wetsuit: surf_session.wetsuit,
            positive_annotations: formData?.positive_annotations || surf_session.positive_annotations,

            surfboard_uuid: formData?.surfboard_uuid || surf_session.surfboard_uuid,
            my_mood: surf_session.my_mood,
            
            
            wave_rating: wave_rating ? wave_rating : surf_session.wave_rating,
            wave_height_avg: parseFloat(wave_avg_size ? wave_avg_size : surf_session.wave_height_avg ),
            wave_period: parseFloat(wave_period ? wave_period : surf_session.wave_period ),
            wind_speed: wind_speed ? wind_speed : surf_session.wind_speed,
            wind_direction: wind_direction ? wind_direction : surf_session.wind_direction,
            temperature: parseFloat(weather_temp ? weather_temp : surf_session.weather_temp ),
            weather_condition: weather_condition ? weather_condition : surf_session.weather_condition,
            water_temperature: parseFloat(water_temp ? water_temp : surf_session.water_temperature ),
            
            
            
            session_rating: surf_session.session_rating,
            negative_annotations: formData?.negative_annotations || surf_session.negative_annotations,
            session_description: formData?.session_description || surf_session.session_description,
            start_session_datetime: surf_session.end_session_datetime,
            end_session_datetime: surf_session.end_session_datetime,
            };

            console.log("PAYLOAD:",payload);
   

            Object.keys(payload).forEach(function(key, index) {
            if (payload[key] === undefined) {
                console.log("TO BE REMOVED:", key);
                delete payload[key]

            }
            
            });
            console.log("NEW PAYLOAD:",payload);

            SurfSessionService.updateSurfSession(payload,surf_session.uuid).then(
              (response) => {
                const data = response.data;
                console.log("------RESPONSE ---:", data);
                data['success_edit'] = true;
                setDataToPass(data);
                showUpdateSuccess(true);
                // navigate("/page/surf-session-view", {state: { data: data_to_pass}});
                setTimeout(() => {
                    navigate("/page/surf-session-view",  {state:{data:data_to_pass}} );
                  }, 2000);



              },
              (error) => {
                console.log(" ERROR", error);
                showUpdateError(true);
                // window.location.reload(false);
                const _error_content =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                  console.log("ERROR:", _error_content);
                  if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                    navigate("/pages/authentication/sign-in");
                  }
                }
            );
      }

    useEffect(() => {
        console.log(" DATA RECEIVED:", surf_session );
        surf_session['edit_success'] = undefined;
        surf_session['edit_error'] = false;
        setDataToPass(surf_session)
        let surfboard_uuid = surf_session.surfboard_uuid;
        // SurfSessionService.get_session(surf_session.uuid).then(
        //     (response) => {
        //         if (response?.data.length > 0) {
        //             console.log("DATA: ",response.data[0])
        //         } 
        //         else {
        //             console.log("NO SURF SESSION FOUND. IT's WEIRD!!!");
        //         }
        //     }
        // )
        
        UserSurfboardService.get_surfboard(surfboard_uuid).then(
            (response) => {
                console.log("SURFBOARD IS:",response);
                if (response?.length > 0) {
                    setSurfboard(response);
                    console.log("STORED SURFBOARD:",response);
                } 
                else {
                    console.log("NO SURFBOARD FOUND");
                    showAlertNoSurfboard(true);
                }

            },
            (error) => {
                const _content_error =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                console.log(error.response);
                if (error.response && error.response.status === 401) {
                  EventBus.dispatch("logout");
                  navigate("/pages/authentication/sign-in");
                }
              }
        )
        UserSurfboardService.getSurfboards().then(
            (response) => {
              const data = response;
              console.log("SURFBOARDS:", data);
              if (data.length === 0 ) {
                console.log("WHAT TO DO?")
                //navigate("/pages/select-spot");
              }
              setMySurfBoards(data);
            },
            (error) => {
            //   setSuccessful(false);
            //   setOpen(true);
              const _content =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
                console.log("ERROR:",_content)
      
            }
          );
   
       

    }, []);
    return (
        <><AuthenticatedResponsiveAppBar routes={routes} transparent light />
         {show_update_success ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="success" onClose={handleClose}>
                            You just edited this Surf Session
                      </Alert>
                      ) : (<></>)}
         {show_update_error ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="error" onClose={handleClose}>
                            Hey dude, something didn't work as expected. Please, try again! 
                      </Alert>
                      ) : (<></>)}
        <Grid container alignItems="left" marginTop={5} spacing={1} >
                    <Grid item md={6}>
                        <Typography variant="h2" color="text.secondary" align="center" >
                                SurfSession Details
                                
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <MKButton  variant="contained" color="info" startIcon={<ArrowBackIcon />} component={Link} to="/page/surf-session-view" state={{ data: data_to_pass}}>
                          Go Back
                        </MKButton>
                    </Grid>

                    <Grid item xs={12} md={2} lg={2}>
                        <MKButton component="label" variant="contained" color="success" onClick={handleSubmit} startIcon={<SaveIcon></SaveIcon>}>
                            Update This Session 
                        </MKButton>
                    </Grid>
                    
                </Grid>
        <Grid container  marginLeft={1} spacing={1} alignItems="center" marginTop={10} padding={5}>
            <Grid item xs={6} md={12} lg={12}  padding={1} >
                {/* Session Info  Mood, Waves and Session Rates*/}
                <Grid container justifyContent="flex-start" spacing={1}>
                    
                    <Grid item xs={6} md={2}>
                   
                        <Typography variant="body" color="text.primary" size="small" >
                            Spot
                            
                     </Typography>
                        <MKTypography variant="body2" >
                           <RoomIcon></RoomIcon> {surf_session?.spot_name}
                        </MKTypography>
                        <Typography variant="body2"  color="text.secondary">
                           <EventAvailableIcon></EventAvailableIcon> {surf_session?.start_session_datetime}
                        </Typography>
                        <Typography variant="body2"  color="text.secondary">
                           <EventBusyIcon></EventBusyIcon> {surf_session?.end_session_datetime}
                        </Typography>
                    </Grid>
                     {/* Surfboard and Wesuit */}

                    
                    <Grid item xs={12} md={2}>
                        <Typography variant="body" color="text.primary" size="small" >
                            Surfboard
                        </Typography>
                        <Grid item xs={12}></Grid>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="surfboard_uuid" >
                                <ArrowDownwardIcon fontSize="small" ></ArrowDownwardIcon>
                            </InputLabel>
                            <Select
                                name="surfboard_uuid"
                                id="surfboard_uuid"
                                value={formData.surfboard_uuid}
                                onChange={handleChange}
                                defaultValue="Soft Top - 7.5 feet"
                                label="surfboard_uuid"
                            >
                                
                                {my_surfboards?.map((surfboard) => (
                                <MenuItem key={surfboard.key} value={surfboard.uuid}>
                                    {surfboard.value}
                                </MenuItem>
                                
                                ))}
                            </Select>

                    </FormControl>
                        
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="body" color="text.secondary" size="small" >
                            Wetsuit
                        </Typography>
                        <Typography variant="body2" color="text.secondary" size="small" >
                            {surf_session?.wetsuit}
                        </Typography>
                        
                    </Grid>
                    
                    <Grid item xs={6} md={2}>
                    <Typography variant="body" color="text.secondary" size="small" align="center">
                        Mood
                    </Typography>
                
                                    <Stack spacing={1}> 
                                                <Rating
                                                    name="wave_classification"
                                                    value={surf_session?.my_mood}
                                                    precision={0.5}
                                                    size="small"
                                                    disabled={true}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                    />
                                                    
                                    </Stack>

                    </Grid>
                    <Grid item xs={6} md={2}>
                    <Typography variant="body" color="text.secondary" size="small" align="center">
                                        Waves  
                                     </Typography>
                                    <Stack spacing={1} sx={{ width: 1/4 }}> 
                                                <Rating
                                                    name="wave_classification"
                                                    value={surf_session?.wave_classification}
                                                    precision={0.5}
                                                    size="small"
                                                    disabled={true}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                    />
                                                    
                                    </Stack>

                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography variant="body" color="text.secondary" size="small" align="center">
                             Session Rating
                        </Typography>
                        <Stack spacing={1} sx={{ width: 1/4 }}> 
                            <Rating
                                                    name="wave_classification"
                                                    value={surf_session?.session_rating}
                                                    precision={0.5}
                                                    size="small"
                                                    disabled={true}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                                                    
                        </Stack>
                    </Grid>    
                </Grid>
                {/* Weather and Sea conditions */}
                <Grid container justifyContent="flex-start" spacing={1} marginTop={5}>
                        
                        <Grid item xs={12} lg={2} >
                            <Typography variant="body" color="text.secondary" size="small" >
                                Wave Heigth
                            </Typography>
                            <Grid item lg={12}></Grid>
                            <MKInput
                                variant="standard"
                                fullWidth
                                style = {{width: 50}}
                                type="text"
                                id="wave_avg_size"
                                name="wave_avg_size"
                                defaultValue={surf_session?.wave_height_avg}
                                onChange={(e) => {
                                    e.preventDefault()
                                    setWaveAvgSize(e.target.value)}
                                }
                                />
                           
                        </Grid>
                        <Grid item xs={12} lg={2} >
                        <Typography variant="body" color="text.secondary" size="small" >
                                Wave Period
                            </Typography>
                            <Grid item lg={12}></Grid>
                            <MKInput
                                variant="standard"
                                style = {{width: 50}}
                                type="text"
                                defaultValue={surf_session?.wave_period}
                                id="wave_period"
                                name="wave_period"
                                onChange={(e) => setWavePeriod(e.target.value)}
                                />
                        </Grid>
                        
                        <Grid item xs={12} lg={2} >
                                <Typography variant="body" color="text.secondary" size="small" >
                                    Weather Temp
                                </Typography>
                                <Grid item lg={12}></Grid>
                                <MKInput
                                        variant="standard"
                                        fullWidth
                                        type="text"
                                        style = {{width: 50}}
                                        defaultValue={surf_session?.temperature}
                                        id="water_temp"
                                        name="water_temp"
                                        onChange={(e) => setWeatherTemp(e.target.value)}
                                />
                        </Grid>
                        <Grid item xs={12} lg={2} >
                                <Typography variant="body" color="text.secondary" size="small" >
                                    Water Temp
                                </Typography>
                                <Grid item lg={12}></Grid>
                                <MKInput
                                        variant="standard"
                                        fullWidth
                                        type="text"
                                        defaultValue={surf_session?.water_temperature}
                                        id="water_temp"
                                        style = {{width: 50}}
                                        name="water_temp"
                                        onChange={(e) => setWaterTemp(e.target.value)}
                                />
                                
                        </Grid>   
                        <Grid item xs={12} lg={2}>
                                <Typography variant="body" color="text.secondary" size="small" >
                                    Wind Speed
                                </Typography>
                                <Grid item lg={12}></Grid>
                                <MKInput
                                    variant="standard"
                                    fullWidth
                                    type="text"
                                    style = {{width: 50}}
                                    defaultValue={surf_session?.wind_speed}
                                    id="wind_speed"
                                    name="wind_speed"
                                    onChange={(e) => setWindSpeed(e.target.value)}
                                />
                        </Grid>
                        <Grid container marginTop={5}>
                        <Grid item xs={12} lg={2} >
                            <Typography variant="body" color="text.secondary" size="small" >
                                    Weather Condition
                            </Typography>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="weather_condition">
                                    <ArrowDownwardIcon fontSize="medium"></ArrowDownwardIcon>
                                </InputLabel>
                                <Select
                                    onChange={handleWeatherConditiononChange}
                                    defaultValue={surf_session?.weather_condition} 
                                >
                                    <MenuItem value={surf_session?.weather_condition}  >
                                        <em>{surf_session?.weather_condition}  </em>
                                    </MenuItem>
                                    {weather_conditions.map((condition) => (
                                        <MenuItem key={condition.value} value={condition.value}>
                                            {condition.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                           
                        </Grid>
                        <Grid item xs={12} lg={2} >
                            <Typography variant="body" color="text.secondary" size="small" >
                                    Wind Direction
                                </Typography>

                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="wind_direction_label" >
                                    <ArrowDownwardIcon fontSize="medium" ></ArrowDownwardIcon>
                                </InputLabel>
                                <Select
                                    labelId="wind_direction"
                                    id="wind_direction"
                                    onChange={handleWindDirectionChange}
                                    defaultValue={surf_session?.wind_direction}
                                >
                                    <MenuItem value={surf_session?.wind_direction} >
                                    <em>
                                    {surf_session?.wind_direction}
                                    </em>
                                    </MenuItem>
                                    {wind_directions.map((direction) => (
                                    <MenuItem key={direction.value} value={direction.value}>
                                        {direction.label}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> 
                        <Grid item xs={12} lg={2}>
                                <Typography variant="body" color="text.secondary" size="small" >
                                    Wave Forecast Rating
                                </Typography>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="wave_rating">
                                    <ArrowDownwardIcon fontSize="medium"></ArrowDownwardIcon>
                                </InputLabel>
                                <Select
                                    labelId="wave_rating"
                                    id="wave_rating"
                                    defaultValue={surf_session?.wave_rating}
                                    onChange={handleWaveRatingChange}
                                >
                                    <MenuItem value={surf_session?.wave_rating}>
                                    <em>{surf_session?.wave_rating}</em>
                                    </MenuItem>
                                    {wave_ratings.map((rating) => (
                                    <MenuItem key={rating.value} value={rating.value}>
                                        {rating.label}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>          
                       </Grid>
                </Grid>
                <Grid container alignItems="center" alignContent="center" marginTop={5}>
               </Grid>
                {/*  Notes */}    
                <Grid container alignItems="center" alignContent="center" marginTop={5} paddingRight={3} spacing={3}>
                    <Grid item xs={6} md={4}>
                        <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                                    What I managed to do right...
                        </Typography>
                            <Grid item xs={12} md={12}>
                                <Textarea
                                variant="standard"
                                minRows={10}
                                fullWidth    
                                id="positive_annotations"               
                                name="positive_annotations"
                                onChange={handleChange}
                                value={formData.positive_annotations}
                                defaultValue={surf_session?.positive_annotations}
                                />
                            </Grid>

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                                What I need to improve...
                        </Typography>
                            <Grid item xs={12} md={12}>
                                <Textarea
                                variant="standard"
                                minRows={10}
                                fullWidth                    
                                id="negative_annotations"
                                name="negative_annotations"
                                onChange={handleChange}
                                value={formData.negative_annotations}
                                defaultValue={surf_session?.negative_annotations}
                                />
                            </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                                    Session Description...
                        </Typography>
                            <Grid item xs={12} md={12}>
                                <Textarea
                                variant="standard"
                                minRows={10}
                                fullWidth                   
                                id="session_description"
                                name="session_description"
                                value={formData.session_description}
                                onChange={handleChange}
                                defaultValue={surf_session?.session_description}
                                />
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Footer />

        </>
        );
}