// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import MKTypography from "components/MKTypography";


// Author page sections
import SimpleLineChart from "./sections/tasks-progress";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
import Surfboards from "pages/PrivatePages/UserBoard/sections/SurfBoards";
import CompleteProfile from "pages/PrivatePages/UserBoard/sections/CompleteProfile";
import CreateTraining from "pages/PrivatePages/UserBoard/sections/CreateTraining";
import LogSession from "pages/PrivatePages/UserBoard/sections/LogSession";
import SessionsList from "pages/PrivatePages/UserBoard/sections/SessionsList";
// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/surfingwallpaper56.jpeg";

const BoardUser = () => {
  return (
    <>
      <ResponsiveAppBar routes={routes} transparent light />
      
      <MKBox component="section" py={12} >
        <Container >
        
          <Grid container spacing={1} alignItems="center" >  
          <Grid item  xs={12} md={12} mb={5} mt={-5}>
            <MKTypography variant="h2" >
              Your Training Progresses
            </MKTypography>
          </Grid>
            <Grid item xs={12} md={12}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="1rem"
                width="100%"
                borderRadius='16px'
               
              />
                    <SimpleLineChart />
                </Card>
              </MKBox>
            </Grid>
         
            {/* <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <Surfboards />
                </Card>
              </MKBox>
            </Grid>
          
            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <CompleteProfile />
                </Card>
              </MKBox>
            </Grid>


            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <CreateTraining />
                </Card>
              </MKBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <LogSession />
                </Card>
              </MKBox>
            </Grid>


            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <SessionsList />
                </Card>
              </MKBox>
            </Grid> */}

          </Grid>
         
        </Container>
        <Footer />

      </MKBox>
      {/* <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -28,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile />
        </Card>
        <Footer />
      </MKBox> */}
    </>
  );
};

export default BoardUser;
