/* eslint-disable no-unused-vars */
import axios from "axios";
import AuthService from "services/auth.service";
import EventBus from "common/EventBus";
const { REACT_APP_BACKEND_URL } = process.env;
const BASE_API_URL = REACT_APP_BACKEND_URL;
const API_URL = BASE_API_URL+"training_progress";
const currentUser = AuthService.getCurrentUser();

if (currentUser != null) {
  var access_token = currentUser.access_token;
} else {
  access_token = undefined;
}


const getUserTrainingProgress = (surf_session_uuid) => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
  };
  
  return axios.get(API_URL+"?surf_session_uuid="+surf_session_uuid,  config).then(
    (res) => {
      console.log("RESPONSE TRAINING PROGRESS:",res);
      return res.data;
    },
    (error) => {
      let http_status = error.response.status;
      console.log("[TRAINING PROGRESS:] error:", error,http_status);
      if (http_status === 401) {
        EventBus.dispatch("logout");
      }
      return error;

    }
  );
};


const addBulkProgress = (payload) => {
   console.log("BULK SEND TO",API_URL+"/bulk", payload)
  return axios.post(API_URL+"/bulk" , payload, {
    headers: { Authorization: "Bearer " + access_token },
  });
};


const deleteTrainingProgress = (surf_session_uuid) => {
  return axios.delete(API_URL+"?surf_session_uuid="+surf_session_uuid, {
    headers: { Authorization: "Bearer " + access_token },
  });
};
const UserTrainingProgressService = {
  addBulkProgress,
  getUserTrainingProgress,
  deleteTrainingProgress

  
};

export default UserTrainingProgressService;
