import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";

 // @mui material components
import MKButton from "components/MKButton";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import Slide from '@mui/material/Slide';

import Footer from "pages/PrivatePages/UserBoard/sections/Footer";

// Routes
import routes from "routes";
import MKTypography from "components/MKTypography";

// Images
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

// RestAPI service 
import UserTrainingProgrammService from "services/training_program.service";
import EventBus from "common/EventBus";


const maneuvers = {
  beginner :  [
    {
      id: 1,
      name: 'paddle technique',
      slug: 'paddle technique',
      type: 'beginner',
      locale: 'en',
    },
    {
        id: 2,
        name: 'Take-off',
        slug: 'take-off',
        type: 'beginner',
        locale: 'en',
      },
    {
      id: 3,
      name: 'Bottom Turn',
      slug: 'bottom-turn',
      type: 'beginner',
      locale: 'en',
      selected: 'true'
    },
    {
      id: 4,
      name: 'Carve',
      slug: 'carve',
      type: 'begineer',
      locale: 'en',
    },

    
    {
      id: 5,
      name: 'Cutback',
      slug: 'cutback',
      type: 'beginner',
      locale: 'en',
    },],
    intermediate :[
      {
        id: 6,
        name: 'Snap',
        slug: 'snap',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 7,
        name: 'Roundhouse Cutback',
        slug: 'Roundhouse Cutback',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 8,
        name: 'Off-the-Lip',
        slug: 'Off-the-Lip',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 9,
        name: 'Foam Climb',
        slug: 'Foam Climb',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 10,
        name: 'Floater',
        slug: 'Floater',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 11,
        name: 'Closeout Re-entry',
        slug: 'Closeout Re-entry',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 12,
        name: 'Tail Slide',
        slug: 'Tail Slide',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 13,
        name: 'Noseriding',
        slug: 'Noseriding',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 14,
        name: 'Hang Five',
        slug: 'Hang Five',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 15,
        name: 'Hang Ten',
        slug: 'Hang Ten',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 16,
        name: 'Switch Stance',
        slug: 'Switch Stance',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 17,
        name: '360',
        slug: '360',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 18,
        name: 'Kickflip',
        slug: 'Kickflip',
        type: 'intermediate',
        locale: 'en',
      },],
      advanced: [
        {
          id: 19,
          name: 'Tube Ride',
          slug: 'Tube Ride',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 20,
          name: 'Aerial',
          slug: 'Aerial',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 21,
          name: 'Alley-Oop',
          slug: 'Alley-Oop',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 22,
          name: 'Superman',
          slug: 'Superman',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 23,
          name: 'Rodeo Flip',
          slug: 'Rodeo Flip',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 24,
          name: 'Kerrupt Flip',
          slug: 'Kerrupt Flip',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 25,
          name: 'Flynnstone Flip',
          slug: 'Flynnstone Flip',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 26,
          name: 'Sushi Flip',
          slug: 'Sushi Flip',
          type: 'advanced',
          locale: 'en',
        }
      
      ]
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const surfer_levels = {
  beginner: "a BEGINNER is a surfer who is yet to successfully paddle out alone and catch and ride a wave cleanly to its logical finish.",
  intermediate: "an INTERMEDIATE rider is a surfer who can successfully paddle out alone at a familiar location and catch and ride waves to a logical finish frontside or backside, confidently using the three basic surfing turns – bottom turn, top turn and cutback.",
  advanced:"an ADVANCED rider is a surfer who can paddle out alone to a surf spot he/she has never ridden, assess the lineup, and catch and ride waves to his/her choice of finish, confidently using a full range of turns in a distinctive, effective style."
}

const UserTraining = () => {
    const navigate = useNavigate();
    const [training_goals, setTrainingGoals] = useState([]);
    const [selected_Level, setLevel] = useState();
    const [training_uuid, setTrainingUUID] = useState();
    const [training_completed, setTrainingCompleted] = useState(false);
    const [show_update_button, showUpdateButton] = useState(true);
    const [show_alert, showAlert] = useState(false);
    const [formData, setFormData] = useState({
      level: "",
      training_goals: training_goals,
      
    });
    const handleSubmit = (event, complete) => {
        event.preventDefault();
        if (complete === true) {
          var payload = {
            completed: true
          }  
        }
        else {
          let check_goals_len = training_goals.length;
          console.log("list :", check_goals_len);
          if (check_goals_len > 0) {
            var payload = {
              training_goals: training_goals
           }
          } 
          else {
              showAlert(true);
          }
          
        }
        
        console.log("PAYLOAD ", payload);
        UserTrainingProgrammService.updateTrainingProgram(payload, training_uuid).then(
          (response) => {
            const data = response.data;
            console.log("------RESPONSE TRAINING PROGRAM---:", data);
            if (complete === true) {
              // the training has been completed 
              setTrainingCompleted(true);
            }
            else {
              // update the list of goals in the training program
              window.location.reload(false);
            }
            
           
          },
          (error) => {
            console.log("TRAINING ERROR", error);
            let status = error.request.status
            let message = JSON.parse(error.request.response);
            console.log("STATUS AND MESSAGE", status, message);
           
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
              console.log("ERROR:", _error_content);
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                navigate("/pages/authentication/sign-in");
              }
            }
        );
      };
    const handleChange = (event) => {
      const { name, value } = event.target;
      console.log("NAME:", name);
      console.log("SET:", (name, value));
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      
      if (name === 'training_goals') {
        setTrainingGoals(training_goals.concat(value));

      }
      else {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      }
      
    };
    const [open, setOpen] = React.useState(false);
   
    const handleClose = () => {
      navigate("/pages/user-training");      
      setOpen(false);
    };

    useEffect(() => {
        UserTrainingProgrammService.getUserTrainingProgram().then(
          (response) => {
            console.log("MY TRAINING PROGRAM :", response);
            if (response.length > 0 ) {
              setLevel(response[0].value);
              setTrainingUUID(response[0].uuid);
              formData.level = response[0].value
              let programs = response[0].training_goals
              programs.map((program) => {
                  maneuvers[response[0].value].map((item) => {
                    if (item.name === program) {
                      console.log("Selected program:",item.name)
                      item["selected"] = true
                    }
                  });
              });
            }
            else {
              navigate("/pages/user-training");

            }

            
            
          },
          (error) => {
            const _content_error =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log(_content_error);
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
              navigate("/pages/authentication/sign-in");
            }
          }
        );
    }, [formData, navigate]);

  return (
    <>
      <ResponsiveAppBar routes={routes} transparent light />
      <Grid item xs={12} lg={12} >
                      {training_completed ? (
                        <Dialog
                        open={true}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            Congratulations, Dude! You did it! Your hard work has paved the way for Success! 
                            Let's move to the next level!
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Let's GO!</Button>
                        </DialogActions>
                      </Dialog>
                      ) : (<></>)}
                      {show_alert ?  (
                          <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="warning" onClose={handleClose}>
                          Please Select the training you want to add to your program.
                    </Alert>
                      ): (<></>)
                      
                    }

      </Grid>
      <Grid container sx={{bgcolor:"#fff"}}>
          <Grid item xs={12} lg={12} sx={{ ml: "auto", padding:2, mt: { xs: 3, lg: 5 } }} >
                  <MKTypography variant="h4" mb={6}>
                  Training Program 
                  </MKTypography>
                  <>
                  <MKTypography variant="body" mb={6}>
                        Level: {selected_Level}
                  </MKTypography>
                  </>
          </Grid>
            <Grid item  xs={12} md={6} sx={{bgcolor:"#fff", padding:2}}>
            {/* <MKTypography variant="body" mb={6} textAlign="center">
                 {surfer_levels[selected_Level]}
            </MKTypography> */}

            </Grid>
            <Grid item  xs={12} md={6} sx={{bgcolor:"#fff"}}>

                <FormControl sx={{ m: 3, bgcolor:"#fff" }} component="fieldset" variant="standard" >

                    <FormLabel component="legend">Current Active Training Program Tasks</FormLabel>

                    <FormGroup>
                        {maneuvers[selected_Level]?.map((task) => (
                            task.selected ? (  
                                <FormControlLabel  control={<Checkbox  defaultChecked disabled={true}/>} key={task.id} label={task.name} name="training_goals" value={task.name} onChange={handleChange}
                                />
                            ): (
                              <></>
                        
                            )
                        ))}
                    </FormGroup>
                    <MKTypography variant="legend" mb={6} mt={6}>
                      Other available tasks
                      </MKTypography>
                    <FormGroup>
                        {maneuvers[selected_Level]?.map((task) => (
                            !task.selected ? (  
                                <FormControlLabel  control={<Checkbox  />} key={task.id} label={task.name} name="training_goals" value={task.name} onChange={handleChange}
                                />
                            ): (
                              <></>
                            )
                        ))}
                    </FormGroup>
                </FormControl>
                <Grid container>
                    <Grid item xs={12} lg={6} alignItems="center" sx={{ ml: "auto", mb: { xs: 3, lg: 5 } }}>
                        <MKButton color="warning" onClick={handleSubmit} size="large" variant="contained" textAlign="center">
                                  Update Your Program
                        </MKButton>
                    </Grid>
                    <Grid item xs={12} lg={6} alignItems="center" sx={{ ml: "auto", mb: { xs: 3, lg: 5 } }}>
                        <MKButton color="success" onClick={(evt) => handleSubmit(evt, true)} size="large" variant="contained" textAlign="center">
                                  Complete Your Training 
                        </MKButton>
                    </Grid>
                </Grid>
                
             </Grid>
        </Grid>
        <MKBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          > 
          <Footer/>
        </MKBox>
        </>
  );
};

export default UserTraining;
