/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import EventBus from "./common/EventBus";

// react-router components
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import AuthService from "services/auth.service";

export default function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  EventBus.on("logout", () => {
    logOut();
  });

  const logOut = () => {
    console.log("LOG OUT!!!! ");
    localStorage.removeItem("user");
    navigate("/pages/authentication/sign-in");

  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        {/* <Route path="/presentation" element={<Presentation />} />  */}
        <Route path="*" element={<Navigate to="/pages/user-board" />} /> 
        {/* <Route path="*" element={<Navigate to="/pages/authentication/sign-in" />} /> */}
      </Routes>
    </ThemeProvider>
  );
}
