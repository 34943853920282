/* eslint-disable no-unused-vars */
import axios from "axios";
import AuthService from "services/auth.service";
import EventBus from "common/EventBus";

const { REACT_APP_BACKEND_URL } = process.env;
const API_URL = REACT_APP_BACKEND_URL;
const API_UPLOAD_URL = API_URL+"medias_upload/multimedia/upload";
const BASE_API_URL  = API_URL+"medias_upload";




const currentUser = AuthService.getCurrentUser();


if (currentUser != null) {
    // console.log("[PROFILE SERVICE]CURRENT USER NOT NULL ", currentUser);
    var access_token = currentUser.access_token;
  } else {
    access_token = undefined;
  }

const multi_upload_files = (surf_session_uuid, files) => {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data; boundary=${bodyFormData._boundary}",
            Authorization: "Bearer " + access_token,
        },
      };
    let bodyFormData = new FormData();
    if (files !== undefined) {
        files.map((file) => {
            console.log("appending:",file)
            bodyFormData.append("files", file);
        })
        
    }
    console.log("BODY :",bodyFormData);
    return axios.post(API_UPLOAD_URL+"?surf_session_uuid="+surf_session_uuid, bodyFormData, config).then(
        (res) => {
          console.log("[MEDIA UPLOAD] RESPONSE:",res.data);
          return res.data;
        },
        (error) => {
          console.log("[MEDIA UPLOAD] error:", error);
          let http_status = error.response.status;
          if (http_status === 401) {
            EventBus.dispatch("logout");
          }
          return error;
        }
      );
}

const get_surf_session_files = (surf_session_uuid) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      };
    return axios.get(BASE_API_URL+"?surf_session_uuid="+surf_session_uuid, config).then(
        (res) => {
            console.log("[MEDIA UPLOAD] RESPONSE:",res.data);
            return res.data;
        },
        (error) => {
          console.log("[MEDIA UPLOAD] error:", error);
          let http_status = error.response?.status || 500 ;
          // eslint-disable-next-line eqeqeq
          if (http_status === 401) {
            EventBus.dispatch("logout");
          }
          return error;
        }
      );

}

const MediaUploadService = {
    get_surf_session_files,
    multi_upload_files
    
  };
  
  export default MediaUploadService;
  