import React from 'react'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

export default function SelectedSpotForecast({forecastData}) {
    return (
       

           <>

           <List
                sx={{ width: '100%', bgcolor: 'background.paper', fontSize:"small"}}
                subheader={<ListSubheader>Forecast {forecastData.spot_name}</ListSubheader>}
            >
           <Grid container spacing={1} alignItems="center">

                <Grid item xs={12} lg={12} >
            
                    <ListItem >
                    <ListItemText id="switch-list-label-bluetooth" primary="Location"  primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}} />
                        {forecastData.spot_city} -  {forecastData.spot_region}

                    </ListItem>
                    <ListItem>
                    <ListItemText id="switch-list-label-bluetooth" primary="Wave Rating" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.wave_rating}
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Wave Size" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.wave_avg_size}m
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Wave Period" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.wave_period}sec
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Weather Condition" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.weather_condition}
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Weather Temp" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.weather_temp}C
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Water Temp" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.water_temp}C
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Wind Direction" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.wind_direction} at {forecastData.wind_speed} Knt
                    </ListItem>
                </Grid>
                {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }} >

                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Weather Condition" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.weather_condition}
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Weather Temp" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.weather_temp}C
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Water Temp" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.water_temp}C
                    </ListItem>
                    <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Wind Direction" primaryTypographyProps={{fontSize: 'small', fontStyle:'normal', fontWeight:'bold'}}/>
                        {forecastData.wind_direction} at {forecastData.wind_speed} Knt
                    </ListItem>
                </Grid> */}
            
        </Grid>
        </List>

        </>
    )
}