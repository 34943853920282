/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Link } from 'react-router-dom';
import ForwardIcon from '@mui/icons-material/Forward';
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// eslint-disable-next-line no-unused-vars
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import React, { useEffect, useState } from "react";
// Images
import sessions_icon from "assets/images/your_sessions.png";

function Profile() {
  
  useEffect(() => {
    
  }, []);
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 2 }} >
      <Container >
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -20, md: -15 }} textAlign="center">
            <MKAvatar
              src={sessions_icon}
              alt=""
              size="xxl"
              shadow="xl"
              

            />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={12} mx={{ xs: "auto", sm: 6, md: 1 }}>

              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1} mt={-5} textAlign="center" >
                <Grid item  xs={12} md={12} >
                    <MKTypography variant="h4" textAlign="center">
                    Access your past sessions
                    </MKTypography>
                </Grid>
              </MKBox>
                <Grid container xs={12} md={12} mt={4} >
                <Grid  item xs={12} md={12}  >
                <MKTypography variant="body2" fontWeight="light" color="text">
                    Check on your past surf sessions
                <MKTypography
                  component="a"
                  href="#"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={0}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                ></MKTypography>
                </MKTypography>
                <MKButton color="info"  size="medium" variant="standard" endIcon={<ForwardIcon fontSize="large"/> }component={Link} to={'/pages/surf-session/list'}>
                  
                </MKButton>

                </Grid>
                
                </Grid>
               </Grid>
            </Grid>
          </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
