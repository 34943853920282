import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Rating from '@mui/material/Rating';
// import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
import MKTypography from "components/MKTypography";
// import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base";
import { styled } from "@mui/system";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

// Routes
import routes from "routes";

import UserTrainingProgressService from "services/training_progress.service";
import UserTrainingProgrammService from "services/training_program.service";
import EventBus from "common/EventBus";
// import { TakeoutDiningSharp } from "@mui/icons-material";

const tasks = [
    {
      id: 1,
      name: 'Take_off',
      slug: 'take-off',
      type: 'beginner',
      locale: 'en',
      enabled: true
    },
    {
      id: 2,
      name: 'Bottom_Turn',
      slug: 'bottom-turn',
      type: 'beginner',
      locale: 'en',
      enabled:true
    },
    {
      id: 3,
      name: 'Take_off',
      slug: 'take-off',
      type: 'beginner',
      locale: 'en',
      enabled: true
    },
    {
      id: 4,
      name: 'Bottom_Turn',
      slug: 'bottom-turn',
      type: 'beginner',
      locale: 'en',
      enabled:true
    },
    {
      id: 5,
      name: 'Take_off',
      slug: 'take-off',
      type: 'beginner',
      locale: 'en',
      enabled: true
    },
    {
      id: 6,
      name: 'Bottom_Turn',
      slug: 'bottom-turn',
      type: 'beginner',
      locale: 'en',
      enabled:true
    },
]


const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#f0f2f5"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function TrainingProgresses() {
  const navigate = useNavigate();
  const location = useLocation();
  const surf_session_data = location.state?.data; // here the surf session uuid received
  const [hover, setHover] = React.useState(-1);
  const [data, setData] = React.useState([{}]);
  const [training_level, setTrainingLevel] = useState();
  const [show_alert, setShowAlert] = useState(false);
  const [alert_level, setAlertLevel] = useState('info')
  const [show_save_button, showSaveButton] = useState('true');
  // const [training_uuid, setTrainingUUID] = useState();
  const [trick_obj, setTrickObject] = useState();
  const [message, setMessage] = useState("");

  const [formData, setFormData] = useState({
  });
  const handleClose = () => {
    setShowAlert(false);
  
  };
  const handleSubmit = (event) => {
    // program_name:Bottom Turn: '5', program_name:Carve: '2.5', comment_1: 'primo commento', comment_2: 'secondo commento '}
    event.preventDefault();
    var payload = {
      "surf_session_uuid": surf_session_data.surf_session.uuid,
      "training_program_uuid": "",
      "progress": {}
    };
    payload.training_program_uuid = surf_session_data.training_program_uuid
    data.map((item,index)=> {
      payload.progress[item.name] = {
        progress: item.progress,
        comment: item.comment
      }
    })
    console.log("PAYLOAD:",payload);
    UserTrainingProgressService.addBulkProgress(payload).then(
          (response) => {
            const data = response.data;
            setShowAlert(true);
            if (response === "ERR_NETWORK") {
              // setSuccessful(false);
              setAlertLevel('error')
              setMessage("You are not connected to a network. Please check your settings");
            }
            else {
              console.log("------RESPONSE ---:", data);
            setMessage("Your progresses have been added to your surf session");
            setAlertLevel('success');
            showSaveButton(false);
            console.log("SURF DATA:",surf_session_data.surf_session);
            setTimeout(() => {
              navigate("/page/surf-session-view",  {state:{data:surf_session_data.surf_session}} );
            }, 2000);

            }
            
          },
          (error) => {
            setShowAlert(true);
            console.log(" ERROR", error);
            let status = error.request.status
            let message = JSON.parse(error.request.response);
            console.log("STATUS AND MESSAGE", status, message);
            setMessage("An error occurred! Please try again.");
            setAlertLevel('error');
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
              console.log("ERROR:", _error_content);
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                navigate("/pages/authentication/sign-in");
              }
            }
        );
  }
 
  

  useEffect(() => {
    console.log(" DATA RECEIVED:", surf_session_data );
    /* 
  
    se la pagina viene ricaricata, presentare i dati esistenti invece che 
    il form

    */
    UserTrainingProgrammService.getUserProgramTracking().then(
      (response) => {
        console.log("MY TRAINING PROGRAM :", response);
        if (response.length > 0 ) {
          var trick_obj = {}
          setTrainingLevel(response[0].level);
          response.map((item,index)=> {
            console.log("INDEX:",index, item.name)
            response[index]["progress"] = ""
            response[index]["comment"] = ""
            let trick_name = item.name
            trick_obj[trick_name] = {
                id: item.id
              
            }
          })
          console.log("RESPONSE NOW:",trick_obj)
          setTrickObject(trick_obj);
          setData(response);
        }
        else {
          navigate("/pages/user-training");
        }

      },
      (error) => {
        const _content_error =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(_content_error);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
          navigate("/pages/authentication/sign-in");
        }
      }
    );
  
}, [navigate, surf_session_data]);

  const handleChange = (event) => {
    event.preventDefault();
    console.log("EVENT:",event)
    const { name, value } = event.target;
    console.log("NAME:", name);
    console.log("SET:", (name, value));
  
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    
    console.log(name.includes("comment_"))
    if (name.includes("comment")) {
       let id = name.substr(8)
       console.log("COMMENT ID:",id)
       data[id-1].comment = value
      }
    if (name in trick_obj) {
        let id = trick_obj[name].id
        console.log("TRICK ID:",id);
        data[id-1].progress = value;
    }
  
  };

  return (
    <>
    <ResponsiveAppBar routes={routes} transparent light />
    <Grid container  spacing={10} marginTop={1} alignItems="center" alignContent="center" marginBottom={5} textAlign="center">
    <Grid item xs={12} lg={12} >
                      {show_alert && message ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity={alert_level} onClose={handleClose}>
                            {message}
                      </Alert>
                      ) : (<></>)}
    </Grid>
    <Grid item lg={12}>
          <MKTypography variant="h3" >
                Tracing your Progresses
          </MKTypography>
    </Grid>
    </Grid>
    <Grid container spacing={2}  marginTop={2} marginBottom={2} paddingBottom={2} borderBottom={1} borderColor="lightgrey" sx={{ mx: "auto" }}>
   
      <Grid item xs={12} md={6} lg={2} marginLeft={20}>
          <MKTypography variant="h6">
                Spot Name
          </MKTypography>
          <MKTypography variant="body2">
            {surf_session_data.surf_session.spot_name} </MKTypography>
      </Grid>
      <Grid item xs={12} md={6} lg={2} >
          <MKTypography variant="h6">
               When
          </MKTypography>
          <MKTypography variant="body2" fontSize="small">
            In:  {surf_session_data.surf_session.start_session_datetime}
          </MKTypography>
          <MKTypography variant="body2" fontSize="small">
            Out:{surf_session_data.surf_session.end_session_datetime}
          </MKTypography>
      </Grid>
      <Grid item xs={12} md={6} lg={2} >
          <MKTypography variant="h6">
                Waves Rating:
          </MKTypography>
          <Stack spacing={2}> 
                      <Rating
                          name="wave_classification"
                          value={surf_session_data.surf_session.wave_classification}
                          precision={0.5}
                          size="small"
                          disabled={true}
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        
          </Stack>
      </Grid>
      <Grid item xs={12} md={6} lg={2} >
          <MKTypography variant="h6">
               Session Rating 
          </MKTypography>
          
          <Stack spacing={1}> 
                      <Rating
                          name="wave_classification"
                          value={surf_session_data.surf_session.session_rating}
                          precision={0.5}
                          size="small"
                          disabled={true}
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        
          </Stack>
      </Grid>
      <Grid item xs={12} md={6} lg={2} >
          <MKTypography variant="h6">
               My Mood 
          </MKTypography>
          
          <Stack spacing={1}> 
                      <Rating
                          name="wave_classification"
                          value={surf_session_data.surf_session.my_mood}
                          precision={0.5}
                          size="small"
                          disabled={true}
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        
          </Stack>
      </Grid>


    </Grid>
    <Grid container >
   
    <Grid item xs={12} md={6} lg={6} marginLeft={5}>
        <MKTypography variant="h6">
               Your Training Level:  <MKTypography variant="body" fontSize="medium">
            {training_level}
          </MKTypography>
        </MKTypography>
         
      
    </Grid>
    <Grid item xs={12} md={6} lg={6} marginLeft={5} marginTop={5}>

    <List
      sx={{ width: '100%' }}
     
    >
        {data.map((task) => (
          <>
        <ListItem>
            <MKTypography variant="body2">{task.name}</MKTypography>
            <Rating
                  name={task.name}
                  value={formData[task.name]}
                  key={task.id}
                  id={task.id}
                  precision={0.5}
                  onChange={(event, newValue) => {
                      handleChange(event, newValue);
                  } }
                  onChangeActive={(_event, newHover) => {
                      setHover(newHover);
                  } }
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
            
          </ListItem>
          <Textarea
                          variant="standard"
                          label="Your Comment"
                          fullWidth
                          minRows={5}
                          id={task.id}
                          name={"comment_"+task.id}
                          value={formData.comment}
                          onChange={(event, newValue) => {
                            handleChange(event, newValue);
                        } }
                          placeholder={"Write your comment on how you perfomed on the "+ task.name}
            />
          </>
          ))}
      </List>
            
    {/* </Box> */}
    </Grid>
    </Grid>
    <Grid container spacing={3} marginTop={5}>
                  {show_save_button ? (
                  <Grid item xs={12} md={6} align="right">
                    <MKButton variant="gradient" color="info" onClick={handleSubmit}>
                      Save
                    </MKButton>
                  </Grid>
                  ) : (<></>)}
                </Grid>  
    </>

  );
}