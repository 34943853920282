/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem"; 
import SearchIcon from '@mui/icons-material/Search';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Loader from "common/Loader";
import FormHelperText from '@mui/material/FormHelperText';
// Custom Services
import UserService from "services/user.service";
import SurfForecast from "services/surf_forecast.service";
import SurfForecastService from "services/surf_forecast.service";
import UserSurfSpotService from "services/surf_spot.service";
import UserSurfboardService from "services/surfboard.service";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';


// Icons 
import ForwardIcon from '@mui/icons-material/Forward';

// Modal 

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import "./style.css"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});




function SelectSpotForm() {
      const [spot_name, setSpotName] = useState("");
      const [isLoading, setLoading] = useState(false);
      const [spot_lists, setListOfSpots] = useState([]);
      const [show_spot_items, showSpotItems] = useState(false);
      const [show_current_spot, showCurrentSpot] = useState(false);
      const [spot_not_found, setNotFound] = useState();
      const [start_datetime, setStartSurf] = useState();
      const [stop_datetime, setStopSurf] = useState();
      const [not_same_day, setNotTheSameDay] = useState(false);
      const [in_the_future, setDateinTheFuture] = useState(false);
      const [surfed_today, setSurfedDateToday] = useState(true);
      const [current_spot, setCurrentSpot] = useState({});
      const [favorite_stored, setFavoriteStored] = useState(false);
      const [fav_surf_spots, setFavSurfSpots] = useState();
      const [open, setOpen] = useState(true);
      const [show_button, setShowButton] = useState(false);
      const [selected_fav, selectFav] = useState('');
      const [getForecast, setGetForecastOnline] = useState(false);
      const [no_surfboard, setNoSurfboard] = useState(false);
      const [alread_saved, setAlreadySaved] = useState(false);
      const [formData, setCoordToLookup] = useState({});

      const navigate = useNavigate();
      const handleClose = () => {
        setFavoriteStored(false);
        setOpen(false);
      
      };
      const handleSpotName = (event) => {
        event.preventDefault();
        setSpotName(event.target.value);
      }

      const handleFavSelect = (event) => {
        console.log("SELECTED ",event.target.value);
        selectFav(event.target.value);
        setShowButton(true);
      };
      const getForecastOnLine = (event) => {
        console.log("SET",event.target.value);
        setGetForecastOnline(!getForecast);
        console.log("getForecast:",getForecast);
        
      }
      const goToSurfBoardPage = () => {
          navigate("/pages/user-profile/surfboards");

      }

      const handleFormAction = () => {
        let data = current_spot;
        data['start_session_datetime'] = start_datetime
        data['stop_session_datetime'] = stop_datetime
        console.log("DATA TO PASS: ",data);
        navigate("/pages/spot-info", {state: {data: data}});

      }

      



      const getSpotbyCoord = (spot_data) => {
        setLoading(true);
        console.log("FORM DATA:",formData)
        SurfForecastService.search_spot_by_lat_and_long(spot_data?.lat, spot_data?.lon).then(
          (response) => {
            const data = response.data;
            console.log("SEARCH BY COORD  RESPONSE :", data);
            data["located"] = true;
            setCurrentSpot(data);
            // setResponse(undefined);
            setLoading(false);
            showCurrentSpot(true);
            showSpotItems(false);
          },
          (error) => {
            console.log("NO FOUND");
            setNotFound("SPOT NOT FOUND");

            setLoading(false);
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log("ERROR:", _error_content);
          }
        );
      
      }

      const setStartDateTime = (start_date) => {
        setDateinTheFuture(false);
        setNotTheSameDay(false);
        setOpen(false);
        setSurfedDateToday(false);
        const formattedDate = start_date.format('YYYY-MM-DD HH:mm:ss');
        setStartSurf(formattedDate);
        
        console.log("START FORMATTED :",formattedDate, start_datetime);
        const today = dayjs();

        // console.log("BEFORE:",start_date.isBefore(today));
        // console.log("AFTER:",start_date.isAfter(today));
        // console.log("SAME:",start_date.isSame(today,'day'));
        
        if (start_date.isAfter(today)) {
          console.log("Setting future");
          setOpen(true);
          setDateinTheFuture(true);

        }
        // if (start_date.isBefore(today)) {
        //   setOpen(true);
        //   setNotTheSameDay(true);
        //   console.log("BEFORE!Setting not today: OPEN IS :",open);

        // }
        if(start_date.isSame(today, 'day')) {
          setSurfedDateToday(true);
       
        }
      }

      const setStopDateTime = (stop_date) => {
        const formattedDate = stop_date.format('YYYY-MM-DD HH:mm:ss');
        setStopSurf(formattedDate);

      }

      const handleCoordChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        console.log("Set ",name,value);
        setCoordToLookup((prevFormData) => ({ ...prevFormData, [name]: value }));

      }

      const reset_view_value = () => {
        setNotFound(undefined);
        showSpotItems(false);
        showCurrentSpot(false);
      };

      const save_spot = (spot_data) => {
        console.log("SAVE SPOT:",spot_data);
        let payload = {
          "spot_id": spot_data.spot_id,
          "lat": spot_data.lat,
          "lon":spot_data.lat,
          "spot_name": spot_data.name,
          "region": spot_data.breadCrumbs[1],
          "county": spot_data.breadCrumbs[2],
          "state": spot_data.breadCrumbs[0],
          "city": spot_data.name,
          "custom": false,
          "secret": false,
          "favorite": true

        }
        console.log("PAYLOAD:",payload)
        UserSurfSpotService.storeSurfSpot(payload).then(
          (response) => {
            const data = response.data;
            console.log("------RESPONSE SURF SPOT---:", data);
            setLoading(false);
            setOpen(true);
            setFavoriteStored(true);
            getFavouriteSpots();
           
          },
          (error) => {
            console.log("SURF SPOT ERROR", error);
            let status = error.request.status
            let message = JSON.parse(error.request.response)
            console.log("STATUS AND MESSAGE", status, message);
            setAlreadySaved(true);
            setLoading(false);
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log("ERROR:", _error_content);
          }
        );
      }
      const getFavouriteSpots = () => {
        UserSurfSpotService.getFavouriteSpots().then(
          (response) => {
            const my_fav_spots = response;
            console.log("--FAV SPOTS:",my_fav_spots);
            setFavSurfSpots(response);
            return response;
          },
          (error) => {
            console.log("ERROR FAV")
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log("ERROR:", _error_content);

          }
        )
      }
  
      const handleSelectedSpotForm = () => {
          /* 
          This function manages the selected spot and passes its data to the /pages/spot-info.
          If the spot is selected from the list of results returned from the forecast service, 
          it's needed to get the complete data by looping through the object of spot_items, where 
          all the results are stored. 
          This is because the checkbox component doesn't allow the entire object to be passed as the 
          value on the onClick event. 
          All the required information is already present if the spot is selected from the list of 
          favorite ones. 
          */
            console.log("Getting INFO TO PASS TO THE NEXT PAGE", getForecast);
            if (typeof selected_fav == "string") {
              // eslint-disable-next-line array-callback-return
              spot_lists.map(spot => {
                if (spot.spot_id === selected_fav) {
                  
                  console.log(" FOUND THIS :",spot);
                  
                  spot['start_session_datetime'] = start_datetime
                  spot['stop_session_datetime'] = stop_datetime
                  if (getForecast) {
                    // Here we are going to send a request for getting the forecasts
                    // 
                    console.log("------GET FORECAST ON LINE: ", spot);
                    navigate("/pages/spot-info", {state: {data: spot}});
                  }
                  else {
                    console.log("Goind without forecast", spot);
                    navigate("/pages/edit-spot-info", {state: {data: spot}});
                  }
                }
              
              })
            } 
            else {
              /* ----------                                 -------*/
              selected_fav['start_session_datetime'] = start_datetime
              selected_fav['stop_session_datetime'] = stop_datetime
              selected_fav['wave_rating'] = undefined
              selected_fav['wave_avg_size'] = undefined
              selected_fav['wave_period'] = undefined
              selected_fav['wind_speed'] = undefined
              selected_fav['wind_direction'] = undefined
              selected_fav['weather_temp'] = undefined
              selected_fav['weather_condition'] = undefined
              selected_fav['water_temp'] = undefined
              if (getForecast) {
                console.log("ELSE WITH getForecast")
                navigate("/pages/spot-info", {state: {data: selected_fav}});
              }
              else {
                console.log("Going without forecast", selected_fav);
                navigate("/pages/edit-spot-info", {state: {data: selected_fav}});
              }
              
            }
         
          

      }
      const handleSearchSpotByName = () => {
        /*
        This function search the given spot by its name
        */

        reset_view_value();
        setLoading(true);
        SurfForecast.search_spot_by_name(spot_name).then(
          (response) => {
            const data = response.data;
            console.log("RESPONSE:", data);
            setListOfSpots(response.data);
            setLoading(false);
            showSpotItems(true);
            showCurrentSpot(false);
            getFavouriteSpots();
           
          },
          (error) => {
            console.log("SPOT NO FOUND");
            setNotFound("SPOT NOT FOUND");
            console.log("show_spot_items:", show_spot_items);
            setLoading(false);
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log("ERROR:", _error_content);
          }
        );
      };

      // ------------------ GEO LOCALITATION -----------------

      const LocateSpot = () => {
        setLoading(true);
        reset_view_value();
    
        var options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };
        function success(pos) {
          var crd = pos.coords;
          // let my_location = undefined;

          console.log("Your current position is:");
          console.log(`Latitude : ${crd.latitude}`);
          console.log(`Longitude: ${crd.longitude}`);
          console.log(`More or less ${crd.accuracy} meters.`);
    
          SurfForecastService.search_spot_by_lat_and_long(crd.latitude, crd.longitude).then(
            (response) => {
              const data = response.data;
              data["located"] = true;
              
              setCurrentSpot(data);
              console.log("LOCATING SPOT RESPONSE :", data);

              // my_location = data;
              // setResponse(undefined);
              setLoading(false);
              showCurrentSpot(true);
              // showSpotItems(false);
            },
            (error) => {
              console.log("NO FOUND");
              setNotFound("SPOT NOT FOUND");

              setLoading(false);
              const _error_content =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              console.log("ERROR:", _error_content);
            }
          );
        }
    
        function errors(err) {
          console.warn(`ERROR(${err.code}): ${err.message}`);
        }
    
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
            console.log("RESULT ", result);
            if (result.state === "granted") {
              //If granted then you can directly call your function here
              navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "prompt") {
              //If prompt then the user will be asked to give permission
              navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
            }
          });
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      };
     

      useEffect(() => {
        UserService.getUserBoard().then((response) => {
          console.log("[SELECTSPOT]RESPONSE FROM AUTH:", response);
        });
        setGetForecastOnline(false);
        setShowButton(false);
        getFavouriteSpots();
        UserSurfboardService.getSurfboards().then(
        (response) => {
          if (response.length === 0 ) {
            setNoSurfboard(true);
          }
        },
        (error) => {
          const _content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("ERROR:",_content)
  
        }
      );
      }, []);
    
     
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      
      <Container>
              <Grid container  marginBottom={3} justifyContent="center" mx="auto"  align = "center" justify = "center" alignItems = "center">
                  {/* Alert Zone */}
                  <Grid item xs={12} lg={12} >
                      {open && favorite_stored ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" onClose={handleClose}>
                            Your spot has been added to your list of favourite
                      </Alert>
                      ) : (<></>)}

                      {open && not_same_day ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="warning" onClose={handleClose}>
                            You didn't go surfing today. We can only fetch your spot's forecast for today. 
                            Don't worry, though. You can enter the information yourself here.  
                      </Alert>
                      ) : (<></>)}  

                      {open && in_the_future ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="warning" onClose={handleClose}>
                            Roads? Where we're going, we don't need roads! Wow dude! Are you Marty McFly? 
                      </Alert>
                      ) : (<></>)}  

                      {open && alread_saved ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="info" onClose={handleClose}>
                            You have already saved this spot in your list of favorites. 
                      </Alert>
                      ) : (<></>)}  



                      {no_surfboard ? (
                          <Dialog
                          open={true}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleClose}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogTitle>{"You haven't insert your surfboard"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                              Let's us know what surfboard do you use
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={goToSurfBoardPage}>Go to my Surfboard</Button>
                          </DialogActions>
                        </Dialog>

                      ): (<></>)}  
                  
                      
                  </Grid>

                  <Grid item xs={12} lg={12} >
                    
                    <MKBox display="flex" justifyContent="center" alignItems="center">
                      <MKTypography variant="h3">
                      Log Your Surf Session
                      </MKTypography>
                  </MKBox>
                  {/* <MKBox display="flex" justifyContent="center" alignItems="center" mb={1}>
                      <MKTypography variant="body2" color="text" >
                      Search you spot, locate it using geolocation or insert your (secret) new spot.
                    </MKTypography>
                    </MKBox> */}
                  </Grid>
                
                  <Grid item xs={12} lg={6} alignItems="center" marginTop={3}>

                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                          {/* <DemoContainer components={['DateTimePicker']} > */}
                            <DateTimePicker 
                                label="When did you start surfing?"  
                                value={start_datetime}
                                name="start_session_datetime"
                                onChange={(newValue) => setStartDateTime(newValue)}
                            />
                        </LocalizationProvider>

                  </Grid>

                  <Grid item xs={12} lg={6} alignItems="center" marginTop={3}>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* <DemoContainer components={['DateTimePicker']} > */}
                        <DateTimePicker 
                            label="When did you finish surfing?"  
                            value={stop_datetime}
                            name="end_session_datetime"
                            backgroundColor="black"
                            onChange={(newValue) => setStopDateTime(newValue)}/>
                      {/* </DemoContainer> */}
                    </LocalizationProvider>

                  </Grid>
                  {/* use geolocalization to find the nearest spot */}
                  <Grid item xs={12} lg={6}>
                          <MKTypography variant="body2" color="text" pl={2}>
                            <MKButton variant="gradient" color="info" onClick={LocateSpot}>
                              Locate you Spot
                            </MKButton>
                          </MKTypography>
                  </Grid>
                  
                   {/* Select fav spots */}
                  <Grid item xs={12} lg={6}>
                      <MKBox display="flex" alignItems="center" p={2}>
                      <MKBox
                            width="3rem"
                            height="3rem"
                            variant="gradient"
                            bgColor="info"
                            color="white"
                            coloredShadow="info"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="xl"
                          >
                            <Icon >home</Icon>
                          </MKBox>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="wetsuit-label">Your Favorite Spots</InputLabel>
                          <Select
                                name="fav_spot"
                                id="fav_spot"
                                value={selected_fav}
                                onChange={handleFavSelect}
                                label="Select from your favourite spot"
                              >
                                <MenuItem value={selected_fav}>
                                  <em></em>
                                </MenuItem>
                                {fav_surf_spots?.map((item) => (
                                  <MenuItem key={item.key} value={item}>
                                    {item.value}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>Select one of your favourite spots</FormHelperText>

                          </FormControl>
                        </MKBox>
                  </Grid>


                  {/* Search the spot by coord */}
                  <Grid container justifyContent="flex-start" spacing={1}>

                  <Grid item xs={12} lg={6}>
                    <MKBox display="flex" alignItems="center" p={2}>
                      <MKBox
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        coloredShadow="info"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="xl"
                      >
                      </MKBox>
                      {/* <MKButton size="medium" variant="gradient" color="info" onClick={getSpotbyCoord(formData)} startIcon={<SearchIcon></SearchIcon>}>
                      </MKButton> */}
                      <MKButton size="small" color="info" onClick={() => getSpotbyCoord(formData)}    endIcon={<ForwardIcon></ForwardIcon>}>
                                Get Forecast
                      </MKButton>

                      <MKTypography variant="body2" color="text" pl={2}>
                        <MKInput
                          variant="standard"
                          label="Insert Spot Lat"
                          name="lat"
                          fullWidth
                          onChange={(e) => handleCoordChange(e)} />
                      </MKTypography>
                      <MKTypography variant="body2" color="text" pl={2}>
                        <MKInput
                          variant="standard"
                          name="lon"
                          label="Insert Spot Lon"
                          fullWidth
                          onChange={(e) => handleCoordChange(e)} />
                      </MKTypography>
                    

                    </MKBox>
                  </Grid>
                    

                  {/* Search the spot by name */}
                  <Grid item xs={12} lg={6}>
                      <MKBox display="flex" alignItems="center" p={2}>
                          <MKBox
                            variant="gradient"
                            bgColor="info"
                            color="white"
                            coloredShadow="info"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="xl"
                          >
                            
                          </MKBox>
                          <MKButton size="medium" variant="gradient" color="info" onClick={handleSearchSpotByName} startIcon={<SearchIcon></SearchIcon>}>
                          </MKButton>
                          <MKTypography variant="body2" color="text" pl={2}>
                          
                            <MKInput
                              variant="standard"
                              fullWidth
                              label="Insert your Spot Name"
                              onChange={(e) => handleSpotName(e)}
                              
                            />
                            
                          </MKTypography>
                        </MKBox>
                  </Grid> 
                 </Grid>
                  {/* spot fetched by geolocalitation */}
                  
              </Grid> 
                  
                  {show_current_spot ? (
                    <Grid container justifyContent="flex-start" spacing={1}>
                      <Grid item xs={12} lg={3}>
                            <Card sx={{ maxWidth: 345 }}>
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image="https://placehold.co/200x200"
                                  sx={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: "0",
                                    justifyContent: "center",
                                    display: "flex",
                                    width: "100%",
                                    borderBottomLeftRadius: "0",
                                    borderBottomRightRadius: "0"
                                  }}
                                  alt="" />
                                <CardContent>
                                  <MKTypography gutterBottom variant="h4" component="div" align="center">
                                    {current_spot?.spot_name}
                                  </MKTypography>

                                  <MKTypography gutterBottom variant="h6" component="div" align="center">
                                    {current_spot?.breadcrumb[3]}
                                  </MKTypography>

                                  <MKTypography variant="h5" color="text.secondary" align="center">
                                    {current_spot?.spot_region} {current_spot?.breadcrumb[0]}
                                  </MKTypography>
                                  <MKTypography gutterBottom variant="body2" component="div" align="center">
                                    {current_spot?.wave_rating}
                                  </MKTypography>

                                  <MKTypography variant="body2" color="text.secondary" align="center">
                                    Wave Size:{current_spot?.wave_avg_size} Period:{current_spot?.wave_period}
                                  </MKTypography>
                                </CardContent>
                              </CardActionArea>
                              <CardActions>
                              <MKButton size="small" color="white" variant="outlined"onClick={() => handleFormAction()}    endIcon={<ForwardIcon></ForwardIcon>}>
                                  Select
                                </MKButton>
                                {/* <MKButton size="small" color="info" variant="outlined" onClick={() => handleOpenModal(board)}  startIcon={<VisibilityIcon></VisibilityIcon>}>
                                  Details
                                </MKButton>
                                <MKButton size="small" color="info" variant="outlined" onClick={() => handleOpenModal(board)}  startIcon={<VisibilityIcon></VisibilityIcon>}>
                                  Save
                                </MKButton> */}
                              </CardActions>


                            </Card>
                      </Grid>
                    </Grid>
                  ) : (
                  <></>
                  )}    

              {show_spot_items ? (
              
              <Grid container justifyContent="flex-start" spacing={1}>
                  {spot_lists.map((spot) => (
                      <Grid item xs={12} lg={3}>
                        <Card sx={{ maxWidth: 345 }}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image="https://placehold.co/200x200"
                              sx={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "0",
                                justifyContent: "center",
                                display: "flex",
                                width: "100%",
                                borderBottomLeftRadius: "0",
                                borderBottomRightRadius: "0"
                              }}
                              alt="" />
                            <CardContent>
                              <MKTypography gutterBottom variant="h4" component="div" align="center">
                                {spot?.name}
                              </MKTypography>

                              <MKTypography gutterBottom variant="h6" component="div" align="center">
                                {spot?.breadCrumbs[3]}
                              </MKTypography>
                              <MKTypography variant="body2" color="text.secondary" align="center">
                                {spot?.lat},{spot?.lon}
                              </MKTypography>
                              
                              <MKTypography variant="h5" color="text.secondary" align="center">
                                {spot?.breadCrumbs[1]} {spot?.breadCrumbs[2]}
                              </MKTypography>
                              
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                          <MKButton size="small" color="white" variant="outlined"onClick={() => getSpotbyCoord(spot)}    endIcon={<ForwardIcon></ForwardIcon>}>
                                Get Forecast
                              </MKButton>

                          </CardActions>


                        </Card>
                      </Grid>
                    ))}
              </Grid>
            ) : (
              <></>
          )} 



        <Grid container  justifyContent="center" mx="auto" mt={10}>                    
              {isLoading ? ( <Loader/>): (<></>)}
              {/* results */}
              <Grid item xs={12} lg={6} >

              {spot_not_found ? (
                <MKTypography variant="h6">No spots were found! Try again.</MKTypography>
              ) : (
                <></>
              )}
              

              <MKTypography
                component="a"
                href="#"
                variant="body2"
                color="info"
                fontWeight="regular"
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: "translateX(3px)",
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: "translateX(6px)",
                  },
                }}
              ></MKTypography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MKBox textAlign="center">
                {show_button && surfed_today? (
                  
                  <><MKTypography variant="body2">
                    Do you want I can find information about how big the waves were today?
                  </MKTypography>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox onChange={getForecastOnLine} />} label="Get the Spot Forecast Online" />
                  </FormGroup>
                  
                 
                  </>
                ): (<></>)}
                {show_button? ( <MKButton variant="gradient" color="info" onClick={handleSelectedSpotForm}>NEXT</MKButton>) : 
                (<></>)}
                </MKBox>
              </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SelectSpotForm;
