import React, { useState, useEffect} from "react";
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import Grid from "@mui/material/Grid";
import Button from '@material-ui/core/Button'

import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';

import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TheatersIcon from '@mui/icons-material/Theaters';
import ImageIcon from '@mui/icons-material/Image';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Routes
import routes from "routes";

const handlePreviewIcon = (fileObject, classes) => {
  const {type} = fileObject.file
  const iconProps = {
    className : classes.image,
  }

  if (type.startsWith("video/")) return <TheatersIcon {...iconProps} />
//   if (type.startsWith("audio/")) return <AudioTrack {...iconProps} />

  switch (type) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <DescriptionIcon {...iconProps} />
    case "application/pdf":
      return <PictureAsPdfIcon {...iconProps} />
    default:
      return <ImageIcon {...iconProps} />
  }
}

export default function UploadFiles() {
    
    const [open, setOpen] = useState(false);
    const [fileObjects, setFileObjects] = useState([]);
    const useStyles = makeStyles(theme => createStyles({
        previewChip: {
          minWidth: 160,
          maxWidth: 210
        },
      }));
      
    const classes = useStyles();
   
    return (
        <>
        <ResponsiveAppBar routes={routes} transparent light />
        <Grid container  marginLeft={1} spacing={1} alignItems="center" marginTop={10} padding={5}>
            <Grid item xs={6} md={12} lg={6}  padding={1} >
                <DropzoneArea
                    filesLimit={10}
                    fileObjects={fileObjects}
                    onAdd={newFileObjs => {
                        console.log('onAdd', newFileObjs);
                        setFileObjects([].concat(fileObjects, newFileObjs));
                    } }
                    onDelete={deleteFileObj => {
                        console.log('onDelete', deleteFileObj);
                    } }
                    // getPreviewIcon={handlePreviewIcon} 
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                    previewChipProps={{ classes: { root: classes.previewChip } }}
                    previewText="Selected files" />
            </Grid>
            <Grid item lg={6} padding={1}>
            <div>
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Add Image
                </Button>

                <DropzoneDialog
                    filesLimit={10}
                    acceptedFiles={['image/*']}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    maxFileSize={5000000}
                    open={open}
                    onClose={() => setOpen(false)}
                    onSave={(files) => {
                    console.log('Files:', files);
                    setOpen(false);
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    useChipsForPreview
                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                    previewChipProps={{ classes: { root: classes.previewChip } }}
                    previewText="Selected files"
                />
</div>
            </Grid>
        </Grid>
        </>
    )
    
}

