/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import MKButton from "components/MKButton";
import Alert from '@mui/material/Alert';
// Icons
import SurfingIcon from '@mui/icons-material/Surfing';
import SnowboardingIcon from '@mui/icons-material/Snowboarding';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import CakeIcon from '@mui/icons-material/Cake';
import ScaleIcon from '@mui/icons-material/Scale';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import WavesIcon from '@mui/icons-material/Waves';
import BadgeIcon from '@mui/icons-material/Badge';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
// Material Kit 2 React components
import MKBox from "components/MKBox";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKInput from "components/MKInput";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MKTypography from "components/MKTypography";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
// Routes
import routes from "routes";
// Local functions
import UserProfileService from "services/profile.service";
// Default Profile Image
import default_upload_image from "assets/images/upload.png";

// Style the upload button
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


function Information() {
  const [current_user, setCurrentUser] = useState();
  const [message, setMessage] = useState("");
  // const [successful, setSuccessful] = useState(false);
  const [profile_picture_file, setFile] = useState();
  const [preview_img, setPreview] = useState();
  const [user_profile_data, setUserProfileData] = useState({});
  const [alert_level, setAlertLevel] = useState('info')
  const location = useLocation();
  const profile_and_user_data = location.state?.profileData;
  const [error, setError]=useState(false);
  const navigate = useNavigate();

  
  console.log(" DATA RECEIVED FOR PROFILE:", profile_and_user_data);
  /* 
  #
  # {
    "data": {
            "current_user": {
                "username": "hellbreak",
                "first_name": "Antonio",
                "last_name": "Di Mariano",
                "email": "hellbreak@gmail.com",
                "is_active": true,
                "is_superuser": false,
                "scopes": "read",
                "uuid": "40335030-9338-4041-b918-221b75536e10"
            
        },
        "user_profile_data": {
            
        }
    }
}
  #
  #
  */

  const handle_upload_img = (event) => {
    console.log("FILE :",event.target.files[0])
    /* This function is responsibile for uploading the profile's image */
    setFile(event.target.files[0]);
    setPreview(URL.createObjectURL(event.target.files[0]));
    console.log("image:", preview_img);
  };

  const handleSubmit = (event) => {
    /* This function sends the form to the backend */
    event.preventDefault();
    console.log("handleSubmit");
    let payload = {
      activity:  user_profile_data.activity,
      stance: user_profile_data.stance,
      age:  parseInt(user_profile_data.age),
      height:  parseFloat(user_profile_data.height),
      weight:  parseFloat(user_profile_data.weight),
      home_town:  user_profile_data.home_town,
      home_spot:  user_profile_data.home_spot,
      nickname: user_profile_data.nickname ,
      instagram_profile:  user_profile_data.instagram_profile,
      facebook_profile:  user_profile_data.facebook_profile,
      twitter_profile:  user_profile_data.twitter_profile,
      youtube_profile:  user_profile_data.youtube_profile,
    };
    console.log("PAYLOAD:", payload);
    console.log("File:", profile_picture_file);

    

    UserProfileService.upadteUserProfile(payload, profile_picture_file).then(
      (response) => {
        const data = response.data;
        console.log("RESPONSE :", data);
        if (response === "ERR_NETWORK") {
          // setSuccessful(false);
          setAlertLevel('error')
          setMessage("You are not connected to a network. Please check your settings");

        }

        else {
          // setSuccessful(true);
          setMessage("All done, Dude! Ready to get the new one?");
          setAlertLevel('success')
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 2000)
        }

      },
      (error) => {
        // setSuccessful(false);
        console.log("ERROR----",error)
        setMessage("Something did wrong!");
        setAlertLevel('error')
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
           error.toString();
          console.log(_content);
      }
    );
  };

  
  const handleChange = (event) => {
    /* This function handles the values of all the Form's input fields */

    const { name, value } = event.target;
    console.log("NAME:", name);
    console.log("SET:", (name, value));
    setUserProfileData(values => ({...values, [name]: value}))
    if ( value === 'sup') {
      console.log("HERE SUP")
      setSupChecked(!isSupChecked)
      
    }
    if ( value === 'surf') {
      console.log("HERE SURF")

      setSurfChecked(!isSurfChecked)
    }
    if ( value === 'goofy') {
      setStance('goofy')
    }
    if ( value === 'regular') {
      setStance('regular')
    }
    
  };

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
    const [isSurfChecked, setSurfChecked] = useState(false)
    const [isSupChecked, setSupChecked] = useState(false)
    const [stanceValue, setStance] = useState('goofy')
    const fetchImage = async (imageUrl) => {
    /* This function fetches the profile's image */
    const res = await fetch(imageUrl);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setPreview(imageObjectURL);
    console.log(" SET DEFAULT IMAGE ",imageObjectURL, imageUrl);
  };

  useEffect(() => {

    if (profile_and_user_data === undefined) {
      navigate("/");
    }
    else {
      setCurrentUser(profile_and_user_data.data.current_user);
      UserProfileService.getUserProfile().then((response) => {
        console.log("-------[PROFILE]FETCH USER PROFILE:",response)
          /* update the form with the fetched data */
          if (response === "ERR_NETWORK") {
            setError("It seems you are not connected to a network. Please try again.")
          }
          setUserProfileData(values => ({...response}));
        
      });
      
      fetchImage(user_profile_data.profile_pic_url);
      if (user_profile_data?.profile_pic_url === "string") {
        fetchImage(default_upload_image);
      }
      if (user_profile_data?.profile_pic_url === undefined ) {
        /* no profile was found */
        fetchImage(default_upload_image); 
      }
      if (user_profile_data?.activity === "surf") {
        console.log("SURF SURF SURF ")
        setSurfChecked(true);
       }
      if (user_profile_data?.activity === "sup") {
        setSupChecked(true);
       }
       if (user_profile_data?.stance === 'regular') {
        setStance("regular");
      }
      if (user_profile_data?.stance === 'goofy') {
        console.log("HERE GOOFY")
        setStance("goofy");
      }
    }
    
  }, [navigate, profile_and_user_data, user_profile_data?.activity, user_profile_data.profile_pic_url, user_profile_data?.stance]);

  return (
    <><ResponsiveAppBar routes={routes} />
      <Container sx={{mt:4}}>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="/pages/user-board"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
        </Breadcrumbs>
      </div>
      {error ? (
            <Alert severity="error">{error}</Alert>
           ) : (<p></p>)}

      </Container>
      
      <MKBox component="form" py={12} method="patch">
        <Container>
          
        <Grid container spacing={1} alignItems="center">


          <Grid item xs={12} lg={6}>
            <MKTypography variant="h4" mb={6}>
              Your Full Profile
            </MKTypography>

            <Grid container justifyContent="flex-start" spacing={1} borderRight={1} borderColor="grey">
              <Grid item xs={6} md={6}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <BadgeIcon fontSize="large"></BadgeIcon>
                  <DefaultInfoCard title="First Name" description="" icon=""/>
                  <MKInput
                    variant="standard"
                    label=""
                    type="text"
                    id="age"
                    name="age"
                    disabled={true}
                    value={current_user?.first_name} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={6}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <BadgeIcon fontSize="large"></BadgeIcon>
                  <DefaultInfoCard title="Last Name" description="" icon="" />
                  <MKInput
                    variant="standard"
                    label=""
                    type="text"
                    id="last_name"
                    name="last_name"
                    disabled={true}
                    value={current_user?.last_name} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={6}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <AlternateEmailIcon fontSize="large"></AlternateEmailIcon>
                  <DefaultInfoCard title="Email" description="" icon=""/>
                  <MKInput
                    variant="standard"
                    label=""
                    type="email"
                    id="email"
                    name="email"
                    disabled={true}
                    value={current_user?.email} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={6}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <FaceRetouchingNaturalIcon fontSize="large"></FaceRetouchingNaturalIcon>
                  <DefaultInfoCard title="Nickname" description="" icon=""/>
                  <MKInput
                    variant="standard"
                    label=""
                    type="text"
                    id="nickname"
                    name="nickname"
                    onChange={handleChange}
                    value={user_profile_data.nickname} />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>



          <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <Card style={{ backgroundColor: "white" }}
              raised
              sx={{
                maxWidth: 380,
                margin: "0 auto",
                padding: "0.1em",
              }}
            >
              {preview_img && (
                <CardMedia
                  component="img"
                  image={preview_img}
                  title="profile"
                  alt="profile"
                  height="250"
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }} />
              )}
              <CardActions>
                <Button
                  variant="contained"
                  component="label"
                  color="grey"
                  size="small"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload File
                  <VisuallyHiddenInput type="file" onChange={handle_upload_img} />
                </Button>
              </CardActions>
            </Card>

          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          marginTop={10}
        >
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start" spacing={1} >
              <Grid item xs={6} md={2}>
                <MKBox mb={6}>

                  <SurfingIcon fontSize="large"></SurfingIcon>
                  <DefaultInfoCard title="Your Activity" description="" icon=""/>
                  <FormGroup>
                    <FormControlLabel
                      
                      control={<Checkbox checked={isSurfChecked}/>}
                      label="Surf"
                      name="activity"
                      value="surf"
                      onChange={handleChange} />
                    <FormControlLabel 
                      control={<Checkbox checked={isSupChecked}/>} 
                      label="SUP" 
                      name="activity"
                      value="sup"
                      onChange={handleChange} />
                  </FormGroup>
                </MKBox>
              </Grid>
              <Grid item xs={6} md={2}>
                <MKBox mb={6}>
                  <SnowboardingIcon fontSize="large"></SnowboardingIcon>
                  <DefaultInfoCard title={stanceValue} description="" icon=""/>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="stance"
                      value={stanceValue}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="goofy" control={<Radio />} label="Goofy" />
                      <FormControlLabel value="regular" control={<Radio />} label="Regular" />
                    </RadioGroup>
                  </FormControl>
                </MKBox>
              </Grid>
              <Grid item xs={6} md={2}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <CakeIcon fontSize="large"></CakeIcon>
                  <DefaultInfoCard title="Your Age" description="" icon=""/>
                  <MKInput
                    variant="standard"
                    label=""
                    type="int"
                    id="age"
                    name="age"
                    value={user_profile_data?.age}                  
                    onChange={handleChange} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={2}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <LineWeightIcon fontSize="large"></LineWeightIcon>
                  <DefaultInfoCard title="Your Height" description="" icon="">
                  </DefaultInfoCard>
                  <MKInput
                    variant="standard"
                    label=""
                    type="text"
                    id="height"
                    name="height"
                    value={user_profile_data?.height}
                    onChange={handleChange} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={2}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <ScaleIcon fontSize="large"></ScaleIcon>
                  <DefaultInfoCard title="Your Weight" description="" icon=""/>
                  <MKInput
                    variant="standard"
                    label=""
                    type="text"
                    id="weight"
                    name="weight"
                    value={user_profile_data?.weight}
                    inputProps={{
                      maxLength: 13,
                      step: "1"
                    }}
                    onChange={handleChange} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={2}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <LocationCityIcon fontSize="large"></LocationCityIcon>
                  <DefaultInfoCard title="Home Town" description="" icon=""/>
                  <MKInput
                    variant="standard"
                    label=""
                    type="text"
                    id="home_town"
                    name="home_town"
                    value={user_profile_data?.home_town}
                    onChange={handleChange} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={2}>
                
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <WavesIcon fontSize="large"></WavesIcon>
                  <DefaultInfoCard title="Home Spot" description="" />
                  <MKInput
                    variant="standard"
                    label=""
                    type="text"
                    id="home_spot"
                    name="home_spot"
                    value={user_profile_data.home_spot}
                    onChange={handleChange} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <InstagramIcon fontSize="large"></InstagramIcon>
                  <DefaultInfoCard title="Instagram Account" description=""/>
                  <MKInput
                    variant="standard"
                    label=""
                    fullWidth
                    type="text"
                    id="instagram_profile"
                    name="instagram_profile"
                    value={user_profile_data.instagram_profile}
                    onChange={handleChange} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <FacebookIcon fontSize="large"></FacebookIcon>
                  <DefaultInfoCard title="Facebook Account" description="">
                  </DefaultInfoCard>
                  <MKInput
                    variant="standard"
                    label=""
                    fullWidth
                    type="text"
                    id="facebook_profile"
                    name="facebook_profile"
                    value={user_profile_data.facebook_profile}
                    onChange={handleChange} />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={{ xs: 6, md: 0 }}>
                  <YouTubeIcon fontSize="large"></YouTubeIcon>
                  <DefaultInfoCard title="YouTube Account" description="" />
                  <MKInput
                    variant="standard"
                    label=""
                    fullWidth
                    type="text"
                    id="youtube_profile"
                    name="youtube_profile"
                    value={user_profile_data.youtube_profile}
                    onChange={handleChange} />
                </MKBox>
              </Grid>
              
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start" spacing={1} sx={{ ml: "auto", mt: { xs: 3, lg: 10 } }}>
              <Grid item xs={12} lg={12}>
                <MKButton color="info" onClick={handleSubmit} size="large" variant="contained">
                  Save
                </MKButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        {message && (
                <Alert variant="filled" severity={alert_level}>{message}</Alert>

          
          // <div className="form-group">
          //   <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
          //     {message}
          //   </div>
          // </div>
        )}
      </Container>
      </MKBox>
      <Footer /></>

);
}

export default Information;
