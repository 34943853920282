import React, {  useEffect } from "react";
import AuthService from "services/auth.service";
import { useNavigate } from "react-router-dom";


function Logout() {
    console.log("LOG OUT HERE ")
    const navigate = useNavigate();

    useEffect(() => {
        // AuthService.logout();
        localStorage.removeItem("user");
        navigate("/pages/authentication/sign-in");

        
      }, [navigate]);
      return (<></>);
}
export default Logout;


