import axios from "axios";
const { REACT_APP_FORECAST_SERVICE_URL } = process.env;
const API_URL = `${REACT_APP_FORECAST_SERVICE_URL}`;


const search_spot_by_lat_and_long = (latitude, longitude) => {
  console.log("Spot by Lat and Lon:", latitude, longitude);
  return axios.get(API_URL + "forecasts/spot?lat=" + latitude + "&lon=" + longitude);
};

const search_spot_by_name = (name) => {
  let server_url = API_URL + "forecasts/spot/search?spot_name=" + name
  console.log("------Spot by name----:", name, server_url);
  return axios.get(server_url);
};

const search_nearby_spots = (spot_id) => {
  console.log("Nearby Spos ", spot_id);
  return axios.get(API_URL + "forecasts/spot/nearby?spot_id=" + spot_id);
};

const SurfForecast = {
  search_spot_by_lat_and_long,
  search_spot_by_name,
  search_nearby_spots,
};

export default SurfForecast;
