/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthService from "services/auth.service";
import EventBus from "common/EventBus";
const currentUser = AuthService.getCurrentUser();

const { REACT_APP_BACKEND_URL } = process.env;
const BASE_API_URL = REACT_APP_BACKEND_URL;
const API_URL = BASE_API_URL+"users/";

console.log("-------- SERVICE URL -------",API_URL);

if (currentUser != null) {
  // console.log("[USER SERVICE]CURRENT USER NOT NULL ", currentUser);
  var access_token = currentUser.access_token;
} else {
  access_token = undefined;
}

// console.log("[USER SERVICE]ACCESS TOKEN:", access_token);
const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  
  return axios.get(API_URL + "me", { headers: { Authorization: "Bearer " + access_token } }).then(
    (response) => {
      console.log("[USER SERVICE]ME::", response.data);
      return response.data;
    },
    (error) => {
      console.log("[USER SERVICE]ERROR ON ME:", error.toString());
      if (error.response && error.response.status === 401) {
        console.log("Redirecting to sign-in");
        EventBus.dispatch("logout");
        return error;
      }
    }
  );
};
const checktoken = () => {
  return axios.get(API_URL + "me", { headers: { Authorization: "Bearer " + access_token } }).then(
    (response) => {
      console.log("[USER SERVICE]CHECK TOKEN---------:", response.data);
      return response.data;
    },
    (error) => {
      console.log("[USER SERVICE]ERROR:", API_URL, error.toString());
      if (error.response && error.response.status === 401) {
        return error;
      }
    }
  );
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod");
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin");
};

const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  checktoken
};

export default UserService;
