/* eslint-disable no-unused-vars */
import axios from "axios";
import AuthService from "services/auth.service";
import EventBus from "common/EventBus";

const { REACT_APP_BACKEND_URL } = process.env;
const BASE_API_URL = REACT_APP_BACKEND_URL;
const API_URL = BASE_API_URL+"surf_spot";
const currentUser = AuthService.getCurrentUser();


if (currentUser != null) {
  var access_token = currentUser.access_token;
} else {
  access_token = undefined;
}


const getFavouriteSpots = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
  };
  console.log("CALLING:", API_URL + "?compact=True")
  return axios.get(API_URL + "?compact=True",  config).then(
    (res) => {
      console.log("RESPONSE FAV:",res);
      return res.data;
    },
    (error) => {
      let http_status = error.response.status;
      console.log("[FAV SURF SPOT] error:", error,http_status);

      // eslint-disable-next-line eqeqeq
      // if (http_status === 401) {
      //   EventBus.dispatch("logout");
      // }
      return error;
    }
  );
};


const storeSurfSpot = (payload) => {
   console.log("SEND TO",API_URL, payload)
  return axios.post(API_URL , payload, {
    headers: { Authorization: "Bearer " + access_token },
  });
};


const UserSurfSpotService = {
  storeSurfSpot,
  getFavouriteSpots

  
};

export default UserSurfSpotService;
