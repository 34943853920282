/* eslint-disable no-unused-vars */
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import Grid from "@mui/material/Grid";
import MKInput from "components/MKInput";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
import MKButton from "components/MKButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MKTypography from "components/MKTypography";
// import Icon from "@mui/material/Icon";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
import Card from "@mui/material/Card";

// Routes
import routes from "routes";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Loader from "common/Loader";

import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import UserService from "services/user.service";

import bgImage from "assets/images/surfingwallpaper56.jpeg";

const EditSpotInfo = () => {
  

  const Navigate = useNavigate();

  const [wave_avg_size, setWaveAvgSize] = useState("");
  const [wave_period, setWavePeriod] = useState("");
  const [wind_direction, setWindDirection] = useState("");
  const [wind_speed, setWindSpeed] = useState("");
  const [weather_temp, setWeatherTemp] = useState("");
  const [weather_condition, setWeatherCondition] = useState("");
  const [water_temp, setWaterTemp] = useState("");

  const [spot_city, setSpotCity] = useState("");
  const [spot_county, setSpotCounty] = useState("");
  const [spot_country, setSpotCountry] = useState("");
  const [spot_region, setSpotRegion] = useState("");
  const [wave_rating, setWaveRating] = useState();

  const [isLoading, setLoading] = useState(false);
  const [current_spot_info, setCurrentSpot] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonTest, setButtonText] = useState("EDIT");
  const [show_next_button, showNextButton] = useState(true);

  const location = useLocation();
  const selected_spot = location.state?.data;

  const [dropdown, setDropdown] = useState(null);
  const [dropup, setDropup] = useState(null);

  // const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  // const closeDropdown = () => setDropdown(null);

  // const openDropup = ({ currentTarget }) => setDropup(currentTarget);
  // const closeDropup = () => setDropup(null);

  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const dropupIconStyles = {
    transform: dropup ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  // console.log("HERE DATA RECEIVED FROM :", selected_spot);

  let wind_directions = [
    { label: "No Data", value: "No Data" },
    { label: "Cross-shore", value: "Cross-shore" },
    { label: "Cross-onshore", value: "Cross-onshore" },
    { label: "Cross-offshore", value: "Cross-offshore" },
    { label: "Onshore", value: "Onshore" },
    { label: "Offshore", value: "Offshore" },
  ];

  let weather_conditions = [
    { label: "No Data", value: "No Data" },
    { label: "LIGHT RAIN", value: "LIGHT_RAIN" },
    { label: "LIGHT SHOWERS", value: "LIGHT_SHOWERS" },
    { label: "MODERATE RAIN", value: "MODERATE_RAIN" },
    { label: "HEAVY RAIN", value: "HEAVY_RAIN" },
    { label: "CLEAR", value: "CLEAR" },
    { label: "CLOUD", value: "CLOUD" },
  ];

  let wave_ratings = [
    { label: "No Data", value: "No Data" },
    { label: "FLAT", value: "flat" },
    { label: "POOR", value: "poor" },
    { label: "POOR TO FAIR", value: "poor_to_fair" },
    { label: "FAIR", value: "fair" },
    { label: "FAIR TO GOOD", value: "fair_to_good" },
    { label: "GOOD", value: "good" },

  ];

  let handleWaveRatingChange = (e) => {
    setWaveRating(e.target.value);
  };

  let handleWindDirectionChange = (e) => {
    setWindDirection(e.target.value);
  };

  let handleWeatherConditiononChange = (e) => {
    setWeatherCondition(e.target.value);
  };

  const buildPayload = useCallback(() => {
    
    const keys_to_check = ['wave_avg_size', 'wave_period', 'weather_temp','water_temp']
    const payload = {
      spot_id : selected_spot.spot_id,
      spot_name: selected_spot.name,
      spot_region: spot_region ? spot_region : selected_spot?.breadCrumbs[1],
      spot_city: spot_city ? spot_city : selected_spot.spot_city,
      spot_forecast_url: selected_spot?.spot_forecast_url,
      wave_rating: wave_rating ? wave_rating : selected_spot.wave_rating,
      wave_avg_size: parseFloat(wave_avg_size ? wave_avg_size : selected_spot.wave_avg_size ),
      wave_period: parseFloat(wave_period ? wave_period : selected_spot.wave_period ),
      wind_speed: wind_speed ? wind_speed : selected_spot.wind_speed,
      wind_direction: wind_direction ? wind_direction : selected_spot.wind_direction,
      weather_temp: parseFloat(weather_temp ? weather_temp : selected_spot.weather_temp ),
      weather_condition: weather_condition ? weather_condition : selected_spot.weather_condition,
      water_temp: parseFloat(water_temp ? water_temp : selected_spot.water_temp ),
      breadCrumbs: [
        spot_country ? spot_country : selected_spot.breadCrumbs[0],
        spot_region ? spot_region : selected_spot?.breadCrumbs[1],
        spot_county ? spot_county : selected_spot?.breadCrumbs[2],
        spot_city ? spot_city : selected_spot?.spot_city,
      ],
      start_session_datetime: selected_spot.start_session_datetime,
      stop_session_datetime: selected_spot.stop_session_datetime

    };
    if (isNaN(payload['wave_avg_size'])) {
        payload['wave_avg_size'] = undefined
    }
    if (isNaN(payload['wave_period'])) {
      payload['wave_period'] = undefined
    }

    if (isNaN(payload['weather_temp'])) {
      payload['weather_temp'] = undefined
    }

    if (isNaN(payload['water_temp'])) {
      payload['water_temp'] = undefined
    }


    Object.keys(payload).forEach(function(key, index) {
      if (key in keys_to_check) {
        if (isNaN(payload[key])) {
          console.log("TO BE SET TO UNDEFINED DUE TO NaN:", key);
          // payload[key] = undefined
          delete payload[key]
      }
      }
      
    });
    console.log("NEW PAYLOAD:",payload);



    return payload;
  })
  const handleEditButton = (event) => {
    event.preventDefault();

    setIsDisabled(!isDisabled);
    
    if (buttonTest === "EDIT") {
      setButtonText("SAVE");
      showNextButton(false);
    } else {
      setButtonText("EDIT");
      showNextButton(true);
    }
    
    const payload = buildPayload();
    console.log("PAYLOAD TO PASS :", payload);
    setCurrentSpot(payload);
  };


  useEffect(() => {
    console.log("DATA RECEIVED FROM PAGE:", selected_spot);
    UserService.getUserBoard().then((response) => {
      // console.log("RESPONSE FROM AUTH:", response);
    });
    if (selected_spot === undefined) {
      Navigate("/pages/select-spot");
    } 
    if (current_spot_info === undefined) {
      const payload = buildPayload();
      console.log("PAYLOAD:", payload);
      setCurrentSpot(payload);
    }
  
  }, []);

  return isLoading ? (
    <><ResponsiveAppBar routes={routes} transparent light /><Loader /></>
  ) : (
    <>
      <ResponsiveAppBar routes={routes} transparent light />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -30,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >

      <Grid container item xs={12} lg={7} sx={{ mx: "auto"}}>
        
      <MKTypography variant="h2" mb={6} textAlign="center" >
          Your spot {selected_spot?.spot_name}
        </MKTypography>

      

      <Grid container spacing={3} >
          {/* <Grid item xs={12} md={6} sx={{ marginBottom: 3 }}>
                  <MKButton color="info" href={selected_spot?.spot_forecast_url} target="_blank">
                    Check Live Forecast for {selected_spot?.spot_name}
                    <Icon sx={{ ml: 1 }}>{<LocationOnIcon />}</Icon>
                  </MKButton>
                  
          </Grid> */}
          <Grid item xs={12} md={6} sx={{ marginBottom: 3}}>
                      <MKButton 
                          color="info" 
                          onClick={handleEditButton}>
                          {buttonTest}
                      </MKButton>
          </Grid>
          <MKTypography variant="h3" mb={6} textAlign="center" >
              How were the conditions today?
        </MKTypography>
      </Grid>        


        <MKBox
          width="100%"
          alignItems="center"
          method="post"
          component="form"
          autoComplete="on"
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, `,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <MKBox component="section" sx={{ p: 2}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Spot Name"
                  fullWidth
                  disabled
                  type="text"
                  id="spot_name"
                  name="spot_name"
                  defaultValue={selected_spot?.name || ''}
                  // onChange={(e) => setSpotCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Spot Country"
                  fullWidth
                  disabled
                  type="text"
                  id="spot_country"
                  name="spot_country"
                  defaultValue={selected_spot?.breadCrumbs[0] || ''}
                  onChange={(e) => setSpotCountry(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Spot Region"
                  fullWidth
                  disabled
                  type="text"
                  id="spot_region"
                  name="spot_region"
                  defaultValue={selected_spot?.breadCrumbs[1] || ''}
                  onChange={(e) => {
                    e.preventDefault()
                    setSpotRegion(e.target.value)}
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Spot County"
                  fullWidth
                  disabled
                  type="text"
                  id="spot_county"
                  name="spot_county"
                  defaultValue={selected_spot?.breadCrumbs[2] || ''}
                  onChange={(e) => setSpotCounty(e.target.value)}
                />
              </Grid>
            </Grid>
          </MKBox>

          <MKBox component="section" sx={{ p: 2 }}>
            <MKTypography variant="h6" mb={6}>
              Water Data:
            </MKTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Wave Heigth"
                  fullWidth
                  type="text"
                  disabled={isDisabled}
                  id="wave_avg_size"
                  name="wave_avg_size"
                  onChange={(e) => {
                    e.preventDefault()
                    setWaveAvgSize(e.target.value)}
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Wave Period"
                  fullWidth
                  type="text"
                  disabled={isDisabled}
                  id="wave_period"
                  name="wave_period"
                  onChange={(e) => setWavePeriod(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Water Temp"
                  fullWidth
                  type="text"
                  disabled={isDisabled}
                  id="water_temp"
                  name="water_temp"
                  onChange={(e) => setWaterTemp(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="wave_rating">Wave Rating</InputLabel>
                  <Select
                    labelId="wave_rating"
                    id="wave_rating"
                    disabled={isDisabled}
                    defaultValue="No Data"
                    onChange={handleWaveRatingChange}
                    label="Wave Rating"
                  >
                    <MenuItem value={selected_spot?.wave_rating}>
                      <em>{selected_spot?.wave_rating}</em>
                    </MenuItem>
                    {wave_ratings.map((rating) => (
                      <MenuItem key={rating.value} value={rating.value}>
                        {rating.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </MKBox>
          <MKBox component="section" sx={{ p: 2}}>
            <MKTypography variant="h6" mb={6}>
              Weather Data:
            </MKTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Weather Temp"
                  fullWidth
                  type="text"
                  disabled={isDisabled}
                  defaultValue=""
                  id="weather_temp"
                  name="weather_temp"
                  onChange={(e) => setWeatherTemp(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MKInput
                  variant="standard"
                  label="Wind Speed"
                  size="small"
                  type="text"
                  disabled={isDisabled}
                  defaultValue=""
                  id="wind_speed"
                  name="wind_speed"
                  onChange={(e) => setWindSpeed(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="wind_direction_label">
                    Wind Direction
                  </InputLabel>
                  <Select
                    labelId="wind_direction"
                    id="wind_direction"
                    disabled={isDisabled}
                    onChange={handleWindDirectionChange}
                    defaultValue="No Data"
                    label="Wind Direction"
                  >
                    <MenuItem value={selected_spot?.wind_direction}>
                      <em>
                        {selected_spot?.wind_direction}{" "}
                      </em>
                    </MenuItem>
                    {wind_directions.map((direction) => (
                      <MenuItem key={direction.value} value={direction.value}>
                        {direction.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="weather_condition">Weather Condition</InputLabel>
                  <Select
                    disabled={isDisabled}
                    onChange={handleWeatherConditiononChange}
                    defaultValue="No Data"
                    label="Weather Condition"
                  >
                    <MenuItem value={selected_spot?.weather_condition}>
                      <em>{selected_spot?.weather_condition}</em>
                    </MenuItem>
                    {weather_conditions.map((condition) => (
                      <MenuItem key={condition.value} value={condition.value}>
                        {condition.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </MKBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} align="right">
            {show_next_button ? (
                <MKButton 
                variant="gradient" color="info" 
                component={Link} to="/pages/surf-session" 
                state={{ data: current_spot_info }}
                >NEXT
         </MKButton>
            ): <></>}
            
            </Grid>
            
          </Grid>
        </MKBox>
      </Grid>
      </Card>
      <Footer />
      </MKBox>                  
    </>
  );
};

export default EditSpotInfo;