import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "services/user.service";
import SurfForecastService from "services/surf_forecast.service";
import Loader from "common/Loader";
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import routes from "routes";



const FetchForecast = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const selected_spot = location.state?.data;
    const [current_spot, setCurrentSpot] = useState();
    const [isLoading, setLoading] = useState(true);
    

    useEffect(() => {
        console.log(" ****DATA RECEIVED****   :",selected_spot);
        UserService.getUserBoard().then((response) => {
          console.log("[FETCHSPOT]RESPONSE FROM AUTH:", response);
        });

        if (current_spot !== undefined) {
                setCurrentSpot(current_spot);
                // setResponse(undefined);
                setLoading(false);
                console.log("CURRENT SPOT HAS FORECAST DEFINED. USE IT",current_spot)
                navigate('/pages/surf-session', {state: { data: current_spot }});
        }
        else {
          if (selected_spot === undefined) {
            console.log("NO SPOT SELECTED")
            navigate("/pages/select-spot");
          } 

          else if (selected_spot.located) {
            console.log(" SPOT HAS BEEN LOCATED");
            setCurrentSpot(selected_spot);
          } 
          else if (selected_spot === undefined) {
            console.log("SELECTED SPOT IS UNDEFINED")
            navigate("/pages/select-spot");
          }
          else if (current_spot === undefined) {
            console.log("NO FORECAST FOR THE CURRENT SPOT. LOOK IT BY LAT AND LONG");
            SurfForecastService.search_spot_by_lat_and_long(selected_spot.lat, selected_spot.lon).then(
              (response) => {
                const data = response.data;
                console.log("RESPONSE FROM SEARCH SPOT--------- :", data);
                console.log("SELECTED SPOT PAYLOAD --------- :", selected_spot);
                delete data["breadcrumb"]; // for some reason, surfline API adds a dict breadcrumb, with more or less the same info 
                // of "breadCrumbs"
                let payload =  { ...data, ...selected_spot };
                console.log("payload:",payload);
                setLoading(false);
                navigate('/pages/surf-session', {state: { data: payload }});
              },
              (error) => {
                setLoading(false);
                const _content_error =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                console.log("ERROR FROM SEARCH SPOT :",_content_error);
                navigate('/pages/select-spot');

              }
            );
          
          }
          
        }
      }, [selected_spot, current_spot, navigate]);


      return isLoading ? (
          <><ResponsiveAppBar routes={routes} transparent light />
          <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -40,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        ><Loader></Loader></Card>
          <Footer />
          </MKBox>
          </>
      
      ) : (
        <></>
    )

    };

export default FetchForecast;
