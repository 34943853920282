/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedResponsiveAppBar from "layouts/sections/navigation/auth-bar";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem"; 
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Loader from "common/Loader";
import FormHelperText from '@mui/material/FormHelperText';

// Custom Services
import EventBus from "common/EventBus";
import UserTrainingProgrammService from "services/training_program.service";


// Routes
import routes from "routes";

// Modal 

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});




function SurfSessionLandingPage() {
      const location = useLocation();
      const surf_session_data = location.state?.data; // here the surf session uuid received
      const [surf_session_uuid, setSurfSessionUUID] = useState("");
      const [training_program_uuid, setTrainingProgramUUID] = useState(false);
      const [spot_items, setResponse] = useState();
      const [favorite_stored, setFavoriteStored] = useState(false);
      const [open, setOpen] = useState(true);
      const [show_button, setShowButton] = useState(false);
      const navigate = useNavigate();
      const handleClose = () => {
        setFavoriteStored(false);
        setOpen(false);

      };
      
      
      const goToSurfBoardPage = () => {
          navigate("/pages/user-profile/surfboards");

      }
  
      // // Automatically close the alert after 3000 milliseconds (3 seconds)
      // setTimeout(handleClose, 7000);     

      /* to remove */
      useEffect(() => {
        console.log(" DATA RECEIVED:", { surf_session_data });
        UserTrainingProgrammService.getUserProgramTracking().then(
            (response) => {
              console.log("MY TRAINING PROGRAM :", response);
              if (response.length > 0 ) {
                setTrainingProgramUUID(response[0]["uuid"])
                console.log("SAVED THE TRAINING PROGRAM UUID:",response[0]["uuid"])
              }
              else {
                // TODO: what to do if a training program has not been created yet?
                // How to get back to the last surf session ?
                navigate("/pages/user-training");
              }
      
            },
            (error) => {
              const _content_error =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              console.log(_content_error);
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                navigate("/pages/authentication/sign-in");
              }
            }
          );
      }, []);
      
     

  return (
    <>
    <AuthenticatedResponsiveAppBar routes={routes} transparent light /><MKBox component="section" py={{ xs: 6, sm: 12 }}>

          <Container>
              <Grid container justifyContent="center" mx="auto">
                  {/* Alert Zone */}
                  <Grid item xs={12} lg={12}>
                      {open && favorite_stored ? (
                          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" onClose={handleClose}>
                              Your spot has been added to your list of favourite
                          </Alert>
                      ) : (<></>)}


                  </Grid>

                  <Grid item xs={12} lg={12}>

                      <MKBox display="flex" justifyContent="center" alignItems="center" mb={1}>
                          <MKTypography variant="h3">
                              Great! Consistency is what transforms average to Excellence!
                          </MKTypography>
                      </MKBox>
                      <MKBox display="flex" justifyContent="center" alignItems="center" mb={1}>
                          <MKTypography variant="body2" color="text">
                              In this page you can track the progress in your training program and upload your photos and videos.
                          </MKTypography>
                      </MKBox>
                  </Grid>
                  <Grid container mt={5} mb={5}>

                      <Grid item xs={12} lg={6}></Grid>
                  </Grid>
                  <Grid container sx={{ ml: { xs: -2, lg: "auto" } }}>
                      {/* Manage the training program */}
                      <Grid item xs={12} lg={4}>
                          <MKBox display="flex" alignItems="center" p={2}>
                          <MKButton 
                                variant="gradient" color="info" 
                                component={Link} to="/pages/surf-session" 
                                > Manage your Training Program
                        </MKButton>
                          </MKBox>
                      </Grid>
                      {/* Track the progresses  */}
                      <Grid item xs={12} lg={4}>
                          <MKBox display="flex" alignItems="center" p={2}>
                            <MKButton 
                                    variant="gradient" color="info" 
                                    component={Link} to="/pages/active-training-goals/progress" 
                                    state={{ data: {training_program_uuid:training_program_uuid,
                                    surf_session:surf_session_data} }}
                                    >Track your Progresses 
                            </MKButton>
                          </MKBox>

                      </Grid>
                      {/* Upload Medias */}
                      <Grid item xs={12} lg={4}>
                        <MKBox display="flex" alignItems="center" p={2}>
                                <MKButton 
                                        variant="gradient" color="info" 
                                        component={Link} to="/pages/surf-session" 
                                        >Uppload your phots and videos 
                                </MKButton>
                            </MKBox>
                      </Grid>
                  </Grid>
              </Grid>
          </Container>
      </MKBox></>
  );
}

export default SurfSessionLandingPage;
