import React, { useState, useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import AuthenticatedResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import { DropzoneDialog } from 'material-ui-dropzone';
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base";
import { styled } from "@mui/system";
import MKButton from "components/MKButton";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
// Icons
import CollectionsIcon from '@mui/icons-material/Collections';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Stack from '@mui/material/Stack';
import RoomIcon from '@mui/icons-material/Room';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";

// Routes
import routes from "routes";
// Services 
import EventBus from "common/EventBus";
import UserSurfboardService from "services/surfboard.service";
import UserTrainingProgrammService from "services/training_program.service";
import UserTrainingProgressService from "services/training_progress.service";
import SurfSessionService from "services/surf_session.service";
import MediaUploadService from "services/media_upload.service";
import "./custom_css.css"

/* all icons from <a href="https://www.flaticon.com/free-icons/wetsuit" title="wetsuit icons">Wetsuit icons created by monkik - Flaticon</a> */
//<a href="https://www.flaticon.com/free-icons/surfboard" title="Surfboard icons">Surfboard icons created by Mayor Icons - Flaticon</a>
//<a href="https://www.flaticon.com/free-icons/wave" title="wave icons">Wave icons created by Ayub Irawan - Flaticon</a>
//<a href="https://www.flaticon.com/free-icons/mood" title="mood icons">Mood icons created by Freepik - Flaticon</a>
//<a href="https://www.flaticon.com/free-icons/surfing" title="surfing icons">Surfing icons created by Culmbio - Flaticon</a>
//<a href="https://www.flaticon.com/free-icons/star" title="star icons">Star icons created by Freepik - Flaticon</a>
//<a href="https://www.flaticon.com/free-icons/spot" title="spot icons">Spot icons created by Freepik - Flaticon</a>



const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };
  
  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#f0f2f5"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  function Loader() {
    return (
      <Grid style={{display: 'flex', justifyContent: 'center'}}margin="20%">
       <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
  
  /> 
      </Grid>
    )
  }
  

const VisuallyHiddenInput =  {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'red',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}



export default function SurfSessionView() {
    const navigate = useNavigate();
    const location = useLocation();
    const surf_session = location.state?.data; // here the surf session uuid received
    const [surfboard, setSurfboard] = useState({});
    const [training_program, setTrainingProgram] = useState({});
    const [training_progress, setTrainingProgress] = useState([]);
    const [data, setData] = useState([{}]);
    const [show_upload_success, showUploadSuccess] = useState(false);
    const [show_upload_error, showUploadError] = useState(false);

    const [training_level, setTrainingLevel] = useState();
    const [show_no_surfboard, showAlertNoSurfboard] = useState(false);
    const [show_no_training_program, showAlertNoTrainingProgram] = useState(false);
    const [show_no_training_progress, showAlertNoTrainingProgress] = useState(false);
    const [alert_before_delete, showAlertBeforeDelete] = useState(false);
    const [error_saving_progress, showErrorSaveProgress] = useState(false);
    const [empty_progress_payload, showEmptyProgressPayloadAlert] = useState(false);
    const [success_progress_save, showSuccessProgressSave] = useState(false);
    const [hover, setHover] = useState(-1);
    const [trick_obj, setTrickObject] = useState();
    const [formData, setFormData] = useState({});
    const [open, setOpen] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    // const [fileObjects, setFileObjects] = useState([]);
    const useStyles = makeStyles(theme => createStyles({
        previewChip: {
          minWidth: 160,
          maxWidth: 210
        },
        
      }));
      
    const classes = useStyles();
    
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

      
    const handleConfermDelete = () => {
        let surf_session_uuid = surf_session.uuid
        SurfSessionService.deleteSurfSession(surf_session_uuid).then(
            (response) => {
              const data = response;
              console.log("RESPONSE:", data);
              console.log("PROGRESS:",show_no_training_progress)
              if (data.status === 202) {
                // delete the linked progresses, if any
                if (!show_no_training_progress) {
                    UserTrainingProgressService.deleteTrainingProgress(surf_session_uuid).then(
                        (response) => {
                            console.log("RESPONSE 2:",response);
                            if (response.status === 202) {
                                showAlertBeforeDelete(false);
                                navigate("/pages/surf-session/list");
                            }
                        },
                        (error) => {
                              const _content =
                                (error.response && error.response.data && error.response.data.message) ||
                                error.message ||
                                error.toString();
                                console.log("ERROR:",_content)
                      
                            }
                        
                    )
                }
              }
            
            },
            (error) => {
           
              const _content =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
                console.log("ERROR:",_content)
      
            }
          );
      };
    const handleDelete = () => {
        showAlertBeforeDelete(true);
      }
    const handleClose = () => {
        showAlertBeforeDelete(false);
        window.location.reload(false);
      };
    
    const closeProgressSuccess = () => {
        showSuccessProgressSave(false);
        window.location.reload(false);


    }
     
    const handleChange = (event) => {
        event.preventDefault();
        console.log("EVENT:",event)
        const { name, value } = event.target;
        console.log("NAME:", name);
        console.log("SET:", (name, value));
      
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        
        console.log(name.includes("comment_"))
        if (name.includes("comment")) {
           let id = name.substr(8)
           console.log("COMMENT ID:",id)
           data[id-1].comment = value
          }
        if (name in trick_obj) {
            let id = trick_obj[name].id
            console.log("TRICK ID:",id);
            data[id-1].progress = value;
        }
        
      
      
      };
    
    
    const handleSubmit = (event) => {
        // program_name:Bottom Turn: '5', program_name:Carve: '2.5', comment_1: 'primo commento', comment_2: 'secondo commento '}
        event.preventDefault();
        showErrorSaveProgress(false);
        var payload = {
          "surf_session_uuid": surf_session.uuid,
          "training_program_uuid": training_program.uuid,
          "progress": {}
        };
        payload.training_program_uuid = training_program.uuid;
        data.map((item,index)=> {
          if ( item.progress === "" ) {
            showEmptyProgressPayloadAlert(true);

          }
          payload.progress[item.name] = {
            progress: item.progress,
            comment: item.comment
          }
        })
        console.log("PAYLOAD:",payload);
        setIsDataLoading(true);
        UserTrainingProgressService.addBulkProgress(payload).then(
              (response) => {
                setIsDataLoading(false);
                showSuccessProgressSave(true)
                const data = response.data;
                console.log("------RESPONSE ---:", data);
                // window.location.reload(false);

            },
              (error) => {
                console.log(" ERROR", error);
                setIsDataLoading(false);
                showErrorSaveProgress(true);
                // window.location.reload(false);
                const _error_content =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                  console.log("ERROR:", _error_content);
                  if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                    navigate("/pages/authentication/sign-in");
                  }
                }
            );
      }

    const handleUpload = (files) => {
      setIsDataLoading(true);
      MediaUploadService.multi_upload_files(surf_session.uuid, files).then(
        (response) => {
          setIsDataLoading(false);
          console.log("OK!",response);
          setIsDataLoading(false);
          // alert('File upload complete');
          setOpen(false);
          showUploadSuccess(true);

        },
        (error) => {
          console.log(" ERROR", error);
          setIsDataLoading(false);
          showUploadError(true);
          const _error_content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("ERROR:", _error_content);
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
              navigate("/pages/authentication/sign-in");
            }
          }
      )


    }

    useEffect(() => {
        console.log(" DATA RECEIVED:", surf_session );
        console.log("PROGRESS STATUS:",show_no_training_progress)
        setIsDataLoading(true);
        let surfboard_uuid = surf_session.surfboard_uuid;
        console.log("Surfboard uuid:",surfboard_uuid);
        UserSurfboardService.get_surfboard(surfboard_uuid).then(
            (response) => {
              setIsDataLoading(false);
                console.log("RESPONSE:",response);
                if (response?.length > 0) {
                    setSurfboard(response);
                    console.log("STORED SURFBOARD:",response);
                } 
                else {
                    console.log("NO SURFBOARD FOUND");
                    showAlertNoSurfboard(true);
                }

            },
            (error) => {
              setIsDataLoading(false);

                const _content_error =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                console.log(error.response, _content_error);
                if (error.response && error.response.status === 401) {
                  EventBus.dispatch("logout");
                  navigate("/pages/authentication/sign-in");
                }
              }
        )
        UserTrainingProgrammService.getUserTrainingProgram().then(
            (response) => {
                console.log("TRAINING :",response);
                if (response?.length > 0) {
                    setTrainingProgram(response[0]);
                    console.log("STORED TRAINING:",response[0]);
                } 
                else {
                    console.log("NO TRAINING FOUND");
                    showAlertNoTrainingProgram(true);
                }

            },
            (error) => {
                const _content_error =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                console.log(error.response);
                if (error.response && error.response.status === 401) {
                  EventBus.dispatch("logout");
                  navigate("/pages/authentication/sign-in");
                }
              }
        )
        UserTrainingProgressService.getUserTrainingProgress(surf_session.uuid).then(
            (response) => {
                console.log("PROGRESS X THIS SESSSION :",response);
                if (response?.length > 0) {
                    setTrainingProgress(response);
                    console.log("STORED TRAINING:",response);
                } 
                else {
                    console.log("NO PROGRESS FOR THIS SESSION");
                    showAlertNoTrainingProgress(true);
                    UserTrainingProgrammService.getUserProgramTracking().then(
                        (response) => {
                          console.log("MY TRAINING PROGRAM :", response);
                          if (response.length > 0 ) {
                            var trick_obj = {}
                            setTrainingLevel(response[0].level);
                            response.map((item,index)=> {
                              console.log("INDEX:",index, item.name)
                              response[index]["progress"] = ""
                              response[index]["comment"] = ""
                              let trick_name = item.name
                              trick_obj[trick_name] = {
                                  id: item.id
                                
                              }
                            })
                            console.log("RESPONSE NOW:",trick_obj)
                            setTrickObject(trick_obj);
                            setData(response);
                          }
                          else {
                            navigate("/pages/user-training");
                          }
                  
                        },
                        (error) => {
                          const _content_error =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                          console.log(_content_error);
                          if (error.response && error.response.status === 401) {
                            EventBus.dispatch("logout");
                            navigate("/pages/authentication/sign-in");
                          }
                        }
                      );
                }

            },
            (error) => {
                const _content_error =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                console.log(error.response);
                if (error.response && error.response.status === 401) {
                  EventBus.dispatch("logout");
                  navigate("/pages/authentication/sign-in");
                }
              }
        )
       

    }, [navigate, show_no_training_progress, surf_session]);
    
    return (
        <><AuthenticatedResponsiveAppBar routes={routes} transparent light />
               {/* Upload Images */ }
               <DropzoneDialog
                    filesLimit={100}
                    acceptedFiles={['image/*']}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    maxFileSize={55000000}
                    open={open}
                    dropzoneClass={VisuallyHiddenInput}
                    onClose={() => {
                      
                      setOpen(false)
                    }}
                    onSave={(files) => {
                    console.log('Files:', files);
                    setIsDataLoading(true);
                    setOpen(false);
                    handleUpload(files);
                    }}
                    showAlerts={['error', 'info']}
                    onDropRejected={(files) => {
                      alert('File rejected: ' + files[0].name);
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    useChipsForPreview
                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                    previewChipProps={{ classes: { root: classes.previewChip } }}
                    previewText="Selected files"
                />
              
              {/* Alert Before Delete */ }

               <Dialog
               open={alert_before_delete}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleClose}
               aria-describedby="alert-dialog-slide-description"
             >
               <DialogTitle>{"Do you want to delete all your session data?"}</DialogTitle>
               <DialogContent>
                 <DialogContentText id="alert-dialog-slide-description">
                 All session data, including progress and linked pictures, will be permanently deleted. 
                 This action cannot be undone. Proceed at your own risk.
                 </DialogContentText>
               </DialogContent>
               <DialogActions>
                 <Button onClick={handleClose}>Go back</Button>
                 <Button onClick={handleConfermDelete}>Agree</Button>
               </DialogActions>
             </Dialog> 

             {/* Upload Success */}
             {show_upload_success ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="success" onClose={handleClose} component={Link} to="/pages/media-gallery" state={{ data: surf_session }}>
                            Great! Click to access your files!
                      </Alert>
                      ) : (<></>)}
              {show_upload_error ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="error" onClose={handleClose}>
                            An error occurred uploading your files. Please try again!
                      </Alert>
                      ) : (<></>)}
           
              {isDataLoading ? <Loader />: 
        <><Grid container alignItems="left" marginTop={5} spacing={1}>
            <Grid item md={4}>
              <Typography variant="h2" color="text.secondary" align="center">
                Your Session Details

              </Typography>

            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <MKButton component="label" variant="outlined" color="success" startIcon={<FileUploadIcon />} onClick={() => setOpen(true)}>
                Upload files
                {/* <VisuallyHiddenInput type="file" /> */}
              </MKButton>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <MKButton variant="outlined" color="black" component={Link} to="/pages/media-gallery" state={{ data: surf_session }} startIcon={<CollectionsIcon></CollectionsIcon>}>
                Your Session Pics
              </MKButton>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <MKButton variant="outlined" color="info" component={Link} to="/page/surf-session-edit" state={{ data: surf_session }} startIcon={<EditIcon></EditIcon>}>
                Edit This Session
              </MKButton>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <MKButton component="label" variant="outlined" color="error" onClick={handleDelete} startIcon={<DeleteForeverIcon />}>
                Delete This Session
              </MKButton>
            </Grid>
          </Grid><Grid container marginLeft={1} spacing={1} alignItems="center" marginTop={10} padding={5}>
              <Grid item xs={6} md={12} lg={12} padding={1}>
                {/* Session Info  Mood, Waves and Session Rates*/}
                <Grid container justifyContent="flex-start" spacing={1}>

                  <Grid item xs={6} md={2}>

                    <Typography variant="body" color="text.primary" size="small">
                      Spot

                    </Typography>
                    <MKTypography variant="body2">
                      <RoomIcon></RoomIcon> {surf_session?.spot_name}
                    </MKTypography>
                    <Typography variant="body2" color="text.secondary">
                      <EventAvailableIcon></EventAvailableIcon> {surf_session?.start_session_datetime}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <EventBusyIcon></EventBusyIcon> {surf_session?.end_session_datetime}
                    </Typography>
                  </Grid>
                  {/* Surfboard and Wesuit */}
                  <Grid item xs={12} md={2}>
                    <Typography variant="body" color="text.primary" size="small">
                      Surfboard
                    </Typography>
                    <Typography variant="body2" color="text.secondary" size="small">
                      {surfboard.brand}   {surfboard.length} {surfboard.volume}L  ({surfboard.nickname} )
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography variant="body" color="text.secondary" size="small">
                      Wetsuit
                      {/* <Icon fontSize="medium"  sx={{ display: { xs: "none", md: "flex" ,height:30, width:32.25 } }}>
        <img src={Wetsuit} alt=""/>
    </Icon> */}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" size="small">
                      {surf_session.wetsuit}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <Typography variant="body" color="text.secondary" size="small" align="center">
                      Mood
                      {/* <Icon fontSize="medium"  sx={{ display: { xs: "none", md: "flex" ,height:30, width:32.25 } }}>
       <img src={Mood} alt=""/>
   </Icon> */}
                    </Typography>

                    <Stack spacing={1}>
                      <Rating
                        name="wave_classification"
                        value={surf_session.my_mood}
                        precision={0.5}
                        size="small"
                        disabled={true}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />

                    </Stack>

                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="body" color="text.secondary" size="small" align="center">
                      Waves
                      {/* <Icon fontSize="medium"  sx={{ display: { xs: "none", md: "flex" ,height:30, width:32.25 } }}>
<img src={Wave} alt=""/>
    </Icon> */}
                    </Typography>

                    <Stack spacing={1} sx={{ width: 1 / 4 }}>
                      <Rating
                        name="wave_classification"
                        value={surf_session.wave_classification}
                        precision={0.5}
                        size="small"
                        disabled={true}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />

                    </Stack>

                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="body" color="text.secondary" size="small" align="center">
                      Session Rating
                      {/* <Icon fontSize="medium"  sx={{ display: { xs: "none", md: "flex" ,height:30, width:32.25 } }}>
<img src={RatingIcon} alt=""/>
</Icon>*/}
                    </Typography>
                    <Stack spacing={1} sx={{ width: 1 / 4 }}>
                      <Rating
                        name="wave_classification"
                        value={surf_session.session_rating}
                        precision={0.5}
                        size="small"
                        disabled={true}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />

                    </Stack>
                  </Grid>
                </Grid>
                {/* Weather and Sea conditions */}
                <Grid container justifyContent="flex-start" spacing={1} marginTop={5}>
                  <Grid item xs={12} lg={2}>
                    <Typography variant="body" color="text.secondary" size="small">
                      Wave Forecast Rating
                    </Typography>
                    <Typography variant="body2" color="text.secondary" size="small">
                      {surf_session.wave_rating}
                    </Typography>

                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Typography variant="body" color="text.secondary" size="small">
                      Wave Heigth
                    </Typography>

                    <Typography variant="body2" color="text.secondary" size="small">
                      {surf_session.wave_height_avg}m
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Typography variant="body" color="text.secondary" size="small">
                      Wave Period
                    </Typography>
                    <Typography variant="body2" color="text.secondary" size="small">
                      {surf_session.wave_period}sec
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Typography variant="body" color="text.secondary" size="small">
                      Weather Condition
                    </Typography>
                    <Typography variant="body2" color="text.secondary" size="small">
                      {surf_session.weather_condition}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Typography variant="body" color="text.secondary" size="small">
                      Weather Temp
                    </Typography>
                    <Typography variant="body2" color="text.secondary" size="small">
                      {surf_session.temperature}C
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Typography variant="body" color="text.secondary" size="small">
                      Water Temp
                    </Typography>
                    <Typography variant="body2" color="text.secondary" size="small">
                      {surf_session.water_temperature}C
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Typography variant="body" color="text.secondary" size="small">
                      Wind Direction
                    </Typography>
                    <Typography variant="body2" color="text.secondary" size="small">
                      {surf_session.wind_direction} at {surf_session.wind_speed} Knt
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" alignContent="center" marginTop={5}>

                </Grid>
                {/*  Notes */}
                <Grid container alignItems="center" alignContent="center" marginTop={5} paddingRight={3} spacing={3}>
                  <Grid item xs={6} md={4}>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                      What I managed to do right...
                    </Typography>
                    <Grid item xs={12} md={12}>
                      <Textarea
                        variant="standard"
                        minRows={10}
                        fullWidth
                        name="positive_annotations"
                        value={surf_session.positive_annotations} />
                    </Grid>

                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                      What I need to improve...
                    </Typography>
                    <Grid item xs={12} md={12}>
                      <Textarea
                        variant="standard"
                        minRows={10}
                        fullWidth
                        name="positive_annotations"
                        value={surf_session.negative_annotations} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                      Session Description...
                    </Typography>
                    <Grid item xs={12} md={12}>
                      <Textarea
                        variant="standard"
                        minRows={10}
                        fullWidth
                        name="positive_annotations"
                        value={surf_session.session_description} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container alignItems="center" alignContent="center" marginTop={5} paddingRight={3} spacing={3}>
                  {/* Training Program */}
                  <Grid item xs={12} md={12} lg={12}>
                    <MKTypography variant="h6">
                      Your Training Program
                    </MKTypography>
                    <MKTypography variant="body" fontSize="medium">
                      Level: {training_program.value}
                    </MKTypography>
                    {error_saving_progress ? (
                      <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="error" onClose={handleClose}>
                        Cannot save your progress. Please, try again.
                      </Alert>
                    ) : (<></>)}
                    {empty_progress_payload ? (
                      <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="warning" onClose={handleClose}>
                        Please add your progress for each of your training program.
                      </Alert>
                    ) : (<></>)}
                    {success_progress_save ? (

                      <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="success" onClose={closeProgressSuccess}>
                        Great job, dude!
                      </Alert>
                    ) : (<></>)}

                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <MKTypography variant="body" fontSize="medium">
                      What maneuvers you are training
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} md={6} align="right">
                    {show_no_training_progress ? (
                      <MKButton variant="gradient" color="info" onClick={handleSubmit}>
                        Save your progresses
                      </MKButton>
                    ) : <></>}
                  </Grid>

                  {show_no_training_progress ? (
                    data.map((task) => (
                      <Grid item xs={12} md={6} lg={6}>
                        <MKTypography variant="body2">{task.name}</MKTypography>
                        <Rating
                          name={task.name}
                          value={formData[task.name]}
                          key={task.id}
                          id={task.id}
                          precision={0.5}
                          onChange={(event, newValue) => {
                            handleChange(event, newValue);
                          } }
                          onChangeActive={(_event, newHover) => {
                            setHover(newHover);
                          } }
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                        <Textarea
                          variant="standard"
                          label="Your Comment"
                          fullWidth
                          minRows={5}
                          id={task.id}
                          name={"comment_" + task.id}
                          value={formData.comment}
                          onChange={(event, newValue) => {
                            handleChange(event, newValue);
                          } }
                          placeholder={"Write your comment on how you perfomed on the " + task.name} />
                      </Grid>
                    ))
                  ) :
                    training_progress?.map((progress, index) => (
                      <Grid item xs={12} md={6} lg={6}>
                        <MKTypography variant="body2">{progress.TrainingProgress.trick}</MKTypography>
                        <Rating
                          name={progress.TrainingProgress.trick}
                          value={progress.TrainingProgress.progress}
                          id={index}
                          disabled={true}
                          precision={0.5}
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                        <Textarea
                          variant="standard"
                          fullWidth
                          minRows={5}
                          disabled={true}
                          id={index}
                          value={progress.TrainingProgress.comment} />
                      </Grid>
                    ))}

                </Grid>
              </Grid>
            </Grid></>
} 
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '30vh',
        }}
      > 
      <Footer/>
    </Box>
        </>
        );
}