import axios from "axios";
import AuthService from "./auth.service";


const { REACT_APP_BACKEND_URL } = process.env;
const BASE_API_URL = REACT_APP_BACKEND_URL;
const API_URL = BASE_API_URL;

const currentUser = AuthService.getCurrentUser();
// console.log("[SURF SESSION]CURRENT USER:", currentUser);
if (currentUser != null) {
  // console.log("[SURF SESSION]CURRENT USER NOT NULL ", currentUser);
  var access_token = currentUser.access_token;
} 

// console.log("[SURF SESSION]ACCESS TOKEN:", access_token);

const storeSurfSession = (payload) => {
  return axios.post(API_URL + "surf_session", payload, {
    headers: { Authorization: "Bearer " + access_token },
  });
};

const updateSurfSession = (payload,surf_session_uuid) => {
  return axios.patch(API_URL + "surf_session/"+surf_session_uuid, payload, {
    headers: { Authorization: "Bearer " + access_token },
  });
};

const deleteSurfSession = (surf_session_uuid) => {
  return axios.delete(API_URL + "surf_session/"+surf_session_uuid, {
    headers: { Authorization: "Bearer " + access_token },
  });
};

const get_session_classification_values = () => {
  console.log("VALUES_:",API_URL + "surf_session/session_values");
  return axios.get(API_URL + "surf_session/session_values");
};

const get_sessions_per_month = (month,year) => {
  console.log("Calling", API_URL + "surf_session?month="+month+"&year="+year);
  return axios.get(API_URL + "surf_session?month="+month+"&year="+year, 
    {headers: { Authorization: "Bearer " + access_token }},
  );
};


const get_session = (surf_session_uuid) => {
  return axios.get(API_URL + "surf_session?surf_session_uuid="+surf_session_uuid, 
    {headers: { Authorization: "Bearer " + access_token }},
  );
};

const SurfSessionService = {
  storeSurfSession,
  updateSurfSession,
  deleteSurfSession,
  get_session_classification_values,
  get_sessions_per_month,
  get_session
};

export default SurfSessionService;
