// import * as React from "react";
// import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from '@mui/material/Typography';

// export default function Loader() {
//   return (
//     <Box sx={{ width: "100%" }}>
//       <LinearProgress color="info" />
//     </Box>
//   );
// }


// import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';

// export default function CircularIndeterminate() {
//   return (
//     <Box sx={{ display: 'flex' }} fontSize="large">
//       <CircularProgress/>
//     </Box>
//   );
// }

import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export default function SkeletonColor() {
  return (
    <Box
      sx={{
        bgcolor: 'white',
        p: 8,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {/* <Skeleton
        sx={{ bgcolor: 'grey.900' }}
        variant="rectangular"
        width={210}
        height={118}
      /> */}
    {/* <CircularProgress color="info"/> */}
    <Box sx={{ width: "100%" }}>
       <LinearProgress color="info" /><Typography position='absolute'>Collecting Forecast Data...</Typography>
     </Box>
    </Box>
  );
}