import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";

 // @mui material components
import MKButton from "components/MKButton";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Alert from '@mui/material/Alert';

// Material Kit 2 React examples
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';

// Author page sections
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";

// Routes
import routes from "routes";
import MKTypography from "components/MKTypography";

// Images
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// RestAPI service 
import UserTrainingProgrammService from "services/training_program.service";
import EventBus from "common/EventBus";
import { object } from "prop-types";


const maneuvers = {
  beginner :  [
    {
      id: 1,
      name: 'paddle technique',
      slug: 'paddle technique',
      type: 'beginner',
      locale: 'en',
    },
    {
        id: 2,
        name: 'Take-off',
        slug: 'take-off',
        type: 'beginner',
        locale: 'en',
      },
    {
      id: 3,
      name: 'Bottom Turn',
      slug: 'bottom-turn',
      type: 'beginner',
      locale: 'en',
    },
    {
      id: 4,
      name: 'Carve',
      slug: 'carve',
      type: 'begineer',
      locale: 'en',
    },

    
    {
      id: 5,
      name: 'Cutback',
      slug: 'cutback',
      type: 'beginner',
      locale: 'en',
    },],
    intermediate :[
      {
        id: 6,
        name: 'Snap',
        slug: 'snap',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 7,
        name: 'Roundhouse Cutback',
        slug: 'Roundhouse Cutback',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 8,
        name: 'Off-the-Lip',
        slug: 'Off-the-Lip',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 9,
        name: 'Foam Climb',
        slug: 'Foam Climb',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 10,
        name: 'Floater',
        slug: 'Floater',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 11,
        name: 'Closeout Re-entry',
        slug: 'Closeout Re-entry',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 12,
        name: 'Tail Slide',
        slug: 'Tail Slide',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 13,
        name: 'Noseriding',
        slug: 'Noseriding',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 14,
        name: 'Hang Five',
        slug: 'Hang Five',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 15,
        name: 'Hang Ten',
        slug: 'Hang Ten',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 16,
        name: 'Switch Stance',
        slug: 'Switch Stance',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 17,
        name: '360',
        slug: '360',
        type: 'intermediate',
        locale: 'en',
      },
      {
        id: 18,
        name: 'Kickflip',
        slug: 'Kickflip',
        type: 'intermediate',
        locale: 'en',
      },],
      advanced: [
        {
          id: 19,
          name: 'Tube Ride',
          slug: 'Tube Ride',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 20,
          name: 'Aerial',
          slug: 'Aerial',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 21,
          name: 'Alley-Oop',
          slug: 'Alley-Oop',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 22,
          name: 'Superman',
          slug: 'Superman',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 23,
          name: 'Rodeo Flip',
          slug: 'Rodeo Flip',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 24,
          name: 'Kerrupt Flip',
          slug: 'Kerrupt Flip',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 25,
          name: 'Flynnstone Flip',
          slug: 'Flynnstone Flip',
          type: 'advanced',
          locale: 'en',
        },
        {
          id: 26,
          name: 'Sushi Flip',
          slug: 'Sushi Flip',
          type: 'advanced',
          locale: 'en',
        }
      
      ]
}


const surfer_levels = {
  beginner: "a BEGINNER is a surfer who is yet to successfully paddle out alone and catch and ride a wave cleanly to its logical finish.",
  intermediate: "an INTERMEDIATE rider is a surfer who can successfully paddle out alone at a familiar location and catch and ride waves to a logical finish frontside or backside, confidently using the three basic surfing turns – bottom turn, top turn and cutback.",
  advanced:"an ADVANCED rider is a surfer who can paddle out alone to a surf spot he/she has never ridden, assess the lineup, and catch and ride waves to his/her choice of finish, confidently using a full range of turns in a distinctive, effective style."
}


const UserTraining = () => {
    const navigate = useNavigate();
    const [begineer_data, setBeginnerData] = useState([{}]);
    const [intermediate_data, setIntermidiateData] = useState([{}]);
    const [advanced_data, setAdvancedData] = useState([{}]);
    const [training_goals, setTrainingGoals] = useState([]);
    const [selected_Level, setLevel] = useState();
    const [show_save_button, showSaveButton] = useState(true);
    const [show_alert, showAlert] = useState(false);
    const [formData, setFormData] = useState({
      level: "",
      training_goals: training_goals,
      
    });
    const handleClose = () => {
      showAlert(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        var payload = {
          level: formData.level,
          training_goals: training_goals
        }
        
        let check_level = ["beginner","intermediate", "advanced"].includes(formData.level);
        let check_goals_len = payload["training_goals"].length;
        console.log("PAYLOAD ", payload, payload["level"]);
        console.log("level:",check_level);
        console.log("list :", check_goals_len);
        if (check_level >0 && check_goals_len > 0) {
          UserTrainingProgrammService.addTrainingProgram(payload).then(
            (response) => {
              const data = response.data;
              console.log("------RESPONSE TRAINING PROGRAM---:", data);
              showSaveButton(false);
              navigate("/pages/active-training-goals");

            },
            (error) => {
              console.log("TRAINING  ERROR", error);
              let status = error.request.status
              let message = JSON.parse(error.request.response);
              console.log("STATUS AND MESSAGE", status, message);
             
              const _error_content =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              console.log("ERROR:", _error_content);
              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                navigate("/pages/authentication/sign-in");
              }
            }
          );
        } 
        else {
          showAlert(true);
        }
        
      };
 
   
    const handleChange = (event) => {
      const { name, value } = event.target;
      console.log("NAME:", name);
      console.log("SET:", (name, value));
      if (name === "level") {
         // clean the list of goals that might be added before 
         if (typeof(formData.training_goals === object)) {
          setTrainingGoals([]);
         }
         
      }
      if (name === 'training_goals') {
        setTrainingGoals(training_goals.concat(value));
      }
      else {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      }
    };

    const data_source = {
        "beginner" : begineer_data,
        "intermediate": intermediate_data,
        "advanced": advanced_data
    }
    useEffect(() => {
        setBeginnerData(maneuvers["beginner"]);
        setIntermidiateData(maneuvers["intermediate"]);
        setAdvancedData(maneuvers["advanced"]);
        UserTrainingProgrammService.getUserTrainingProgram().then(
          (response) => {
            console.log("MY TRAINING PROGRAM :", response);
            if (response.length > 0 ) {
              setLevel(response[0].value);
              showSaveButton(false);
              navigate("/pages/active-training-goals");
            //   formData.level = response[0].value
            //   let programs = response[0].training_goals
            //   programs.map((program) => {
            //       maneuvers[response[0].value].map((item) => {
            //         if (item.name === program) {
            //           console.log("Selected program:",item.name)
            //           item["selected"] = true
            //         }
            //     });
            // });
          }
          },
          (error) => {
            const _content_error =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log(_content_error);
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
              navigate("/pages/authentication/sign-in");
            }
          }
        );
    }, [formData, navigate]);

  return (
    <>
      <ResponsiveAppBar routes={routes} transparent light />
      <Grid item xs={12} lg={12} >
                      {show_alert ? (
                        <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled" severity="warning" onClose={handleClose}>
                            Please Select you level and your training goals
                      </Alert>
                      ) : (<></>)}

      </Grid>
      <Grid container sx={{bgcolor:"#fff"}}>
          
          <Grid item xs={12} lg={12} sx={{ ml: "auto", mt: { xs: 3, lg: 5 } }} textAlign="center">
                  <MKTypography variant="h4" mb={6}>
                    Set your Training Goals
                  </MKTypography>
          </Grid>
          <Grid item  xs={12} md={6} sx={{bgcolor:"#fff"}}>
   
        <FormControl sx={{ml:"100px" , mt:"20px"}} textAlign="center">
            <FormLabel id="demo-row-radio-buttons-group-label">What's your Level ( Be humble )</FormLabel>
            
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="level"
                value={formData.level}
                onChange={handleChange}
                
            >
                <FormControlLabel value="beginner" control={<Radio />} label="Beginner" />
                <FormControlLabel value="intermediate" control={<Radio />} label="Intermediate" />
                <FormControlLabel value="advanced" control={<Radio />} label="Advanced" />
                
            </RadioGroup>
            </FormControl>
            <Grid item md={6} sx={{bgcolor:"#fff", ml: "80px", mt: { xs: 3, lg: 5 }}} textAlign="justify">
                <MKTypography variant="body" mb={12} >
                      {surfer_levels[formData.level]}
                  </MKTypography>
            </Grid>
            <Grid item md={6} sx={{bgcolor:"#fff", ml: "80px", mt: { xs: 3, lg: 5 }}} textAlign="justify">
                <MKTypography variant="body" mb={12} >
                      Info: you can only have one active Training progam. 
                      
                  </MKTypography>
            </Grid>


            <Grid item xs={12} lg={12} alignItems="center" alignContent="center" sx={{ ml: "600px", mt: { xs: 3, lg: 5 } , mb: { xs: 3, lg: 5 } }}>
              {show_save_button ? (
                  <MKButton color="info" onClick={handleSubmit} size="large" variant="contained" textAlign="center">
                      Save your Training Program
                  </MKButton>
              ) : (<></>) }
              
          </Grid>
          </Grid>
          
          <Grid item  xs={12} md={6} sx={{bgcolor:"#fff",  height:"700px"}} alignContent="center" alignItems="center">

            <FormControl sx={{ ml: 20, mt:3, bgcolor:"#fff" }} component="fieldset" variant="standard" >

                <FormLabel component="legend">What are you working on ?</FormLabel>

                <FormGroup sx={{mt:5}}>
                    {data_source[formData.level]?.map((task) => (
                        task.selected ? (  
                            <FormControlLabel  control={<Checkbox  defaultChecked/>} key={task.id} label={task.name} name="training_goals" value={task.name} onChange={handleChange}
                            />
                        ): (
                            <FormControlLabel control={<Checkbox  />} label={task.name} key={task.id} name="training_goals" value={task.name} onChange={handleChange}
                            />

                        )
                    ))}
                </FormGroup>
            </FormControl>
          </Grid>
          
      </Grid>
      <MKBox
          sx={{
            display: 'flex',
            bgcolor:"#fff",
            flexDirection: 'column'
          }}
      > 
        <Footer/>
      </MKBox>
    </>
  );
};

export default UserTraining;
