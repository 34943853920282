import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import EventBus from "common/EventBus";
import  Icon from "@mui/material/Icon"
import surf_icon from "assets/images/icons/surfing.png"
import shaka from "assets/images/icons/shaka.png"
import ListItemIcon from '@mui/material/ListItemIcon';
import SurfingIcon from '@mui/icons-material/Surfing';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { makeStyles } from '@mui/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const pages = ["Log New Session", "Your Sessions", "Your Training Program"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];
//   background: 'linear-gradient(to right, #e96443, #904e95)',
//   background: 'linear-gradient(195deg, #42424a, #191919)'

/* this applies the style to the menu bar */
const useStyles = makeStyles((theme) => ({
  header: {
  background: 'linear-gradient(to right, #B9DDDA, #E2E5FA)',
 },
}));
function AuthenticatedResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const classes = useStyles();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const navigate = useNavigate();

  const handleCloseNavMenu = (e) => {
    console.log("MENU CLICKED:", e.target.value);
    if (e.target.value === "Log New Session") {
      console.log("navigate to select-spot");
      navigate("/pages/select-spot");
    }
    if (e.target.value === "Your Sessions") {
      navigate("/pages/surf-session/list");
    }
    if (e.target.value === "Your Training Program") {
      navigate("/pages/active-training-goals");
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event) => {
    console.log("OPEN SETTNIGS", event.currentTarget.dataset);
    const { myValue } = event.currentTarget.dataset;
    console.log(myValue);
    if (myValue === "Logout") {
      navigate("/pages/logout");
      
    }
    if (myValue === "Profile") {
      navigate("/pages/user-profile");

    }
    if (myValue === "User") {
      navigate("/pages/user-board");

    }
    setAnchorElUser(null);
  };
 

 
  return (
    <AppBar position="static" className={classes.header}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Icon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <img src={surf_icon} alt=""/>
          </Icon>
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#fff",
              textDecoration: "none",
            }}
          >
            MySurfWorkBook 
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#fff"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >{page}</Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/presentation"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#00adb5",
              textDecoration: "none",
            }}
          >
            MySurfWorkBook
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                value={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#fff", display: "block", '&:hover': {
                  backgroundColor: '#fff',
                  color: '#3c52b2',
              } }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Your Account">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt=""><SurfingIcon fontSize="large"></SurfingIcon></Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
                <MenuItem key={setting} data-my-value={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
              <MenuItem onClick={handleCloseUserMenu} data-my-value="Profile">
          <Avatar src={shaka}/> Profile
        </MenuItem>
        <MenuItem onClick={handleCloseUserMenu} data-my-value="User">
          <Avatar fontSize="small"/> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCloseUserMenu}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleCloseUserMenu} data-my-value="Logout">
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AuthenticatedResponsiveAppBar;
