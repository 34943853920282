import React, { useState, useEffect} from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import MKTypography from "components/MKTypography";


// Author page sections
import Profile from "pages/PrivatePages/UserBoard/sections/Profile";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
import Surfboards from "pages/PrivatePages/UserBoard/sections/SurfBoards";
import CompleteProfile from "pages/PrivatePages/UserBoard/sections/CompleteProfile";
import CreateTraining from "pages/PrivatePages/UserBoard/sections/CreateTraining";
import LogSession from "pages/PrivatePages/UserBoard/sections/LogSession";
import SessionsList from "pages/PrivatePages/UserBoard/sections/SessionsList";
// Routes
import routes from "routes";
// Local functions
import UserProfileService from "services/profile.service";
import UserService from "services/user.service";
// Images
import bgImage from "assets/images/surfingwallpaper56.jpeg";

const BoardUser = () => {
  const [current_user, setCurrentUser] = useState();
  const [user_profile_data, setUserProfileData] = useState({});
  const [error, setError]=useState(false);


  useEffect(() => {

    if (current_user === undefined) {
      UserService.getUserBoard().then((response) => {
        console.log("[USER BOARD]RESPONSE FROM AUTH:", response);
        if (response === "ERR_NETWORK") {
          setError("It seems you are not connected to a network. Please try again.")
        }
        setCurrentUser(response);
        console.log("CURRENT USER:",current_user);

      });
      UserProfileService.getUserProfile().then((response) => {
        console.log("-------[USER BOARD]FETCH USER PROFILE:",response)
          /* update the form with the fetched data */
          setUserProfileData(values => ({...response}));
         
        
      });
    }

      
    
  }, [current_user]);

  return (
    <>
      <ResponsiveAppBar routes={routes} transparent light />
      
      <MKBox component="section" py={12} >
        <Container >
        
          <Grid container spacing={1} alignItems="center" >  
          <Grid item  xs={12} md={12} mb={5} mt={-5}>
            {/* <MKTypography variant="h2" >
              Profile
            </MKTypography> */}
          </Grid>
            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
                sx={{
                  backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.1),
                      rgba(gradients.dark.state, 0.1)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "grid",
                  placeItems: "center",
                }}
              />
                    <Profile data={{ current_user: {current_user}, user_profile_data: {user_profile_data}}}/>
                </Card>
              </MKBox>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <Surfboards data={{ current_user: {current_user}, user_profile_data: {user_profile_data}}}/>
                </Card>
              </MKBox>
            </Grid>
          
            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <CompleteProfile data={{ current_user: current_user, user_profile_data: user_profile_data}}/>
                </Card>
              </MKBox>
            </Grid>


            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <CreateTraining />
                </Card>
              </MKBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <LogSession />
                </Card>
              </MKBox>
            </Grid>


            <Grid item xs={12} md={4}>
              <MKBox>
                <Card
            sx={{
              p: 2,
              mt: 0,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
              <MKBox
                minHeight="10rem"
                width="100%"
                borderRadius='16px'
                
              />
              <SessionsList />
                </Card>
              </MKBox>
            </Grid>

          </Grid>
          
        </Container>
      </MKBox>
      <Footer />
      </>
  );
};

export default BoardUser;
