/* eslint-disable no-unused-vars */
import axios from "axios";
import AuthService from "services/auth.service";
import handle_api_error from "./helpers/rest_api_errors_handler";
// import { config } from "dotenv";
const { REACT_APP_BACKEND_URL } = process.env;
const BASE_API_URL = REACT_APP_BACKEND_URL;
const API_URL = BASE_API_URL+"surfboard";

const currentUser = AuthService.getCurrentUser();


if (currentUser != null) {
  var access_token = currentUser.access_token;
} else {
  access_token = undefined;
}

const get_surfboard = (uuid) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
  };

  return axios.get(API_URL + "?surfboard_uuid="+uuid ,config).then(
    (res) => {
      console.log("RESPONSE SURFBOARD:",res);
      return res.data;
    },
    (error) => {
      let http_status = error.response.status;
      console.log("[SURFBOARD SPOT] error:", error,http_status);
      return error;
    }
  );
}

const getSurfboards = (uuid, compact) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
  };
  
  console.log("CALLING:", API_URL + "?compact=True")
  return axios.get(API_URL + "?compact=True",  config).then(
    (res) => {
      console.log("RESPONSE SURFBOARD:",res);
      return res.data;
    },
    (error) => {
      // return handle_api_error(error, API_URL, 'SURFBOARD' )
      let http_status = error.response.status;
      console.log("[SURFBOARD SPOT] error:", error,http_status);
      return error;
    }
  );
};

const deleteSurfboard = (uuid) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
  };
  return axios.delete(API_URL+"?surfboard_uuid="+uuid, config).then(
    (res) => {
      console.log("DELETED SURFBOARD:",res);
      return res.data;
    },
    (error) => {
      let http_status = error.response.status;
      console.log("[SURFBOARD SPOT] error:", error,http_status);
      return error;
    }
  );


}
const addSurfboard = (payload) => {
   console.log("SEND TO",API_URL, payload)
  return axios.post(API_URL , payload, {
    headers: { Authorization: "Bearer " + access_token },
  });
};

const patchSurfboard = (uuid,payload) => {
  console.log("SEND TO",API_URL, payload)
 return axios.patch(API_URL+"?surfboard_uuid="+uuid , payload, {
   headers: { Authorization: "Bearer " + access_token },
 });
};


const UserSurfboardService = {
  addSurfboard,
  getSurfboards,
  get_surfboard,
  patchSurfboard,
  deleteSurfboard

  
};

export default UserSurfboardService;
