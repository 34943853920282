/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

import SearchIcon from '@mui/icons-material/Search';

import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
// Routes
import routes from "routes";
// Custom Services
import UserService from "services/user.service";

import SurfForecastService from "services/surf_forecast.service";
import UserSurfSpotService from "services/surf_spot.service";

import PageLoader from "../../../../../services/helpers/loader";

import Slide from '@mui/material/Slide';

import "./style.css"
import { makeStyles } from "@material-ui/core/styles";





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    width: 700,
    height: 600,
    backgroundColor: '#6a5acd'
  },
  table: {},
  row: {
    width: 700,
    backgroundColor: 'grey'

  },
  cell_long: {
    fontSize: "10px",
    width: 600,
    minWidth: 1,
    backgroundColor: '#ee82ee'

  },
  cell_short: {
      fontSize: "10px",
      width: 100,
      backgroundColor: 'green'

    },

}));


function SpotLocation() {
      const [spot_name, setSpotName] = useState("");
      const [isLoading, setLoading] = useState(false);
      const [spot_items, setResponse] = useState();
      const [show_spot_items, showSpotItems] = useState(false);
      const [show_current_spot, showCurrentSpot] = useState(false);
      const [spot_not_found, setNotFound] = useState();
      const [start_datetime, setStartSurf] = useState();
      const [stop_datetime, setStopSurf] = useState();
      
      const [current_spot, setCurrentSpot] = useState();
      const [favorite_stored, setFavoriteStored] = useState(false);
      const [fav_surf_spots, setFavSurfSpots] = useState();
      const [open, setOpen] = useState(true);
      const [show_button, setShowButton] = useState(false);
      const [selected_fav, selectFav] = useState('');
      const [getForecast, setGetForecastOnline] = useState(false);
      const [alread_saved, setAlreadySaved] = useState(false);

      const [spot_coord, setSpotCoord] = useState({});

      
      const [formData, setCoordToLookup] = useState({});

      const navigate = useNavigate();

      const handleCoordChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setCoordToLookup((prevFormData) => ({ ...prevFormData, [name]: value }));

      }
      

      const handleClose = () => {
        setFavoriteStored(false);
        setOpen(false);
      
      };
      
      
      
      const reset_view_value = () => {
        setNotFound(undefined);
        showSpotItems(false);
        showCurrentSpot(false);
      };

      const save_spot = (spot_data) => {
        console.log("SAVE SPOT:",spot_data);
        let payload = {
          "spot_id": spot_data.spot_id,
          "lat": spot_data.lat,
          "lon":spot_data.lat,
          "spot_name": spot_data.name,
          "region": spot_data.breadCrumbs[1],
          "county": spot_data.breadCrumbs[2],
          "state": spot_data.breadCrumbs[0],
          "city": spot_data.name,
          "custom": false,
          "secret": false,
          "favorite": true

        }
        console.log("PAYLOAD:",payload)
        UserSurfSpotService.storeSurfSpot(payload).then(
          (response) => {
            const data = response.data;
            console.log("------RESPONSE SURF SPOT---:", data);
            setLoading(false);
            setOpen(true);
            setFavoriteStored(true);
            getFavouriteSpots();
           
          },
          (error) => {
            console.log("SURF SPOT ERROR", error);
            let status = error.request.status
            let message = JSON.parse(error.request.response)
            console.log("STATUS AND MESSAGE", status, message);
            setAlreadySaved(true);
            setLoading(false);
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log("ERROR:", _error_content);
          }
        );
      }
      const getFavouriteSpots = () => {
        UserSurfSpotService.getFavouriteSpots().then(
          (response) => {
            const my_fav_spots = response;
            console.log("--FAV SPOTS:",my_fav_spots);
            setFavSurfSpots(response);
            return response;
          },
          (error) => {
            console.log("ERROR FAV")
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log("ERROR:", _error_content);

          }
        )
      }
  
      
      const submitValue = () => {
        /*
        This function search the given spot by its name
        */

        reset_view_value();
        setLoading(true);
        SurfForecastService.search_spot_by_lat_and_long(formData.lat_to_search, formData.lon_to_search).then(
          (response) => {
            const data = response.data;
            console.log("SEARCH BY COORD  RESPONSE :", data);
            data["located"] = true;
            setCurrentSpot(data);
            // setResponse(undefined);
            setLoading(false);
            showCurrentSpot(true);
            showSpotItems(false);
          },
          (error) => {
            console.log("NO FOUND");
            setNotFound("SPOT NOT FOUND");

            setLoading(false);
            const _error_content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            console.log("ERROR:", _error_content);
          }
        );
      };


      
      const LocateSpot = () => {
        setLoading(true);
        reset_view_value();
    
        var options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };
        function success(pos) {
          var crd = pos.coords;
          let my_location = undefined;

          console.log("Your current position is:");
          console.log(`Latitude : ${crd.latitude}`);
          console.log(`Longitude: ${crd.longitude}`);
          console.log(`More or less ${crd.accuracy} meters.`);
          setSpotCoord({lat:crd.latitude, lon:crd.longitude});
          
          SurfForecastService.search_spot_by_lat_and_long(crd.latitude, crd.longitude).then(
            (response) => {
              const data = response.data;
              console.log("LOCATING SPOT RESPONSE :", data);
              data["located"] = true;
              setCurrentSpot(data);
              my_location = data;
              setResponse(undefined);
              setLoading(false);
              showCurrentSpot(true);
              showSpotItems(false);
            },
            (error) => {
              console.log("NO FOUND");
              setNotFound("SPOT NOT FOUND");

              setLoading(false);
              const _error_content =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              console.log("ERROR:", _error_content);
            }
          );
        }
    
        function errors(err) {
          console.warn(`ERROR(${err.code}): ${err.message}`);
        }
    
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
            console.log("RESULT ", result);
            if (result.state === "granted") {
              //If granted then you can directly call your function here
              navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "prompt") {
              //If prompt then the user will be asked to give permission
              navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
            }
          });
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      };
     
      useEffect(() => {
        UserService.getUserBoard().then((response) => {
          console.log("[SELECTSPOT]RESPONSE FROM AUTH:", response);
        });
        setShowButton(false);

      }, []);
    

      
  return (
    <><ResponsiveAppBar routes={routes} /><MKBox component="section" py={{ xs: 6, sm: 12 }}>

      <Container>
        <Grid container justifyContent="center" mx="auto">
          {/* Alert Zone */}
          <Grid item xs={12} lg={12}>
            {open && favorite_stored ? (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" onClose={handleClose}>
                Your spot has been added to your list of favourite
              </Alert>
            ) : (<></>)}


            {open && alread_saved ? (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="info" onClose={handleClose}>
                You have already saved this spot in your list of favorites.
              </Alert>
            ) : (<></>)}

          </Grid>

          <Grid item xs={12} lg={12}>

            <MKBox display="flex" justifyContent="center" alignItems="center" mb={1}>
              <MKTypography variant="h3">
                Where did you go surfing today?
              </MKTypography>
            </MKBox>
            <MKBox display="flex" justifyContent="center" alignItems="center" mb={1}>
              <MKTypography variant="body2" color="text">
                Search you spot, locate it using geolocation or insert your (secret) new spot.
              </MKTypography>
            </MKBox>
          </Grid>

          <Grid container sx={{ ml: { xs: -2, lg: "auto" } }}>
            {/* use geolocalization to find the nearest spot */}
            <Grid item xs={12} lg={4}>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon sx={{ mr: 1 }}>search</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKButton variant="gradient" color="info" onClick={LocateSpot}>
                    Locate you Spot
                  </MKButton>
                </MKTypography>
              </MKBox>
            </Grid>
            {/* Search the spot by name */}
            <Grid item xs={12} lg={4}>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKInput
                    variant="standard"
                    label="Insert Spot Lat"
                    name="lat_to_search"
                    fullWidth
                    onChange={(e) => handleCoordChange(e)} />
                </MKTypography>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKInput
                    variant="standard"
                    name="lon_to_search"
                    label="Insert Spot Lon"
                    fullWidth
                    onChange={(e) => handleCoordChange(e)} />
                </MKTypography>
                <MKButton size="medium" variant="gradient" color="info" onClick={submitValue} startIcon={<SearchIcon></SearchIcon>}>
                </MKButton>



              </MKBox>
            </Grid>

          </Grid>

          {show_current_spot ? (
            <Grid container justifyContent="flex-start" spacing={1}>
              <Grid item xs={12} lg={3}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://placehold.co/200x200"
                      sx={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "0",
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        borderBottomLeftRadius: "0",
                        borderBottomRightRadius: "0"
                      }}
                      alt="" />
                    <CardContent>
                      <MKTypography gutterBottom variant="h4" component="div" align="center">
                        {current_spot?.spot_name}
                      </MKTypography>

                      <MKTypography gutterBottom variant="h6" component="div" align="center">
                        {current_spot?.breadcrumb[3]}
                      </MKTypography>

                      <MKTypography variant="h5" color="text.secondary" align="center">
                        {current_spot?.spot_region} {current_spot?.breadcrumb[0]}
                      </MKTypography>
                      <MKTypography gutterBottom variant="body2" component="div" align="center">
                        {current_spot?.wave_rating}
                      </MKTypography>

                      <MKTypography variant="body2" color="text.secondary" align="center">
                        Wave Size:{current_spot?.wave_avg_size} Period:{current_spot?.wave_period}
                      </MKTypography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    {/* <MKButton size="small" color="error" variant="outlined" onClick={() => handleDelete(board)} state={{ data: board }}  startIcon={<DeleteForeverIcon></DeleteForeverIcon>}>
                Delete
            </MKButton>
            <MKButton size="small" color="info" variant="outlined" onClick={() => handleOpenModal(board)}  startIcon={<VisibilityIcon></VisibilityIcon>}>
                Details
            </MKButton>
           */}

                  </CardActions>


                </Card>
              </Grid>
            </Grid>


          ) : (
            <></>
          )}
          <Grid container justifyContent="center" mx="auto" mt={10}>
            {isLoading ? (<PageLoader.Loader />) : (<></>)}
            {/* results */}
            <Grid item xs={12} lg={6}>

              {spot_not_found ? (
                <MKTypography variant="h6">No spots were found! Try again.</MKTypography>
              ) : (
                <></>
              )}

              <MKTypography
                component="a"
                href="#"
                variant="body2"
                color="info"
                fontWeight="regular"
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: "translateX(3px)",
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: "translateX(6px)",
                  },
                }}
              ></MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox></>
  );
}

export default SpotLocation;
