import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthenticatedResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import { Button, CardActionArea, CardActions } from '@mui/material';
import dayjs from "dayjs";
// Routes
import routes from "routes";
// Services 
import EventBus from "common/EventBus";
import SurfSessionService from 'services/surf_session.service';
//images 
import session_image from "assets/images/waves/perfect_wave.jpeg";

export default function MultiActionAreaCard() {
    const navigate = useNavigate();
    const [surf_sessions, setSurfSessions] = useState([]);
    const [show_no_entries, showAlertNoEntries] = useState(false);
    useEffect(() => {
        let current_month = dayjs().month() + 1;
        let current_year = dayjs().year();
        console.log("current month/year", current_month, current_year);
        SurfSessionService.get_sessions_per_month(current_month, current_year).then(
            (response) => {
                console.log("RESPONSE:",response);
                if (response?.data.length > 0) {
                    setSurfSessions(response.data);
                    console.log("STORED SESSIONS:",response.data);
                } 
                else {
                    console.log("NO ENTRIES");
                    showAlertNoEntries(true);
                }

            },
            (error) => {
                const _content_error =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                console.log(error.response);
                if (error.response && error.response.status === 401) {
                  EventBus.dispatch("logout");
                  navigate("/pages/authentication/sign-in");
                }
              }
        )
    } , []);   

    return (
    <><AuthenticatedResponsiveAppBar routes={routes} transparent light />
    <Grid container marginTop={5} spacing={5} xs={12} lg={12} sx={{ mx: "auto"}}>
          {surf_sessions.map((surf_session) => (
                <Grid item xs={12} lg={3}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea >
                        <CardMedia
                            component="img"
                            height="140"
                            image={session_image}
                            sx={{marginLeft:"auto", 
                             marginRight:"auto",
                             marginTop:"0", 
                             justifyContent: "center", 
                             display: "flex", 
                             width:"100%",
                             borderBottomLeftRadius:"0",
                             borderBottomRightRadius:"0"
                            }}
                            alt="" />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" align="center">
                                {surf_session["SurfSession"].spot_name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center">
                            {surf_session["SurfSession"].start_session_datetime} 
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="justify" >
                            {surf_session["SurfSession"].session_description}
                            </Typography>
                            <Grid container marginTop={2} borderTop={1} alignContent="center" textAlign="center">
                            <Grid item lg={4} alignContent="center" marginTop={1}>
                            <Typography variant="body" color="text.secondary" size="small" align="center">
                                My Mood 
                            </Typography>
          
                            <Stack spacing={1}> 
                                        <Rating
                                            name="wave_classification"
                                            value={surf_session["SurfSession"].my_mood}
                                            precision={0.5}
                                            size="small"
                                            disabled={true}
                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                            
                            </Stack>
                            </Grid>
                            
                            <Grid item lg={4} alignContent="center" marginTop={1}>
                            <Typography variant="body" color="text.secondary" size="small" align="center">
                                Waves  
                            </Typography>
          
                            <Stack spacing={1} sx={{ width: 1/4 }}> 
                                        <Rating
                                            name="wave_classification"
                                            value={surf_session["SurfSession"].wave_classification}
                                            precision={0.5}
                                            size="small"
                                            disabled={true}
                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                            
                            </Stack>

                            </Grid>

                            
                            <Grid item lg={4} alignContent="center" marginTop={1} >
                            <Typography variant="body" color="text.secondary" size="small" align="center">
                                Session   
                            </Typography>
          
                            <Stack spacing={1} sx={{ width: 1/4 }}> 
                                        <Rating
                                            name="wave_classification"
                                            value={surf_session["SurfSession"].session_rating}
                                            precision={0.5}
                                            size="small"
                                            disabled={true}
                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                            
                            </Stack>
                            </Grid>
                            </Grid>
                            

                            
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary" component={Link} to="/pages/surf-session" >
                            Details
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
          ))}
          
          



      </Grid></>



    
  );
}