/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect} from "react";
import { RotatingLines } from "react-loader-spinner";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from "@mui/material/FormControl";
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Modal from '@mui/material/Modal';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import SurfingIcon from '@mui/icons-material/Surfing';
import WavesIcon from '@mui/icons-material/Waves';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import StraightenIcon from '@mui/icons-material/Straighten';
import WidthFullIcon from '@mui/icons-material/WidthFull';
import Crop54Icon from '@mui/icons-material/Crop54';
import BuildIcon from '@mui/icons-material/Build';
import SellIcon from '@mui/icons-material/Sell';
import StarsIcon from '@mui/icons-material/Stars';
import Button from '@mui/material/Button';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKInput from "components/MKInput";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MKTypography from "components/MKTypography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import FormHelperText from '@mui/material/FormHelperText';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
// Routes
import routes from "routes";
// API Services
import UserService from "services/user.service";
import UserSurfboardService from "services/surfboard.service";
import { TroubleshootSharp } from "@mui/icons-material";


function Loader() {
  return (
    <Grid style={{display: 'flex', justifyContent: 'center'}}margin="20%">
     <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}

/> 
    </Grid>
  )
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const items = {
    "surfboard_type": [
        {"id": 1, "value": "Shortboard"},
        {"id": 2, "value": "Longboard"},
        {"id": 3, "value": "Mid Length"},
        {"id": 4, "value": "Fish"},
        {"id": 5, "value": "Soft Top"},
        {"id": 6, "value": "Fun Board"},
        {"id": 7, "value": "Mini Simmon"},
        {"id": 8, "value": "Groveller"},
        {"id": 9, "value": "Alaia"},
        {"id": 10, "value": "Bonzer"},
        {"id": 11, "value": "Gun"},
        {"id": 12, "value": "SUP"},
        {"id": 13, "value": "Tow Boards"},
        {"id": 14, "value": "Step Up's"},
        {"id": 15, "value": "Foil"},

    ],
    "fins" : [
        {"id":1 , "value": "single-fin"},
        {"id":2 , "value": "twin-fin"},
        {"id":3 , "value": "thruster"},
        {"id":4 , "value": "quad"},
        {"id":5 , "value": "bonza"},
    ]
  }

function Surfboards() {
  const [current_user, setCurrentUser] = useState();
  const [message, setMessage] = useState(false);
  const [my_surfboards, setMySurfBoards] = useState();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [alter_level, setAlertLevel] = useState('info')
  const [alert_before_delete, showAlertBeforeDelete] = useState(false);
  const [surfboard_data, setSurfboardData] = useState({});
  
  const [open_modal, setOpenModal] = useState(false);
  
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = (board_data) => {
    console.log("SURFBOARD DATA:",board_data);
    let payload = {
      brand:board_data.brand,
      favorite:board_data.favorite,
      fins:board_data.fins,
      id:board_data.id,
      length:board_data.length,
      material:board_data.material,
      nickname:board_data.nickname,
      surfboard_type:board_data.surfboard_type,
      uuid:board_data.uuid,
      volume:board_data.volume,
      width:board_data.width
    }
    setSurfboardData(payload); 
    setFormData(payload)
    setOpenModal(true);
  }
  const handleDelete = (board_data) => {
    console.log("board data to delete:",board_data);
    setSurfboardData(board_data)
    showAlertBeforeDelete(true);
  }
  const handleClose = () => {
    showAlertBeforeDelete(false);
    setMessage(false);
  };
  const handleConfirmDelete = () => {
      console.log(surfboard_data);
      setIsDataLoading(TroubleshootSharp);
      showAlertBeforeDelete(false);

      UserSurfboardService.deleteSurfboard(surfboard_data.uuid).then(
        (response) => {
          if (response === "ERR_NETWORK") {
            setAlertLevel('error')
            setMessage("You are not connected to a network. Please check your settings");
          }
          setIsDataLoading(false);
          UserSurfboardService.getSurfboards().then(
            (response) => {
              const data = response;
              console.log("RESPONSE FROM SERVICE:", data);
              setAlertLevel('success')
              setMessage("You Surfboard has been deleted!");
              setMySurfBoards(data);
              setIsDataLoading(false);
    
            },
            (error) => {
              setIsDataLoading(false);
              setAlertLevel('error')
              setMessage("An error occurred! Please try again");
              const _content =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
                console.log("ERROR:",_content)
    
            }
          );
    

        },
        (error) => {
          setAlertLevel('error')
          setMessage("An error occured! Check it again, please!");
          const _content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("ERROR DELETE:",_content)
          }  
      )
  }


  const handleUpdate = (event) => {
    event.preventDefault();
    setIsDataLoading(true);
    let payload = {
      "surfboard_type": formData.surfboard_type,
       "fins": formData.surfboard_fins || surfboard_data.fins,
        "width": formData.board_width || surfboard_data.width,
        "brand": formData.board_brand || surfboard_data.brand,
        "nickname": formData.board_nickname || surfboard_data.nickname,
        "favorite": formData.favorite || surfboard_data.favorite,
        "length": formData.board_length || surfboard_data.length,
        "volume": formData.board_volume || surfboard_data.volume,
        "material": formData.board_material || surfboard_data.material
    }
    console.log("Data:", payload, surfboard_data.uuid);
    UserSurfboardService.patchSurfboard(surfboard_data.uuid, payload).then(
      (response) => {
        setIsDataLoading(false);
        setOpenModal(false);
        const data = response.data;
        if (response === "ERR_NETWORK") {
          setAlertLevel('error')
          setMessage("You are not connected to a network. Please check your settings");
        }
        else {
          console.log("RESPONSE PATCH SURFBOARD:", data);
          setAlertLevel('success');
          setMessage("Your new board has been updated!");
          UserSurfboardService.getSurfboards().then(
            (response) => {
              const data = response;
              console.log("RESPONSE FROM SERVICE:", data);
              setMySurfBoards(data);
              setIsDataLoading(false);
    
            },
            (error) => {
              setIsDataLoading(false);
    
              const _content =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
                console.log("ERROR:",_content)
    
            }
          );
    
        }
      },
      (error) => {
        setIsDataLoading(false);
        setAlertLevel('error')
        if (error.response.status === 422 || error.response.status === 403) {
          setAlertLevel('warning');
          setMessage("Please fill out all the required fields");
        }
        else {
          setMessage("No way, dude! This looks bad, sorry! I couldn't save your board! Check it again, please!");
        }
        
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
          console.log("ERROR 2:",_content);
      }
    )
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsDataLoading(true);
    let payload = {
      "surfboard_type": formData.surfboard_type,
       "fins": formData.surfboard_fins,
        "width": formData.board_width,
        "brand": formData.board_brand,
        "nickname": formData.board_nickname,
        "favorite": formData.favorite,
        "length": formData.board_length,
        "volume": formData.board_volume,
        "material": formData.board_material
    }
    console.log("Data:", payload);

    UserSurfboardService.addSurfboard(payload).then(
      (response) => {
        setIsDataLoading(false);
        const data = response.data;
        if (response === "ERR_NETWORK") {
          setAlertLevel('error')
          setMessage("You are not connected to a network. Please check your settings");
        }
        else {
          console.log("RESPONSE ADD SURFBOARD:", data);
          setAlertLevel('success')
          setMessage("Your new board has been saved!");

          UserSurfboardService.getSurfboards().then(
            (response) => {
              setIsDataLoading(false);
              if (response === "ERR_NETWORK") {
                setAlertLevel('error')
                setMessage("You are not connected to a network. Please check your settings");
              }
              const data = response;
              console.log("RESPONSE FROM SERVICE:", data);
              setMySurfBoards(data);
            },
            (error) => {
              const _content =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
                console.log("ERROR 1:",_content)
              }
          );  
          }
        
      },
      (error) => {
        setIsDataLoading(false);
        setAlertLevel('error')
        if (error.response.status === 422 || error.response.status === 403) {
          setAlertLevel('warning');
          setMessage("Please fill out all the required fields");
        }
        else {
          setMessage("No way, dude! This looks bad, sorry! I couldn't save your board! Check it again, please!");
        }
        
        const _content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
          console.log("ERROR 2:",_content);
      }
    );
  };
  const [formData, setFormData] = useState({});
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("NAME:", name);
    console.log("SET:", (name, value));
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
 
  

  useEffect(() => {
    setIsDataLoading(true);
    if (current_user === undefined) {
      UserService.getUserBoard().then((response) => {
        
        if (response === "ERR_NETWORK") {
          setAlertLevel('warning');
          setMessage("It seems you are not connected to a network. Please try again.");
        }
        setCurrentUser(response);
      });
    }
    UserSurfboardService.getSurfboards().then(
        (response) => {
          const data = response;
          console.log("RESPONSE FROM SERVICE:", data);
          setMySurfBoards(data);
          setIsDataLoading(false);

        },
        (error) => {
          setIsDataLoading(false);

          const _content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("ERROR:",_content)

        }
      );

  }, [current_user]);
  return (
    <><ResponsiveAppBar routes={routes} />
    
      {isDataLoading ? <Loader />: 
    
    <MKBox component="form"  method="post">
       {message ? (
                 <Alert variant="filled" severity={alter_level} onClose={(e) => {handleClose()}}>{message} </Alert>

              ) : (<p></p>)}
       {/* Alert Before Delete */ }

       <Dialog
               open={alert_before_delete}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleClose}
               aria-describedby="alert-dialog-slide-description"
             >
               <DialogTitle>{"Do you want to delete all your session data?"}</DialogTitle>
               <DialogContent>
                 <DialogContentText id="alert-dialog-slide-description">
                 All session data, including progress and linked pictures, will be permanently deleted. 
                 This action cannot be undone. Proceed at your own risk.
                 </DialogContentText>
               </DialogContent>
               <DialogActions>
                 <Button onClick={handleClose}>Go back</Button>
                 <Button onClick={handleConfirmDelete}>Agree</Button>
               </DialogActions>
      </Dialog> 
     {/* Modal to edit the surfboard */}
     
      <Modal
        open={open_modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit your surfboard 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Grid container justifyContent="flex-start" spacing={1}>

                          <Grid item xs={6} md={3}>
                            <SurfingIcon fontSize="large"></SurfingIcon>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel id="wetsuit-label">Type of SurfBoard</InputLabel>
                              <Select
                                name="surfboard_type"
                                id="surfboard_type"
                                defaultValue={surfboard_data.surfboard_type}
                                onChange={handleChange}
                                label="What type of Surfbord do you have?"
                              >
                                <MenuItem value={surfboard_data.surfboard_type}>
                                  <em>None</em>
                                </MenuItem>
                                {items["surfboard_type"].map((item) => (
                                  <MenuItem key={item.key} value={item.value}>
                                    {item.value}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>What type of Surfbord do you have?</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <MKBox mb={{ xs: 6, md: 0 }}>
                              <DefaultInfoCard title="Lenght" description="" />
                              <StraightenIcon fontSize="large"></StraightenIcon>

                              <MKInput
                                variant="standard"
                                label="Board Length in cm"
                                fullWidth
                                type="text"
                                id="board_length"
                                name="board_length"
                                defaultValue={surfboard_data?.length} 
                                onChange={handleChange}
                                placeholder="How long is your board?"
                                required />
                            </MKBox>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <MKBox mb={{ xs: 6, md: 0 }}>
                              <DefaultInfoCard title="Width" description="" />
                              <WidthFullIcon fontSize="large"></WidthFullIcon>
                              <MKInput
                                variant="standard"
                                label="Board Width in cm"
                                fullWidth
                                type="text"
                                id="board_width"
                                name="board_width"
                                defaultValue={surfboard_data?.width}
                                onChange={handleChange}
                                placeholder="What's the Width of your board?"
                                required />
                            </MKBox>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <DefaultInfoCard title="Volume" description="" />
                            <Crop54Icon fontSize="large"></Crop54Icon>
                            <MKBox mb={{ xs: 6, md: 0 }}>
                              <MKInput
                                variant="standard"
                                label="Board Volume in litres"
                                fullWidth
                                type="text"
                                id="board_volume"
                                name="board_volume"
                                defaultValue={surfboard_data?.volume}
                                onChange={handleChange}
                                placeholder="The volume of your board"
                                required />
                            </MKBox>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <WavesIcon fontSize="large"></WavesIcon>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel id="surfboard-fins">Fins</InputLabel>
                              <Select
                                name="surfboard_fins"
                                id="surfboard_fins"
                                defaultValue={surfboard_data?.fins}
                                onChange={handleChange}
                                label="Your Fins setup"
                                required
                              >
                                <MenuItem value={formData.surfboard_fins}>
                                  <em>{formData.surfboard_fins}</em>
                                </MenuItem>
                                {items["fins"].map((item) => (
                                  <MenuItem key={item.key} value={item.value}>
                                    {item.value}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>What's you type of fin?</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <DefaultInfoCard title="Brand" description="" />
                            <BrandingWatermarkIcon fontSize="large"></BrandingWatermarkIcon>
                            <MKBox mb={{ xs: 6, md: 0 }}>
                              <MKInput
                                variant="standard"
                                label="Board Brand"
                                fullWidth
                                type="text"
                                id="board_brand"
                                name="board_brand"
                                defaultValue={surfboard_data?.brand}
                                onChange={handleChange}
                                placeholder="Does your board have a brand?" />
                            </MKBox>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <DefaultInfoCard title="Material" description="" />
                            <BuildIcon fontSize="large"></BuildIcon>
                            <MKBox mb={{ xs: 6, md: 0 }}>
                              <MKInput
                                variant="standard"
                                label="Board Material"
                                fullWidth
                                type="text"
                                id="board_material"
                                name="board_material"
                                defaultValue={surfboard_data?.material}
                                onChange={handleChange}
                                placeholder="" />
                            </MKBox>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <DefaultInfoCard title="Nick Name" description="" />
                            <SellIcon fontSize="large"></SellIcon>
                            <MKBox mb={{ xs: 6, md: 0 }}>
                              <MKInput
                                variant="standard"
                                label="Board NickName"
                                fullWidth
                                type="text"
                                id="board_nickname"
                                name="board_nickname"
                                defaultValue={surfboard_data?.nickname}
                                onChange={handleChange}
                                placeholder="Does your board have a brand?" />
                            </MKBox>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <MKBox mb={6}>

                              <StarsIcon fontSize="large"></StarsIcon>

                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Favorite"
                                  defaultValue={surfboard_data?.favorite}
                                  name="favorite"
                                  onChange={handleChange} />
                              </FormGroup>
                            </MKBox>
                          </Grid>
                          <Grid item xs={12} lg={12} marginBottom={5}>
                            <MKButton color="info" onClick={handleUpdate} size="large" variant="contained" startIcon={<SaveIcon />}>
                              Update
                            </MKButton>
                            

                          </Grid>
                          </Grid>
          </Typography>
        </Box>
      </Modal>
     
     
     
     <Container>

        <><Grid container spacing={1} alignItems="center" marginTop={10} borderBottom={1}>
            <Grid item xs={12} lg={12}>
           
             <MKTypography variant="h4" mb={6}>
                Your SurfBoard
              </MKTypography>
             
              <Grid container justifyContent="flex-start" spacing={1}>

                <Grid item xs={6} md={3}>
                  <SurfingIcon fontSize="large"></SurfingIcon>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="wetsuit-label">Type of SurfBoard</InputLabel>
                    <Select
                      name="surfboard_type"
                      id="surfboard_type"
                      value={formData.surfboard_type}
                      onChange={handleChange}
                      label="What type of Surfbord do you have?"
                    >
                      <MenuItem value={formData.surfboard_type}>
                        <em>None</em>
                      </MenuItem>
                      {items["surfboard_type"].map((item) => (
                        <MenuItem key={item.key} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>What type of Surfbord do you have?</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <MKBox mb={{ xs: 6, md: 0 }}>
                    <DefaultInfoCard title="Length" description="" />
                    <StraightenIcon fontSize="large"></StraightenIcon>

                    <MKInput
                      variant="standard"
                      label="Board Length in cm"
                      fullWidth
                      type="text"
                      id="board_length"
                      name="board_length"
                      value={formData.board_length}
                      onChange={handleChange}
                      placeholder="How long is your board?"
                      required />
                  </MKBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <MKBox mb={{ xs: 6, md: 0 }}>
                    <DefaultInfoCard title="Width" description="" />
                    <WidthFullIcon fontSize="large"></WidthFullIcon>
                    <MKInput
                      variant="standard"
                      label="Board Width in cm"
                      fullWidth
                      type="text"
                      id="board_width"
                      name="board_width"
                      value={formData.board_width}
                      onChange={handleChange}
                      placeholder="What's the Width of your board?"
                      required />
                  </MKBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DefaultInfoCard title="Volume" description="" />
                  <Crop54Icon fontSize="large"></Crop54Icon>
                  <MKBox mb={{ xs: 6, md: 0 }}>
                    <MKInput
                      variant="standard"
                      label="Board Volume in litres"
                      fullWidth
                      type="text"
                      id="board_volume"
                      name="board_volume"
                      value={formData.board_volume}
                      onChange={handleChange}
                      placeholder="The volume of your board"
                      required />
                  </MKBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <WavesIcon fontSize="large"></WavesIcon>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="surfboard-fins">Fins</InputLabel>
                    <Select
                      name="surfboard_fins"
                      id="surfboard_fins"
                      value={formData.surfboard_fins}
                      onChange={handleChange}
                      label="Your Fins setup"
                      required
                    >
                      <MenuItem value={formData.surfboard_fins}>
                        <em>{formData.surfboard_fins}</em>
                      </MenuItem>
                      {items["fins"].map((item) => (
                        <MenuItem key={item.key} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>What's you type of fin?</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DefaultInfoCard title="Brand" description="" />
                  <BrandingWatermarkIcon fontSize="large"></BrandingWatermarkIcon>
                  <MKBox mb={{ xs: 6, md: 0 }}>
                    <MKInput
                      variant="standard"
                      label="Board Brand"
                      fullWidth
                      type="text"
                      id="board_brand"
                      name="board_brand"
                      value={formData.board_brand}
                      defaulValue="No Brand"
                      onChange={handleChange}
                      placeholder="Does your board have a brand?" />
                  </MKBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DefaultInfoCard title="Material" description="" />
                  <BuildIcon fontSize="large"></BuildIcon>
                  <MKBox mb={{ xs: 6, md: 0 }}>
                    <MKInput
                      variant="standard"
                      label="Board Material"
                      fullWidth
                      type="text"
                      id="board_material"
                      name="board_material"
                      value={formData.board_material}
                      defaulValue="No Brand"
                      onChange={handleChange}
                      placeholder="Does your board have a brand?" />
                  </MKBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DefaultInfoCard title="Nick Name" description="" />
                  <SellIcon fontSize="large"></SellIcon>
                  <MKBox mb={{ xs: 6, md: 0 }}>
                    <MKInput
                      variant="standard"
                      label="Board NickName"
                      fullWidth
                      type="text"
                      id="board_nickname"
                      name="board_nickname"
                      value={formData.board_nickname}
                      defaulValue="No Brand"
                      onChange={handleChange}
                      placeholder="Does your board have a brand?" />
                  </MKBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <MKBox mb={6}>

                    <StarsIcon fontSize="large"></StarsIcon>

                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Favorite"
                        name="favorite"
                        onChange={handleChange} />
                    </FormGroup>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={12} marginBottom={5}>
                  <MKButton color="info" onClick={handleSubmit} size="large" variant="contained" startIcon={<SaveIcon />}>
                    Save
                  </MKButton>
                  

                </Grid>
              </Grid>
            </Grid>

          </Grid><Grid
            container
            spacing={1}
            alignItems="center"
          >
              <Grid item xs={12} lg={12} marginTop={5}>
                <Typography component="h2" variant="h3" color="success" gutterBottom>
                  My SurfBoards
                </Typography>
                <Grid container justifyContent="flex-start" spacing={1}>
                  {my_surfboards?.map((board) => (
                    <Grid item xs={12} lg={3}>
                      <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image="https://placehold.co/200x200"
                            sx={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "0",
                              justifyContent: "center",
                              display: "flex",
                              width: "100%",
                              borderBottomLeftRadius: "0",
                              borderBottomRightRadius: "0"
                            }}
                            alt="" />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div" align="center">
                              {board.nickname}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center">
                              {board.value} - {board.brand}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                        <MKButton size="small" color="error" variant="outlined" onClick={() => handleDelete(board)} state={{ data: board }}  startIcon={<DeleteForeverIcon></DeleteForeverIcon>}>
                            Delete
                        </MKButton>
                        <MKButton size="small" color="info" variant="outlined" onClick={() => handleOpenModal(board)}  startIcon={<VisibilityIcon></VisibilityIcon>}>
                            Details
                        </MKButton>
                       
                       
                    </CardActions>

                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container justifyContent="flex-start" spacing={1} sx={{ ml: "auto", mt: { xs: 3, lg: -24 } }}>
                </Grid>
              </Grid>
            </Grid></>
      </Container>
    
    </MKBox>    
}    
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '30vh',
        }}
      > 
      <Footer/>
    </Box>

    </>
  );
}

export default Surfboards;
