/* eslint-disable no-unused-vars */
import axios from "axios";
import AuthService from "services/auth.service";
import EventBus from "common/EventBus";
const { REACT_APP_BACKEND_URL } = process.env;
const BASE_API_URL = REACT_APP_BACKEND_URL;
const API_URL = BASE_API_URL+"training_program";
const currentUser = AuthService.getCurrentUser();

if (currentUser != null) {
  var access_token = currentUser.access_token;
} else {
  access_token = undefined;
}


const getUserProgramTracking = () => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
  };
  
  console.log("CALLING:", API_URL + "?format_for_tracking=True&completed=false")
  return axios.get(API_URL + "?format_for_tracking=True&completed=false",  config).then(
    (res) => {
      console.log("RESPONSE TRAINING PROGRAM:",res);
      return res.data;
    },
    (error) => {
      let http_status = error.response.status;
      console.log("[TRAINING PROGRAM:] error:", error,http_status);
      if (http_status === 401) {
        EventBus.dispatch("logout");
      }
      return error;

    }
  );
};



const getUserTrainingProgram = () => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
  };
  
  console.log("CALLING:", API_URL + "?compact=True&completed=false")
  return axios.get(API_URL + "?compact=True&completed=false",  config).then(
    (res) => {
      console.log("RESPONSE TRAINING PROGRAM:",res);
      return res.data;
    },
    (error) => {
      let http_status = error.response.status;
      console.log("[TRAINING PROGRAM:] error:", error,http_status);
      if (http_status === 401) {
        EventBus.dispatch("logout");
      }
      return error;

    }
  );
};


const addTrainingProgram = (payload) => {
   console.log("SEND TO",API_URL, payload)
  return axios.post(API_URL , payload, {
    headers: { Authorization: "Bearer " + access_token },
  });
};

const updateTrainingProgram = (payload, training_uuid) => {
  console.log("SEND TO",API_URL+"?training_uuid="+training_uuid, payload)
 return axios.patch(API_URL+"?training_uuid="+training_uuid , payload, {
   headers: { Authorization: "Bearer " + access_token },
 });
};


const UserTrainingProgrammService = {
  getUserTrainingProgram,
  addTrainingProgram,
  updateTrainingProgram,
  getUserProgramTracking

  
};

export default UserTrainingProgrammService;
