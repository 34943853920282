// Material Kit 2 React examples
import ResponsiveAppBar from "layouts/sections/navigation/auth-bar";
import Card from "@mui/material/Card";
import Footer from "pages/PrivatePages/UserBoard/sections/Footer";
// Routes
import routes from "routes";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import React, { useEffect } from "react";
import UserService from "services/user.service";
import bgImage from "assets/images/surfingwallpaper56.jpeg";
import SelectSpotForm from "pages/PrivatePages/SurfSessions/select-spot/sections/SelectSpotForm"

function SelectSpot() {


  useEffect(() => {
    UserService.getUserBoard().then((response) => {
      console.log("[SURFSESSION]RESPONSE FROM AUTH:", response);
    });
  }, []);


  return  (
    <>
      <ResponsiveAppBar routes={routes} transparent light />
      <MKBox bgColor="white">

      <MKBox component="section" py={{ xs: 3, md: 12 }}minHeight="25rem"
          width="100%" sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center" }}
          />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -38,
            mb: 10,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
        <SelectSpotForm />
        </Card>
        <Footer />
      </MKBox>
    </>
  );
};
export default SelectSpot;