/* eslint-disable no-unused-vars */
import axios from "axios";
import AuthService from "services/auth.service";
import EventBus from "common/EventBus";
const { REACT_APP_BACKEND_URL } = process.env;

const API_URL = `${REACT_APP_BACKEND_URL}profile/user_profile`;
const currentUser = AuthService.getCurrentUser();


if (currentUser != null) {
  // console.log("[PROFILE SERVICE]CURRENT USER NOT NULL ", currentUser);
  var access_token = currentUser.access_token;
} else {
  access_token = undefined;
}

// console.log("[PROFILE SERVICE]ACCESS TOKEN:", access_token);

const getUserProfile = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
  };

  return axios.get(API_URL,  config).then(
    (res) => {
      return res.data;
    },
    (error) => {
      console.log("[PROFILE SERVICE]error:", error);
      let http_status = error.response?.status || 500 ;
      if (error.code === "ERR_NETWORK") {
        return "ERR_NETWORK"
      }
      else if (http_status === 401) {
        EventBus.dispatch("logout");
      }
      else if (http_status === 404) {
        return {};
      }
      
      else {
        return error; 
      }
      
    }
  );
};

const upadteUserProfile = (payload, profile_picture_file) => {
  
  // let p = {"activity": "surf", "stance": "goofy",  "age": 47, "height": 173, "weight": 72.25, "home_town": "Catania", "home_spot": "Siracusa", "profile_pic": "string", "instagram_profile": "string", "facebook_profile": "string", "twitter_profile": "string", "youtube_profile": "string"};
  let bodyFormData = new FormData();
  const json_payload = JSON.stringify(payload);
  bodyFormData.append("data", json_payload);
  if (profile_picture_file !== undefined) {
    bodyFormData.append("profile_picture_file", profile_picture_file);
  }
  
  const config = {
    headers: {
      "Content-Type": "multipart/form-data; boundary=${bodyFormData._boundary}",
      Authorization: "Bearer " + access_token,
    },
  };
  console.log("PAYLOAD:",payload);
  console.log("PICTURE:",profile_picture_file);
  console.log("Payload:",payload);
  console.log("BODY:",bodyFormData);
  return axios.patch(API_URL, bodyFormData, config).then(
    (res) => {
      console.log("RESPONSE:",res.status);
      return res.data;
    },
    (error) => {
      console.log("error:", error);
      let http_status = error.response?.status || 500 ;
      if (error.code === "ERR_NETWORK") {
        return "ERR_NETWORK"
      }
      else if (http_status === 401) {
        EventBus.dispatch("logout");
      }
      else {
        return error; 
      }
    }
  );
}
const storeUserProfile = (payload, profile_picture_file) => {
  let bodyFormData = new FormData();
  let p = {
    activity: "surf",
    stance: "goofy",
    age: 47,
    height: 173,
    weight: 72.25,
    home_town: "Catania",
    home_spot: "Catania",
    profile_pic: "string",
    instagram_profile: "string",
    facebook_profile: "string",
    twitter_profile: "string",
    youtube_profile: "string",
  };
  console.log("PAYLOAD:", p);
  const json_payload = JSON.stringify(p);
  const blob = new Blob([json_payload], {
    type: "application/json",
  });
  const data = new FormData();
  data.append("data", blob);
  bodyFormData.append("data", json_payload);
  bodyFormData.append("profile_picture_file", profile_picture_file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data; boundary=${bodyFormData._boundary}",
      Authorization: "Bearer " + access_token,
    },
  };

  return axios.post(API_URL, bodyFormData, config).then(
    (res) => {
      console.log(res.data);
      console.log(data);
      return res.data;
    },
    (error) => {
      console.log("error:", error);
      let http_status = error.response?.status || 500 ;
      if (error.code === "ERR_NETWORK") {
        return "ERR_NETWORK"
      }
      else if (http_status === 401) {
        EventBus.dispatch("logout");
      }
      else {
        return error; 
      }
    }
  );
};

const UserProfileService = {
  getUserProfile,
  storeUserProfile,
  upadteUserProfile,
  
};

export default UserProfileService;
